import Position from '../core/Position';
import PositionSet from '../core/PositionSet';
import PositionSets from '../core/PositionSets';

export default class SolveHint {

    private readonly _text: string;
    private readonly _squares: PositionSet;
    private readonly _colors: PositionSets;
    private readonly _causes: PositionSets;

    private constructor(text: string, squares: PositionSet, colors: PositionSets, causes: PositionSets) {
        this._text = text;
        this._squares = squares;
        this._colors = colors;
        this._causes = causes;
    }

    public text(): string {
        return this._text;
    }

    public squares(): PositionSet {
        return this._squares;
    }

    public colors(): PositionSets {
        return this._colors;
    }

    public causes(): PositionSets {
        return this._causes;
    }

    public static fromText(text: string, squares?: Position | Position[] | PositionSet, colors?: PositionSet[] | PositionSets, causes?: PositionSet[] | PositionSets): SolveHint {
        return new SolveHint(text, PositionSet.fromAny(squares), PositionSets.fromAny(colors), PositionSets.fromAny(causes));
    }
}