import { withAuth0, WithAuth0Props, withAuthenticationRequired } from '@auth0/auth0-react';
import memoize from 'memoize-one';
import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import Constraint from 'sudokuku-common/src/constraints/Constraint';
import ConstraintReg from 'sudokuku-common/src/constraints/ConstraintReg';
import Position from 'sudokuku-common/src/core/Position';
import PositionSet from 'sudokuku-common/src/core/PositionSet';
import SudokuConstraints from 'sudokuku-common/src/core/SudokuConstraints';
import SudokuDef from 'sudokuku-common/src/core/SudokuDef';
import SudokuState from 'sudokuku-common/src/core/SudokuState';
import ViolationState from 'sudokuku-common/src/core/ViolationState';
import PropertySelection from 'sudokuku-common/src/properties/PropertySelection';
import SudokuApi from '../api/v1/SudokuApi';
import Auth from '../auth/Auth';
import Viewport from '../components/Viewport';
import SudokuStorage from '../storage/SudokuStorage';
import UIMenu, { UIMenuProps } from '../ui-menu/UIMenu';
import UIMenuButton from '../ui-menu/UIMenuButton';
import UIMenuDivider from '../ui-menu/UIMenuDivider';
import UIMenuHamburger from '../ui-menu/UIMenuHamburger';
import UIMenuLink from '../ui-menu/UIMenuLink';
import UIRoot from '../ui-shell/UIRoot';
import Grid from './components/grid/Grid';
import GridEvents, { GridEventsFlags } from './components/grid/GridEvents';
import UIEditProperties from './components/ui/UIEditProperties';
import UIInputConstraints from './components/ui/UIInputConstraints';
import UIInputDigits from './components/ui/UIInputDigits';
import ConfigState from './model/ConfigState';
import { InputMode } from './model/InputState';
import SelectionState from './model/SelectionState';
import StatusState, { StatusFlags } from './model/StatusState';
import './UIEdit.css';

export type UIEditParams = {
    id: string
}

export type UIEditProps = UIMenuProps & WithAuth0Props & RouteComponentProps<UIEditParams>;

export type UIEditState = {
    sudoku: SudokuState,
    selection: SelectionState,
    propertySelection: PropertySelection,
    status: StatusState
}

export class UIEdit extends React.PureComponent<UIEditProps, UIEditState> {

    private readonly _gridRef: React.RefObject<HTMLDivElement>;
    private _auth: Auth;

    constructor(props: UIEditProps) {
        super(props);

        const sudokuDef = SudokuDef.fromEmpty();

        this.state = {
            sudoku: SudokuState.fromDef(sudokuDef),
            selection: SelectionState.fromDef(sudokuDef),
            propertySelection: PropertySelection.fromEmpty(),
            status: StatusState.fromDefault()
        };

        this._auth = new Auth(this.props.auth0);
        this._gridRef = React.createRef<HTMLDivElement>();

        this.handleKeyDown = this.handleKeyDown.bind(this);
        this.handlePointerDown = this.handlePointerDown.bind(this);
        this.handlePointerDrag = this.handlePointerDrag.bind(this);
        this.handlePointerClick = this.handlePointerClick.bind(this);
        this.handlePointerLongClick = this.handlePointerLongClick.bind(this);
        this.handlePointerUp = this.handlePointerUp.bind(this);
        this.handleInputClick = this.handleInputClick.bind(this);
    }

    override componentDidMount(): void {

        document.title = UIRoot.Title + ' - Editor';

        document.addEventListener("keydown", this.handleKeyDown);

        if (this.loadLocalSudoku()) {
            this.checkServerSudokuRevision();
        }
        else {
            this.loadServerSudoku();
        }
    }

    override componentWillUnmount(): void {
        document.removeEventListener("keydown", this.handleKeyDown);
    }

    override componentDidUpdate(prevProps: UIEditProps, prevState: UIEditState): void {

        // update auth
        if (this.props.auth0 !== prevProps.auth0) {
            this._auth = new Auth(this.props.auth0);
        }

        // save state
        if (this.state.sudoku !== prevState.sudoku) {

            // save locally
            SudokuStorage.saveSudoku(this.props.location.pathname, this.state.sudoku);

            // save to server
            this.saveServerSudoku();
        }
    }

    validateConstraints = memoize((sudoku: SudokuState) => UIEdit.validateConstraints(sudoku));

    isComplete = memoize((sudoku: SudokuState) => sudoku.isComplete());

    override render(): React.ReactNode {

        const sudoku = this.state.sudoku;
        const selection = this.state.selection;
        const config = ConfigState.fromDefault();

        const violations = this.validateConstraints(sudoku);

        return (
            <div className="UIEdit">

                <div className="UIEditInput">
                    <UIInputDigits mode={InputMode.Digits} config={config} onClick={this.handleInputClick} />
                    <UIInputConstraints onClick={this.handleInputClick} />
                </div>

                <div className="UIEditGrid" ref={this._gridRef} tabIndex={1}>
                    <Viewport>
                        <GridEvents onPointerDown={this.handlePointerDown} onPointerDrag={this.handlePointerDrag} onPointerClick={this.handlePointerClick} onPointerLongClick={this.handlePointerLongClick} onPointerUp={this.handlePointerUp} >
                            <Grid sudoku={sudoku} selection={selection} violations={violations} />
                        </GridEvents>
                    </Viewport>
                </div>

                <UIEditProperties sudoku={this.state.sudoku} selection={this.state.selection} propertySelection={this.state.propertySelection}
                    onSudoku={(s) => this.setSudoku(s)}
                    onSelection={(s) => this.setPropertySelection(s)} />

                <UIMenu {...this.props}>
                    <UIMenuHamburger {...this.props} />
                    <UIMenuLink {...this.props} to="/">Home</UIMenuLink>
                    <UIMenuDivider {...this.props} />
                    <UIMenuButton {...this.props} id="Menu-Play" onClick={(e) => this.handleMenuClick(e)}>Play Puzzle</UIMenuButton>
                    <UIMenuDivider />
                    <UIMenuButton {...this.props} id="Menu-Delete" onClick={(e) => this.handleMenuClick(e)}>Delete Puzzle!</UIMenuButton>
                    <UIMenuDivider />
                    <UIMenuLink to='/terms' target="_blank" {...this.props}>Terms</UIMenuLink>
                    <UIMenuLink to='/privacy' target="_blank" {...this.props}>Privacy</UIMenuLink>
                </UIMenu>

            </div>
        );
    }

    private setSudoku(sudoku: SudokuState): void {
        this.setState({ sudoku: sudoku });
    }

    private setPropertySelection(propertySelection: PropertySelection): void {
        const selection = this.cellSelection(propertySelection);
        this.setState({ propertySelection: propertySelection, selection: selection });
    }

    private cellSelection(propertySelection: PropertySelection): SelectionState {

        let selection = this.state.selection;

        const constraints = propertySelection.objects(this.state.sudoku.propertyObject())
            .filter(o => o.data() instanceof Constraint)
            .map(o => o.data() as Constraint);

        const constraintSquares = Constraint.squares(constraints);
        if (!constraintSquares.isEmpty()) {
            selection = selection.setMultiSelection(constraintSquares.middle(), constraintSquares.toArray());
        }

        return selection;
    }

    private static validateConstraints(sudoku: SudokuState): ViolationState {
        let violations = ViolationState.fromEmpty();

        for (const constraint of sudoku.def().constraints().all()) {
            violations = constraint.validate(sudoku, violations);
        }

        return violations;
    }

    private handleKeyDown(event: KeyboardEvent) {

        function setSelection(s: UIEditState, isMulti: boolean, move: () => Position) {

            const square = s.selection.nextSelectableSquare(s.selection.primary(), move);
            if (!square) return null;

            // undo the selection when going backwards
            if (isMulti) {
                const prev = s.selection.prev();
                if (prev && square.isEqual(prev.primary())) {
                    return { selection: prev, propertySelection: prev.propertySelection(s.sudoku) };
                }
            }

            const selection = isMulti ? s.selection.addSelection(square, s.selection) : s.selection.setSelection(square);

            return { selection: selection, propertySelection: selection.propertySelection(s.sudoku) };
        }

        // ctc app:
        //  ctrl+click:  add/remove from selection
        //  ctrl+arrow:  add to selection
        //  space:       next mode
        //  ctrl+space:  prev mode
        //  z:           digit mode
        //  x:           corner mode
        //  c:           center mode
        //  v:           color mode
        //  ctrl+digit:  center
        //  shift+digit: corner
        //  c+s+digit:   color
        //  ctrl+z       undo
        //  ctrl+y       redo

        // Play Mode
        //  https://www.sudokuku.com/play?id=dsjdhw87
        //  - Save solve in progress (on timer, or on change?)
        //  - Save def & state on client (version stamped)
        //  - Restart reloads def from server and clears state (no undo!)
        //  - URL contains puzzle id
        //  - Copy URL to share, no login required
        //  - Login to see all solved puzzles
        //  - Login to comment on puzzle
        //  - Config stored on client (eg: show timer, etc)
        //
        // Create Mode
        //  - Requires login
        //  - Create new puzzle
        //      - https://www.sudokuku.com/edit?id=dsjdhw87
        //      - Size, Title, Description, Rules, etc
        //  - Automatically saves def (version stamped)
        //

        // only handle keyboard events if grid is focused
        if (document.activeElement instanceof HTMLInputElement) return;
        if (document.activeElement instanceof HTMLTextAreaElement) return;

        switch (event.code) {

            case 'ArrowDown':
                event.preventDefault();
                this.setState((s, p) => setSelection(s, event.ctrlKey || event.shiftKey, Position.prototype.down));
                break;

            case 'ArrowUp':
                event.preventDefault();
                this.setState((s, p) => setSelection(s, event.ctrlKey || event.shiftKey, Position.prototype.up));
                break;

            case 'ArrowLeft':
                event.preventDefault();
                this.setState((s, p) => setSelection(s, event.ctrlKey || event.shiftKey, Position.prototype.left));
                break;

            case 'ArrowRight':
                event.preventDefault();
                this.setState((s, p) => setSelection(s, event.ctrlKey || event.shiftKey, Position.prototype.right));
                break;

            case 'Digit1':
            case 'Digit2':
            case 'Digit3':
            case 'Digit4':
            case 'Digit5':
            case 'Digit6':
            case 'Digit7':
            case 'Digit8':
            case 'Digit9':
            case 'Numpad1':
            case 'Numpad2':
            case 'Numpad3':
            case 'Numpad4':
            case 'Numpad5':
            case 'Numpad6':
            case 'Numpad7':
            case 'Numpad8':
            case 'Numpad9':
                event.preventDefault();
                this.setState((s, p) => UIEdit.setDigit(s, event.key));
                break;

            case 'Backspace':
            case 'Delete':
                event.preventDefault();
                this.setState((s, p) => UIEdit.clearDigit(s));
                break;

            case 'KeyA':
                if (event.ctrlKey || event.shiftKey || event.metaKey) {
                    event.preventDefault();
                    this.setState((s, p) => UIEdit.selectAll(s));
                }
                break;

            case 'KeyZ':
                if (event.ctrlKey) {
                    event.preventDefault();
                    this.setState((s, p) => UIEdit.undo(s));
                }
                else if (event.metaKey && !event.shiftKey) {
                    event.preventDefault();
                    this.setState((s, p) => UIEdit.undo(s));
                }
                else if (event.metaKey && event.shiftKey) {
                    event.preventDefault();
                    this.setState((s, p) => UIEdit.redo(s));
                }
                break;

            case 'KeyY':
                if (event.ctrlKey) {
                    event.preventDefault();
                    this.setState((s, p) => UIEdit.redo(s));
                }
                break;
        }
    }

    private handlePointerDown(element: Element | null, flags: GridEventsFlags) {

        function setSelection(s: UIEditState, isMulti: boolean, square: Position) {
            const selection = isMulti ? s.selection.toggleSelection(square) : s.selection.setSelection(square);

            return { selection: selection, propertySelection: selection.propertySelection(s.sudoku) };
        }

        const squareElement = element as HTMLDivElement;
        if (!squareElement) return;
        if (!squareElement.id) return;

        const isMulti = (flags & GridEventsFlags.IsMulti) !== 0;

        this.setState((s, p) => setSelection(s, isMulti, Position.fromString(squareElement.id)));
    }

    private handlePointerUp(element: Element | null, flags: GridEventsFlags) {
        // do nothing
    }

    private handlePointerDrag(element: Element | null, flags: GridEventsFlags) {

        function addSelection(s: UIEditState, square: Position) {
            const selection = s.selection.addSelection(square);
            return { selection: selection, propertySelection: selection.propertySelection(s.sudoku) };
        }

        const squareElement = element as HTMLDivElement;
        if (!squareElement) return;
        if (!squareElement.id) return;

        const square = Position.fromString(squareElement.id);

        this.setState((s, p) => addSelection(s, square));
    }

    private handlePointerClick(element: Element, flags: GridEventsFlags) {

        // always set focus
        this._gridRef.current?.focus({ preventScroll: true });

    }

    private handlePointerLongClick(element: Element, flags: GridEventsFlags) {

        function setSelection(s: UIEditState, isMulti: boolean, primary: Position, squares: PositionSet) {
            if (isMulti) {
                return { selection: s.selection.addMultiSelection(primary, squares.toArray()) }
            } else {
                return { selection: s.selection.setMultiSelection(primary, squares.toArray()) }
            }
        }

        // always set focus
        this._gridRef.current?.focus({ preventScroll: true });

        // get selected square
        const squareElement = element as HTMLDivElement;
        if (!squareElement) return;
        if (!squareElement.id) return;

        // get flags
        const isMulti = (flags & GridEventsFlags.IsMulti) !== 0;

        // check selection has not changed
        const square = Position.fromString(squareElement.id);
        if (!square.isEqual(this.state.selection.primary())) return;

        // check selection contains a digit
        const sudoku = this.state.sudoku;
        const selectedDigit = sudoku.digit(square);
        if (!selectedDigit) return;

        // find all positions with the same digit
        const squares = sudoku.digitSquares(selectedDigit);

        // set selection
        this.setState((s, p) => setSelection(s, isMulti, square, squares));
    }

    private handleInputClick(event: React.MouseEvent<HTMLButtonElement>) {
        const id = event.currentTarget.id;
        const value = event.currentTarget.value;

        switch (id) {
            case 'Input1':
            case 'Input2':
            case 'Input3':
            case 'Input4':
            case 'Input5':
            case 'Input6':
            case 'Input7':
            case 'Input8':
            case 'Input9':
                event.preventDefault();
                this.setState((s, p) => UIEdit.setDigit(s, id.substring(id.length - 1)));
                break;

            case 'InputClear':
                event.preventDefault();
                this.setState((s, p) => UIEdit.clearDigit(s));
                break;

            case 'InputUndo':
                event.preventDefault();
                this.setState((s, p) => UIEdit.undo(s));
                break;

            case 'InputRedo':
                event.preventDefault();
                this.setState((s, p) => UIEdit.redo(s));
                break;

            case 'InputConstraint':
                event.preventDefault();
                this.setState((s, p) => UIEdit.setConstraint(s, value));
                break;
        }
    }

    private handleMenuClick(event: React.MouseEvent<HTMLElement>) {
        const id = event.currentTarget.id;

        switch (id) {

            case 'Menu-Play': {
                this.props.history.push(`/play/${this.props.match.params.id}`);
                break;
            }

            case 'Menu-Delete': {
                if (window.confirm('Are you sure you want to delete this puzzle?')) {
                    SudokuApi.deleteSudoku(this._auth, this.props.match.params.id)
                        .then(() => this.props.history.replace('/'))
                        .catch(e => console.error(e));
                }
                break
            }
        }
    }

    private static setDigit(s: UIEditState, digit: string) {
        const newDef = s.sudoku.def().setDigit(s.selection.squares(), digit);

        return { sudoku: s.sudoku.setDef(newDef) };
    }

    private static clearDigit(s: UIEditState) {
        const newDef = s.sudoku.def().clearDigit(s.selection.squares());

        return { sudoku: s.sudoku.setDef(newDef) };
    }

    private static setConstraint(s: UIEditState, kind: string) {

        const constraintDef = SudokuConstraints.defs().find(d => d.kind() === kind);
        if (!constraintDef) return null;

        const propertySelection = [s.propertySelection] as [PropertySelection];
        const squares = s.selection.ordered();
        const sudoku = ConstraintReg.fromSelection(kind, s.sudoku, squares, propertySelection);
        if (sudoku === s.sudoku) return null;

        return { sudoku: sudoku, propertySelection: propertySelection[0].setFocus() };
    }

    private static selectAll(s: UIEditState) {
        const primary = s.selection.primary() || s.selection.firstSelectableSquare();
        const squares = s.sudoku.allSquares().toArray();
        const selection = s.selection.setMultiSelection(primary, squares);

        return { selection: selection, propertySelection: selection.propertySelection(s.sudoku) };
    }

    private static undo(s: UIEditState) {
        return { sudoku: s.sudoku.undo() };
    }

    private static redo(s: UIEditState) {
        return { sudoku: s.sudoku.redo() };
    }

    private loadLocalSudoku(): boolean {
        const sudoku = SudokuStorage.loadSudoku(this.props.location.pathname);
        if (!sudoku) return false;

        this.setState({
            sudoku: sudoku,
            selection: SelectionState.fromSudoku(sudoku),
            propertySelection: PropertySelection.fromObjects(sudoku.propertyObject()),
        });

        return true;
    }

    private loadServerSudoku() {

        this.setState((s, p) => ({
            status: s.status.setFlags(StatusFlags.IsLoading)
        }));

        SudokuApi.getSudoku(this.props.match.params.id).then(sudoku =>
            this.setState((s, p) => ({
                sudoku: sudoku,
                selection: SelectionState.fromSudoku(sudoku),
                propertySelection: PropertySelection.fromObjects(sudoku.propertyObject()),
                status: StatusState.fromDefault()
            })));
    }

    private saveServerSudoku() {

        // already saving? try again later
        if (this.state.status.hasFlags(StatusFlags.IsSaving)) {
            setTimeout(() => this.saveServerSudoku, 1000);
            return;
        }

        this.setState((s, p) => ({
            status: s.status.setFlags(StatusFlags.IsSaving)
        }));

        SudokuApi.putSudoku(this._auth, this.props.match.params.id, this.state.sudoku)
            .catch(e => {
                console.error(e);
            })
            .finally(() => {
                this.setState((s, p) => ({
                    status: s.status.clearFlags(StatusFlags.IsSaving)
                }));
            });
    }

    private checkServerSudokuRevision() {
        SudokuApi.getSudoku(this.props.match.params.id).then(sudoku => {

            const localRevision = this.state.sudoku.def().revision();
            const serverRevision = sudoku.def().revision();
            if (localRevision >= serverRevision) return;

            this.setState((s, p) => ({
                status: s.status.setFlags(StatusFlags.HasNewerRevision)
            }));
        });
    }
}

export default withAuthenticationRequired(withAuth0(UIEdit), {
    onRedirecting: () => <div>Loading...</div>,
});