import React from 'react';
import classNames from 'classnames';
import SudokuState from 'sudokuku-common/src/core/SudokuState';
import Position from 'sudokuku-common/src/core/Position';
import './GridDigit.css';

export type GridDigitProps = {
    id: string;
    sudoku: SudokuState;
}

export type GridDigitState = {
}

export default class GridDigit extends React.PureComponent<GridDigitProps, GridDigitState> {

    override render(): React.ReactNode {

        const sudoku = this.props.sudoku;
        const square = Position.fromString(this.props.id);

        const digit = sudoku.digit(square);
        if (!digit) return null;

        const className = classNames({
            'GridDigit': true,
            'content': true,
        });

        return <div className={className}>{digit}</div>;
    }
}