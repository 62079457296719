import React from 'react';
import './GridRow.css';

type GridRowProps = {
    id: string
}

type GridRowState = {    
}

export default class GridRow extends React.PureComponent<GridRowProps,GridRowState> {
    override render(): React.ReactNode {
        return (
            <div id={this.props.id} className="GridRow">
                {this.props.children}
            </div>
        );
    }
}