import classNames from 'classnames';
import React, { MouseEventHandler } from 'react';
import SudokuDef from 'sudokuku-common/src/core/SudokuDef';
import SudokuState from 'sudokuku-common/src/core/SudokuState';
import ViewState from '../../model/ViewState';
import './UIInputInfo.css';

export type UIInputInfoProps = {
    orientation: 'any' | 'portrait' | 'landscape',
    sudoku: SudokuState,
    view: ViewState,
    onClick?: MouseEventHandler
}

export type UIInputInfoState = {
}

export default class UIInputInfo extends React.PureComponent<UIInputInfoProps, UIInputInfoState> {

    override render(): React.ReactNode {
        const def = this.props.sudoku.def();
        const info = def.info();
        const title = info.title() || 'Untitled Puzzle';
        const author = info.author();
        const rules = info.rules() || this.defaultRules(def);
        const links = info.links().split('\n').filter(l => Boolean(l));
        const viewInfo = this.props.view.info();

        const className = classNames({
            'UIInputInfo': true,
            'expanded': viewInfo !== 'hide',
            'collapsed': viewInfo === 'hide',
            'any': this.props.orientation === 'any',
            'portrait': this.props.orientation === 'portrait',
            'landscape': this.props.orientation === 'landscape',
        });

        return (
            <div className={className}>

                <div className="titleBox" id="InputTitle" onClick={this.props.onClick}>
                    <div className="toggle collapse"><svg width="2em" height="1em" viewBox="0 8 48 24" xmlns="http://www.w3.org/2000/svg"><path d="M33.17 17.17l-9.17 9.17-9.17-9.17-2.83 2.83 12 12 12-12z" /></svg></div>
                    <div className="toggle expand"><svg width="2em" height="1em" viewBox="0 8 48 24" xmlns="http://www.w3.org/2000/svg"><path d="M24 16l-12 12 2.83 2.83 9.17-9.17 9.17 9.17 2.83-2.83z" /></svg></div>
                    {title ? <h1 className="title">{title}</h1> : null}
                    {author ? <h2 className="author">by {author}</h2> : null}
                </div>

                {rules ? <ul className="rules">
                    {rules}
                </ul> : null}

                {links.length ? <ul className="links">
                    {links.map(u => <li key={u} className="link" ><a href={u} target="_blank" rel="noreferrer">{u}</a></li>)}
                </ul> : null}

            </div>
        );
    }

    private defaultRules(def: SudokuDef) {
        const constraints = def.constraints().all();
        const rulesMap = new Map(def.constraints().all().map(c => [c.rules(constraints), c]));
        const rulesArray = Array.from(rulesMap.entries()).sort((a, b) => a[1].order() - b[1].order()).map(r => r[1]);

        const rulesElements = rulesArray.map(c => (
            <li key={c.key()} id={`InputRule-${c.key()}`} className="rule" onClick={this.props.onClick}>{c.rules(constraints)}</li>
        ));

        return rulesElements;
    }
}