import SolveElimination from './SolveElimination';
import SolveHint from './SolveHint';

export default class SolveDeduction {

    // Deduction:
    //   Elimination?
    //   Hints[] - final hint is solution text!

    // deduction
    //   hints[]
    //   elimination sources - what caused the elimination (naked pair, swordfish, etc)
    //   elimination reason - textual reason (the Swordfish...)
    // 
    // hint:
    //   text
    //   colors
    //   selection
    //   changes?  (to explain)
    //
    // Naked Single:
    //   Hint 1: Naked Single: This box only has a single square that can contain the digit "9".
    //   Hint 2: The digit "7" can be placed in SSS because the {CCC} squares are ruled out by Scanning the Columns.
    //   Excluding Squares: []
    //   Excluding Reason: []
    //
    // Scan Row:
    //   Hints: []
    //   Eliminaton causes: The given digit (square)
    //   Elimination reson: Scanning the Rows
    //
    // Scan Chess:
    //   Hints: []
    //   Eliminaton causes: The given digit (square)
    //   Elimination reson: the Chess constraints
    //
    // Scan Consecutive:
    //   Hints: []
    //   Eliminaton causes: The given digit (square)
    //   Eliminatin reson: the Consecutive constraints
    //
    // Chess Impossible:
    //   Hint 1: A cell sees all
    //   Elimination causes: The whole row! maybe we just keep it empty...
    //   Elimination rwason: The Chess constraints 

    //   Containing Box or Region
    //   Containing Houses (unique complete constraints that contain the square)
    //   Peer Squares (squares that share a house - cannot be the same digit)

    private readonly _technique: string;
    private readonly _hints: readonly SolveHint[];
    private readonly _eliminations: readonly SolveElimination[];

    private constructor(technique: string, hints: readonly SolveHint[], eliminations: readonly SolveElimination[]) {
        this._technique = technique;
        this._hints = hints;
        this._eliminations = eliminations;
    }

    public technique(): string {
        return this._technique;
    }

    public hints(): readonly SolveHint[] {
        return this._hints;
    }

    public eliminations(): readonly SolveElimination[] {
        return this._eliminations;
    }

    public static fromTechnique(technique: string, hints: readonly SolveHint[], eliminations: readonly SolveElimination[]): SolveDeduction {
        return new SolveDeduction(technique, hints, eliminations);
    }
}