import SudokuError from '../core/SudokuError';

export default class SudokuSerializerError extends SudokuError {

    public static fromMessage(message: string): SudokuSerializerError {
        return new SudokuSerializerError(message);
    }

    public static fromMissing(path: string): SudokuSerializerError {
        return new SudokuSerializerError(`Missing ${path}`);
    }
}