import SudokuStorage from "../../storage/SudokuStorage";
import ViewState from "../model/ViewState";
import ViewSerializer from "../serialization/ViewSerializer";

export default class ViewStorage {

    public static saveView(path: string, view: ViewState): void {

        const json = ViewSerializer.saveView(view);
        const str = JSON.stringify(json);

        SudokuStorage.saveString(path, 'view', str);
    }

    public static loadView(path: string): ViewState | null {

        try {
            const str = SudokuStorage.loadString(path, 'view');
            if (!str) return null;
    
            const json = JSON.parse(str);
            const view = ViewSerializer.loadView(json);

            return view;
        }
        catch {
            return null;
        }
    }
}