
export type ConfigKeypad = 'phone' | 'numpad';

export type ConfigValidate = 'always' | 'never' | 'complete';

export default class ConfigState {

    private _keypad?: ConfigKeypad;
    private _validate?: ConfigValidate;

    private constructor(keypad?: ConfigKeypad, validate?: ConfigValidate) {
        this._keypad = keypad;
        this._validate = validate;
    }

    public keypad(): ConfigKeypad | undefined {
        return this._keypad;
    }

    public validate(): ConfigValidate | undefined {
        return this._validate;
    }

    public setKeypad(keypad?: ConfigKeypad): ConfigState {
        return new ConfigState(keypad);
    }

    public setValidate(validate?: ConfigValidate): ConfigState {
        return new ConfigState(this._keypad, validate);
    }

    public static fromDefault(): ConfigState {
        return new ConfigState();
    }

    public static fromData(keypad?: ConfigKeypad, validate?: ConfigValidate): ConfigState {
        return new ConfigState(keypad, validate);
    }
}