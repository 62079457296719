import DigitSet from '../../core/DigitSet';
import Position from '../../core/Position';
import PositionSet from '../../core/PositionSet';
import SudokuState from '../../core/SudokuState';
import ViolationState from '../../core/ViolationState';
import Constraint from '../Constraint';

export default abstract class DominoConstraint extends Constraint {

    private readonly _squares: PositionSet;
    private readonly _validDigits: (digits: DigitSet) => DigitSet;
    private readonly _validDominos: Set<number>;

    protected constructor(squares: PositionSet, validDigitsFunc: (digits: DigitSet) => DigitSet, prev?: DominoConstraint) {
        super(prev);

        this._squares = squares;
        this._validDigits = validDigitsFunc;
        this._validDominos = DominoConstraint.getValidDominos(validDigitsFunc);
    }

    override order(): number {
        return 1;
    }

    public squares(): PositionSet {
        return this._squares;
    }

    public isUnique(): boolean {
        return false;
    }

    public longName(): string {
        return `${this.name()} ${this.squares().toString()}`;
    }

    public validDigits(digits: DigitSet): DigitSet {
        return this._validDigits(digits);
    }

    public isValidDomino(domino: DigitSet): boolean {
        return this._validDominos.has(domino.toBits());
    }

    public override validate(sudoku: SudokuState, violations: ViolationState): ViolationState {

        for (const domino of this.dominoes()) {

            const a = DigitSet.fromString(sudoku.digit(domino[0]) || '');
            if (a.isEmpty()) continue;

            const b = DigitSet.fromString(sudoku.digit(domino[1]) || '');
            if (b.isEmpty()) continue;

            const isValid = this.isValidDomino(a.unionWith(b));
            if (isValid) continue;

            violations = violations.addViolation(PositionSet.fromArray(domino));
        }

        return violations;
    }

    public dominoes(): [Position, Position][] {
        const dominoes: [Position, Position][] = [];

        for (const square of this._squares.toArray()) {
            const bottomSquare = this.bottomSquare(square);
            if (bottomSquare) dominoes.push([square, bottomSquare]);

            const rightSquare = this.rightSquare(square);
            if (rightSquare) dominoes.push([square, rightSquare]);
        }

        return dominoes;
    }

    public topSquare(square: Position): Position | null {
        const topSquare = square.up();
        return this._squares.has(square) && this._squares.has(topSquare) ? topSquare : null;
    }

    public leftSquare(square: Position): Position | null {
        const leftSquare = square.left();
        return this._squares.has(square) && this._squares.has(leftSquare) ? leftSquare : null;
    }

    public bottomSquare(square: Position): Position | null {
        const bottomSquare = square.down();
        return this._squares.has(square) && this._squares.has(bottomSquare) ? bottomSquare : null;
    }

    public rightSquare(square: Position): Position | null {
        const rightSquare = square.right();
        return this._squares.has(square) && this._squares.has(rightSquare) ? rightSquare : null;
    }

    public static getValidDominos(validDigitsFunc: (digits: DigitSet) => DigitSet): Set<number> {
        const set = new Set<number>();

        for (let digit = 1; digit <= 9; digit++) {
            const firstDigit = DigitSet.fromDigit(digit);
            const secondDigits = validDigitsFunc(firstDigit).all();

            for (const secondDigit of secondDigits) {
                set.add(firstDigit.unionWith(secondDigit).toBits());
            }
        }

        return set;
    }
}