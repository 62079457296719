import React from 'react';
import './UIMenuButton.css';
import UIMenuItem from './UIMenuItem';

export type UIMenuButtonProps = {
    id?: string;
    onClick?: React.MouseEventHandler<HTMLElement>;
    onMenuCollapse: React.MouseEventHandler<HTMLElement>;
}

export type UIMenuButtonState = {
}

export default class UIMenuButton extends React.PureComponent<UIMenuButtonProps,UIMenuButtonState> {

    override render(): React.ReactNode {
        return (
            <UIMenuItem onMenuCollapse={this.props.onMenuCollapse}>
                <button id={this.props.id} onClick={this.props.onClick} tabIndex={2}>
                    {this.props.children}
                </button>
            </UIMenuItem>
        );
    }
}