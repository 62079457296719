import React from 'react';

export enum GridEventsFlags {
    None = 0,
    ShiftKey = 1 << 1,
    CtrlKey = 1 << 2,
    MetaKey = 1 << 3,

    IsMulti = ShiftKey | CtrlKey
}

export type GridEventsCallback = (element: Element, flags: GridEventsFlags) => void;

export type GridEventsProps = {
    onPointerDown?: GridEventsCallback;
    onPointerClick?: GridEventsCallback;
    onPointerLongClick?: GridEventsCallback;
    onPointerDrag?: GridEventsCallback;
    onPointerUp?: GridEventsCallback;
}

export type GridEventsState = {
}

enum GridInputEvent {
    SingleDown,
    SingleUp,
    MultiDown,
    MultiUp,
    SingleMove,
    MultiMove,
    ShortTimeout,
    LongTimeout,
}

enum GridInputState {
    Idle,
    Down,
    DownShortTimeout,
    DownMove,
}

export default class GridEvents extends React.PureComponent<GridEventsProps, GridEventsState> {

    private SHORT_TIMEOUT_MS = 100;
    private LONG_TIMEOUT_MS = 600;

    private readonly _ref: React.RefObject<HTMLDivElement>;
    private _inputState: GridInputState;
    private _inputElement: Element | undefined;
    private _shortTimeout: NodeJS.Timeout | undefined;
    private _longTimeout: NodeJS.Timeout | undefined;
    private _flags: GridEventsFlags;

    constructor(props: GridEventsProps) {
        super(props);

        this._ref = React.createRef<HTMLDivElement>();
        this._inputState = GridInputState.Idle;
        this._flags = GridEventsFlags.None;

        this.handleMouseDown = this.handleMouseDown.bind(this);
        this.handleMouseMove = this.handleMouseMove.bind(this);
        this.handleMouseUp = this.handleMouseUp.bind(this);
        this.handleTouchStart = this.handleTouchStart.bind(this);
        this.handleTouchMove = this.handleTouchMove.bind(this);
        this.handleTouchEnd = this.handleTouchEnd.bind(this);
        this.handleShortTimeout = this.handleShortTimeout.bind(this);
        this.handleLongTimeout = this.handleLongTimeout.bind(this);
        this.handleContextMenu = this.handleContextMenu.bind(this);
    }

    override componentDidMount(): void {
        this._ref.current?.addEventListener("mousedown", this.handleMouseDown);
        this._ref.current?.addEventListener("mousemove", this.handleMouseMove);
        this._ref.current?.addEventListener("mouseup", this.handleMouseUp);
        this._ref.current?.addEventListener("touchstart", this.handleTouchStart);
        this._ref.current?.addEventListener("touchmove", this.handleTouchMove);
        this._ref.current?.addEventListener("touchend", this.handleTouchEnd);
        this._ref.current?.addEventListener("touchcancel", this.handleTouchEnd);
        this._ref.current?.addEventListener("contextmenu", this.handleContextMenu);
    }

    override componentWillUnmount(): void {
        this._ref.current?.removeEventListener("mousedown", this.handleMouseDown);
        this._ref.current?.removeEventListener("mousemove", this.handleMouseMove);
        this._ref.current?.removeEventListener("mouseup", this.handleMouseUp);
        this._ref.current?.removeEventListener("touchstart", this.handleTouchStart);
        this._ref.current?.removeEventListener("touchmove", this.handleTouchMove);
        this._ref.current?.removeEventListener("touchend", this.handleTouchEnd);
        this._ref.current?.removeEventListener("touchcancel", this.handleTouchEnd);
        this._ref.current?.removeEventListener("contextmenu", this.handleContextMenu);
    }

    override render(): React.ReactNode {

        return (
            <div className="GridEvents" ref={this._ref}>
                {this.props.children}
            </div>
        );
    }

    private handleMouseDown(event: MouseEvent) {
        const inputEvent = (event.buttons === 1) ? GridInputEvent.SingleDown : GridInputEvent.MultiDown;
        const inputElement = event.target as Element;

        this._flags = GridEvents.getFlags(event);

        if (this.handleInputEvent(inputEvent, inputElement)) event.preventDefault();
    }

    private handleMouseMove(event: MouseEvent) {
        const inputEvent = (event.buttons === 1) ? GridInputEvent.SingleMove : GridInputEvent.MultiMove;
        const inputElement = event.target as Element;

        this._flags = GridEvents.getFlags(event);

        if (this.handleInputEvent(inputEvent, inputElement)) event.preventDefault();
    }

    private handleMouseUp(event: MouseEvent) {
        const inputEvent = (event.buttons === 0) ? GridInputEvent.SingleUp : GridInputEvent.MultiUp;

        if (this.handleInputEvent(inputEvent)) event.preventDefault();
    }

    private handleTouchStart(event: TouchEvent) {
        const inputEvent = (event.touches.length === 1) ? GridInputEvent.SingleDown : GridInputEvent.MultiDown;
        let inputElement = undefined;

        if (event.touches.length === 1) {
            inputElement = event.touches[0].target as Element;
        }

        this._flags = GridEvents.getFlags(event);

        if (this.handleInputEvent(inputEvent, inputElement)) event.preventDefault();
    }

    private handleTouchMove(event: TouchEvent) {
        const inputEvent = (event.touches.length === 1) ? GridInputEvent.SingleMove : GridInputEvent.MultiMove;
        let inputElement = undefined;

        if (event.touches.length === 1) {
            const touch = event.touches[0];
            inputElement = document.elementFromPoint(touch.clientX, touch.clientY) || undefined;
        }

        this._flags = GridEvents.getFlags(event);

        if (this.handleInputEvent(inputEvent, inputElement)) event.preventDefault();
    }

    private handleTouchEnd(event: TouchEvent) {
        const inputEvent = (event.touches.length === 0) ? GridInputEvent.SingleUp : GridInputEvent.MultiUp;

        if (this.handleInputEvent(inputEvent)) event.preventDefault();
    }

    private handleContextMenu(event: MouseEvent) {
        event.preventDefault();
    }

    private handleShortTimeout() {
        this.handleInputEvent(GridInputEvent.ShortTimeout);
    }

    private handleLongTimeout() {
        this.handleInputEvent(GridInputEvent.LongTimeout);
    }

    private handleInputEvent(inputEvent: GridInputEvent, inputElement?: Element): boolean {

        if (inputEvent === GridInputEvent.SingleMove && this._inputElement === inputElement) return true;

        switch (this._inputState) {

            case GridInputState.Idle:
                if (inputEvent === GridInputEvent.SingleDown) {
                    this._inputState = GridInputState.Down;
                    this._inputElement = inputElement;
                    this._shortTimeout = GridEvents.replaceTimeout(this._shortTimeout, this.SHORT_TIMEOUT_MS, this.handleShortTimeout);
                    this._longTimeout = GridEvents.replaceTimeout(this._longTimeout, this.LONG_TIMEOUT_MS, this.handleLongTimeout);
                    return true;
                }
                break;

            case GridInputState.Down:
                if (inputEvent === GridInputEvent.SingleUp && this._inputElement) {
                    this.props.onPointerDown?.(this._inputElement, this._flags);
                    this.props.onPointerClick?.(this._inputElement, this._flags);
                    this.props.onPointerUp?.(this._inputElement, this._flags);
                    this._inputState = GridInputState.Idle;
                    this._inputElement = undefined;
                    return true;
                }
                else if (inputEvent === GridInputEvent.SingleMove && this._inputElement && inputElement) {
                    this.props.onPointerDown?.(this._inputElement, this._flags);
                    this.props.onPointerDrag?.(inputElement, this._flags);
                    this._inputState = GridInputState.DownMove;
                    this._inputElement = inputElement;
                    return true;
                }
                else if (inputEvent === GridInputEvent.ShortTimeout && this._inputElement) {
                    this.props.onPointerDown?.(this._inputElement, this._flags);
                    this._inputState = GridInputState.DownShortTimeout;
                    return true;
                }
                else if (inputEvent === GridInputEvent.LongTimeout && this._inputElement) {
                    this.props.onPointerDown?.(this._inputElement, this._flags);
                    this.props.onPointerLongClick?.(this._inputElement, this._flags);
                    this.props.onPointerUp?.(this._inputElement, this._flags);
                    this._inputState = GridInputState.Idle;
                    this._inputElement = undefined;
                    return true;
                }
                else if (inputEvent === GridInputEvent.MultiDown) {
                    this._inputState = GridInputState.Idle;
                    this._inputElement = undefined;
                    return false;
                }
                break;

            case GridInputState.DownShortTimeout:
                if (inputEvent === GridInputEvent.SingleUp && this._inputElement) {
                    this.props.onPointerClick?.(this._inputElement, this._flags);
                    this.props.onPointerUp?.(this._inputElement, this._flags);
                    this._inputState = GridInputState.Idle;
                    this._inputElement = undefined;
                    return true;
                }
                else if (inputEvent === GridInputEvent.SingleMove && this._inputElement && inputElement) {
                    this.props.onPointerDrag?.(inputElement, this._flags);
                    this._inputState = GridInputState.DownMove;
                    this._inputElement = inputElement;
                    return true;
                }
                else if (inputEvent === GridInputEvent.LongTimeout && this._inputElement) {
                    this.props.onPointerLongClick?.(this._inputElement, this._flags);
                    this.props.onPointerUp?.(this._inputElement, this._flags);
                    this._inputState = GridInputState.Idle;
                    this._inputElement = undefined;
                    return true;
                }
                else if (inputEvent === GridInputEvent.MultiDown && this._inputElement) {
                    this.props.onPointerUp?.(this._inputElement, this._flags);
                    this._inputState = GridInputState.Idle;
                    this._inputElement = undefined;
                    return false;
                }
                break;

            case GridInputState.DownMove:
                if (inputEvent === GridInputEvent.SingleUp && this._inputElement) {
                    this.props.onPointerUp?.(this._inputElement, this._flags);
                    this._inputState = GridInputState.Idle;
                    this._inputElement = undefined;
                    return true;
                }
                else if (inputEvent === GridInputEvent.SingleMove && this._inputElement && inputElement) {
                    this.props.onPointerDrag?.(inputElement, this._flags);
                    this._inputElement = inputElement;
                    return true;
                }
                else if (inputEvent === GridInputEvent.MultiDown && this._inputElement) {
                    this.props.onPointerUp?.(this._inputElement, this._flags);
                    this._inputState = GridInputState.Idle;
                    this._inputElement = undefined;
                    return false;
                }
                break;
        }

        return false;
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private static replaceTimeout(timeout: NodeJS.Timeout | undefined, ms: number, callback: (...args: any[]) => void): NodeJS.Timeout {
        if (timeout) clearTimeout(timeout);
        return setTimeout(callback, ms);
    }

    private static getFlags(event: MouseEvent | TouchEvent): GridEventsFlags {
        let flags = GridEventsFlags.None;

        if (event.shiftKey) flags |= GridEventsFlags.ShiftKey;
        if (event.ctrlKey) flags |= GridEventsFlags.CtrlKey;
        if (event.metaKey) flags |= GridEventsFlags.MetaKey;

        return flags;
    }

    private static rectContainsPoint(rect: DOMRect, x: number, y: number): boolean {
        return (x >= rect.left) && (x <= rect.right) && (y >= rect.top) && (y <= rect.bottom);
    }
}