import PositionSet from "sudokuku-common/src/core/PositionSet";
import SudokuState from "sudokuku-common/src/core/SudokuState";
import ClassicSerializer from 'sudokuku-common/src/serialization/ClassicSerializer';

export default class GridClipboard {

    public static copyAsText(sudoku: SudokuState, selection: PositionSet): void {

        const clipboard = navigator.clipboard;
        if (!clipboard) {
            console.warn('Clipboard is not supported');
            return;
        }

        const text = ClassicSerializer.saveSudoku(sudoku, { selection: selection });

        clipboard.writeText(text)
            .then(v => {
                // do nothing
            })
            .catch(err => {
                console.warn(err);
            });
    }
}