import classNames from "classnames";
import React from "react";
import { ConstraintProps } from "sudokuku-common/src/constraints/ConstraintReg";
import ArrowConstraint from "sudokuku-common/src/constraints/line/ArrowConstraint";
import './ArrowConstraintCell.css';

export default function ArrowConstraintCell(props: ConstraintProps): React.ReactElement| null {

    const constraint = props.constraint as ArrowConstraint;

    const [iDir, oDir] = constraint.cellDirections(props.pos);
    if (!iDir && !oDir) return null;

    const bulb = constraint.isStart(props.pos);
    const tip = constraint.isEnd(props.pos);

    const iClassName = classNames(['ArrowConstraint', 'content', 'line', 'd' + iDir]);
    const oClassName = classNames(['ArrowConstraint', 'content', 'line', 'd' + oDir]);
    const tClassName = classNames(['ArrowConstraint', 'content', 'tip', 't' + iDir]);
    const bClassName = classNames(['ArrowConstraint', 'content', 'bulb']);

    return (
        <React.Fragment>
            {iDir ? <div className={iClassName} /> : null}
            {oDir ? <div className={oClassName} /> : null}
            {bulb ? <div className={bClassName} /> : null}
            {tip ? <div className={tClassName} /> : null}
        </React.Fragment>);
}