import DbSearchResult from "sudokuku-common/src/db/DbSearchResult";
import SudokuApi from "../../api/v1/SudokuApi";
import Auth from "../../auth/Auth";
import SearchQuery from "./SearchQuery";

export default class SearchResults {

    private readonly _results: DbSearchResult[];

    private constructor(results: DbSearchResult[]) {
        this._results = results;
    }

    public metadata(): DbSearchResult[] {
        return this._results;
    }

    public static fromEmpty(): SearchResults {
        return new SearchResults([]);
    }

    public static async fromQueryAsync(auth: Auth, query: SearchQuery): Promise<SearchResults> {
        const items = await SudokuApi.getSearchResults(auth, query.query(), query.pageNum(), query.pageSize());
        return new SearchResults(items);
    }
}