// Rich Text:
//   {}       <span />
//   {a}      <span className="a" />
//   {a:x}    <span className="a">x</span>
//   {a b:x}  <span className="a b">x</span>
//   {a:{b}}  <span className="a"><span className="b" /></span>
//   \{\}}    {}
//   \\       \

export enum RichTextTokenKind {
    Text,
    StartSpan,
    EndSpan,
    ClassName,
    End,
}

export type RichTextToken = {
    kind: RichTextTokenKind,
    value: string,
    index: number,
}

export default class RichTextScanner {

    public static *scan(str: string): Generator<RichTextToken> {
        let kind = RichTextTokenKind.Text;
        let value = '';

        for (let i = 0; i < str.length; i++) {
            switch (str[i]) {

                case '{': {
                    if (value.length) yield { kind: kind, value: value, index: i - 1 };
                    yield { kind: RichTextTokenKind.StartSpan, value: '', index: i };
                    kind = RichTextTokenKind.ClassName;
                    value = '';
                    break;
                }

                case '}': {
                    if (value.length) yield { kind: kind, value: value, index: i - 1 };
                    yield { kind: RichTextTokenKind.EndSpan, value: '', index: i };
                    kind = RichTextTokenKind.Text;
                    value = '';
                    break;
                }

                case ':': {
                    if (kind === RichTextTokenKind.ClassName) {
                        if (value.length) yield { kind: kind, value: value, index: i - 1 };
                        kind = RichTextTokenKind.Text;
                        value = '';
                    }
                    else {
                        value += str[i];
                    }
                    break;
                }

                case '\\':
                    if (str[i + 1] === '{') {
                        value += str[++i];
                    }
                    else if (str[i + 1] === '}') {
                        value += str[++i];
                    }
                    else if (str[i + 1] === '\\') {
                        value += str[++i];
                    }
                    else {
                        value += str[i];
                    }
                    break;

                default:
                    value += str[i];
                    break;
            }
        }

        if (value.length) yield { kind: kind, value: value, index: str.length - 1 };
        yield { kind: RichTextTokenKind.End, value: '', index: str.length };
    }
}