import React from 'react';
import SearchForm from '../model/SearchForm';
import SearchQuery from '../model/SearchQuery';
import './UISearchForm.css';

export type UISearchFormProps = {
    inputRef: React.RefObject<HTMLInputElement>,
    query: SearchQuery,
    onSearch: (query: SearchQuery) => void,
}

export type UISearchFormState = {
    query: SearchQuery,
    form: SearchForm,
}

export default class UISearchForm extends React.Component<UISearchFormProps, UISearchFormState> {

    constructor(props: UISearchFormProps) {
        super(props);

        this.state = {
            query: props.query,
            form: SearchForm.fromQuery(props.query)
        }
    }

    static getDerivedStateFromProps(nextProps: UISearchFormProps, prevState: UISearchFormState): Partial<UISearchFormState> | null {

        // derive new state if query has changed
        if (prevState.query === nextProps.query) return null;

        return { query: nextProps.query, form: SearchForm.fromQuery(nextProps.query) };
    }

    override render(): React.ReactNode {

        return (
            <div className="UISearchForm">

                <div className="search">

                    <input
                        className="text"
                        type="text"
                        placeholder="Search"
                        ref={this.props.inputRef}
                        value={this.state.form.text()}
                        onChange={e => this.handleTextChange(e)}
                        onKeyDown={e => this.handleTextKeyDown(e)} />

                    <div className="buttons">

                        <button onClick={e => this.handleSearchClick(e)}>
                            <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000">
                                <title>Search</title>
                                <path d="M0 0h24v24H0V0z" fill="none" />
                                <path d="M15.5 14h-.79l-.28-.27C15.41 12.59 16 11.11 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 5.91 16 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z" />
                            </svg>
                        </button>

                        <button onClick={e => this.handleClearClick(e)}>
                            <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000">
                                <title>Clear</title>
                                <path d="M0 0h24v24H0V0z" fill="none" />
                                <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12 19 6.41z" />
                            </svg>
                        </button>

                    </div>
                </div>
            </div>
        );
    }

    private handleTextChange(event: React.FormEvent<HTMLInputElement>) {
        const element = event.target as HTMLInputElement;

        this.setState((s, p) => ({ form: s.form.setText(element.value) }));
    }

    private handleTextKeyDown(event: React.KeyboardEvent<HTMLInputElement>) {
        switch (event.code) {

            case 'Enter':
                event.preventDefault();
                this.props.onSearch(this.state.form.toQuery());
                break;

            case 'Escape':
                event.currentTarget.blur();
                break;
        }
    }

    private handleSearchClick(event: React.MouseEvent<HTMLButtonElement>) {
        this.props.onSearch(this.state.form.toQuery());
    }

    private handleClearClick(event: React.MouseEvent<HTMLButtonElement>) {
        this.props.onSearch(SearchQuery.fromEmpty());
    }

}