import Property from "./Property";

export default class PropertyObject {

    private readonly _ref: unknown;
    private readonly _data: unknown;
    private readonly _properties: readonly Property[];
    private readonly _children: readonly PropertyObject[];

    public constructor(prev: PropertyObject | undefined, data: unknown, properties?: readonly Property[], children?: readonly PropertyObject[]) {
        this._ref = prev?.ref() ?? this;
        this._data = data;
        this._properties = properties ?? prev?.properties() ?? [];
        this._children = children ?? prev?.children() ?? [];
    }

    public ref(): unknown {
        return this._ref;
    }

    public data(): unknown {
        return this._data;
    }

    public properties(): readonly Property[] {
        return this._properties;
    }

    public children(): readonly PropertyObject[] {
        return this._children;
    }
}