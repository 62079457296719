import Position from '../../core/Position';
import PositionSet from '../../core/PositionSet';
import ConstraintDef, { ConstraintDefFlags } from '../ConstraintDef';
import UniqueConstraint from './UniqueConstraint';

export default class BoxConstraint extends UniqueConstraint {

    private static readonly _box = new ConstraintDef('box', 'Box', 'Unique', [], -2, ConstraintDefFlags.IsStandard);

    protected constructor(squares: PositionSet) {
        super(squares);
    }

    public static def(): ConstraintDef {
        return this._box;
    }

    public def(): ConstraintDef {
        return BoxConstraint.def();
    }

    public longName(): string {
        return `${this.name()} ${this.squares().first()?.toString()}`;
    }

    public rules(): string {
        return 'Digits cannot repeat in each box.'
    }

    public toString(): string {
        return this.squares().first()?.toString() || '';
    }

    public static fromString(str: string): BoxConstraint {
        return BoxConstraint.fromStart(Position.fromString(str));
    }

    public static fromStart(start: Position): BoxConstraint {
        const squares: Position[] = [];

        for (let i = 0; i < 9; i++) {
            const square = start.offset(i / 3, i % 3);
            squares.push(square);
        }

        return new BoxConstraint(PositionSet.fromArray(squares));
    }

    public static fromSquares(squares: PositionSet): BoxConstraint {
        return new BoxConstraint(squares);
    }
}