import Position from '../core/Position';
import PositionSet from '../core/PositionSet';
import SudokuConstraints from '../core/SudokuConstraints';
import SudokuDef from '../core/SudokuDef';
import SudokuInfo from '../core/SudokuInfo';
import SudokuState from '../core/SudokuState';
import SudokuSerializer from './SudokuSerializer';
import SudokuSerializerError from './SudokuSerializerError';

export type ClassicSerializerOptions = {
    newline?: string;
    unsolved?: string;
    selection?: PositionSet;
}

export default class ClassicSerializer {

    private static readonly _classicConstraintsJson = {
        "box": ["a1", "a4", "a7", "d1", "d4", "d7", "g1", "g4", "g7"],
        "col": ["a1", "a2", "a3", "a4", "a5", "a6", "a7", "a8", "a9"],
        "row": ["a1", "b1", "c1", "d1", "e1", "f1", "g1", "h1", "i1"]
    };

    //#region save

    public static saveSudoku(sudoku: SudokuState, options?: ClassicSerializerOptions): string {

        let str = '';
        const def = sudoku.def();
        const numRows = def.numRows();
        const numCols = def.numCols();

        const newline = options?.newline ?? '\n';
        const unsolved = options?.unsolved ?? '.';

        for (let row = 1; row <= numRows; row++) {

            if (str.length && !str.endsWith(newline)) str += newline;

            for (let col = 1; col <= numCols; col++) {
                const pos = Position.fromRowCol(row, col);

                const isSelected = options?.selection?.has(pos) ?? true;
                if (!isSelected) continue;

                const digit = sudoku.digit(pos);
                if (digit) {
                    str += digit;
                }
                else if (def.isSquare(pos)) {
                    str += unsolved;
                }
                else {
                    str += '#';
                }
            }
        }

        return str;
    }

    //#endregion save

    //#region load

    public static canLoadSudoku(str: string): boolean {
        return this.trimString(str).length === 81;
    }

    public static loadSudoku(str: string, revision = 1, info = SudokuInfo.fromEmpty()): SudokuState {

        // trim spaces
        str = this.trimString(str);

        // standard sudoku string (just digits, no newlines)
        if (str.length == 81) {
            str = this.chunkString(str, 9).join('\n');
        }

        // standard sudoku string (just digits, with newlines)
        if (str.length === 89) {
            const def = SudokuDef.fromGrid(revision, info, str, this.classicConstraints());
            return SudokuState.fromDef(def);
        }

        // unknown format
        throw new SudokuSerializerError('Unknown format');
    }

    private static classicConstraints(): SudokuConstraints {
        return SudokuSerializer.loadSudokuConstraints(this._classicConstraintsJson, 'constraints');
    }

    private static trimString(str: string): string {
        let trimmed = '';

        for (let i = 0; i < str.length; i++) {
            switch (str[i]) {
                case '0':
                case '1':
                case '2':
                case '3':
                case '4':
                case '5':
                case '6':
                case '7':
                case '8':
                case '9':
                case '.':
                case '#':
                    trimmed += str[i];
                    break;
            }
        }

        return trimmed;
    }

    private static chunkString(str: string, size: number): string[] {
        const numChunks = Math.ceil(str.length / size)
        const chunks = new Array(numChunks);

        for (let i = 0, o = 0; i < numChunks; i += 1, o += size) {
            chunks[i] = str.substr(o, size);
        }

        return chunks;
    }

    //#endregion load
}