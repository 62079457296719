import DigitSet from '../../core/DigitSet';
import Position from '../../core/Position';
import PositionSet from '../../core/PositionSet';
import SudokuState from '../../core/SudokuState';
import Property, { PropertyFlags } from '../../properties/Property';
import PropertySelection from '../../properties/PropertySelection';
import Constraint from '../Constraint';
import ConstraintDef from '../ConstraintDef';
import DiffNegativeConstraint from '../global/DiffNegativeConstraint';
import DominoConstraint from './DominoConstraint';

export default class DiffConstraint extends DominoConstraint {

    private static readonly _diff = new ConstraintDef('diff', 'Kropki Diff', 'Domino');

    protected constructor(squares: PositionSet) {
        super(squares, d => DiffConstraint.getValidDigits(d));
    }

    public static def(): ConstraintDef {
        return this._diff;
    }

    public override def(): ConstraintDef {
        return DiffConstraint.def();
    }

    public rules(constraints: Constraint[]): string {
        const negative = constraints.find(c => c instanceof DiffNegativeConstraint);
        const negativeRule = negative ? '' : ' Not all white dots are given.';

        return 'Cells separated by a white dot contain consecutive digits.' + negativeRule;
    }

    public toString(): string {
        return this.squares().toString();
    }

    public static fromString(str: string): DiffConstraint {
        return DiffConstraint.fromSquares(PositionSet.fromString(str));
    }

    static fromSquares(squares: PositionSet): DiffConstraint {
        return new DiffConstraint(squares);
    }

    public static fromSelection(sudoku: SudokuState, selection: Position[], propertySelection: [PropertySelection]): SudokuState {
        
        const squares = PositionSet.fromArray(selection);

        return Constraint.add(sudoku, new DiffConstraint(squares), propertySelection);
    }

    private static getValidDigits(digits: DigitSet): DigitSet {
        return digits.increase(1).unionWith(digits.decrease(1));
    }

    protected override getProperties(properties: Property[]): void {
        properties.push(new Property('Difference', this.name(), 'text', PropertyFlags.IsReadOnly, this, () => '1'));
    }
}