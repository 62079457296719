import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import UIUserMenu from '../ui-user/UIUserMenu';
import UIBody from './UIBody';
import UIHeader from './UIHeader';
import './UIRoot.css';

export type UIRootProps = {
}

export type UIRootState = {
    isMenuExpanded: boolean;
}

export default class UIRoot extends React.PureComponent<UIRootProps, UIRootState> {

    public static readonly Title = 'Sudokuku';

    constructor(props: UIRootProps) {
        super(props);

        this.state = {
            isMenuExpanded: false
        }

        this.handleMenuExpand = this.handleMenuExpand.bind(this);
        this.handleMenuCollapse = this.handleMenuCollapse.bind(this);
    }

    override render(): React.ReactNode {
        return (
            <BrowserRouter>
                <div className="UIRoot">
                    <UIHeader />
                    <UIBody isMenuExpanded={this.state.isMenuExpanded} onMenuExpand={this.handleMenuExpand} onMenuCollapse={this.handleMenuCollapse} />
                    <UIUserMenu />
                </div>
            </BrowserRouter>
        );
    }

    private handleMenuExpand(event: React.MouseEvent<HTMLElement>) {
        this.setState({ isMenuExpanded: true });
    }

    private handleMenuCollapse(event: React.MouseEvent<HTMLElement>) {
        this.setState({ isMenuExpanded: false });
    }
}