import It from "../core/It";
import MultiMap from "../core/MultiMap";
import Property, { PropertyContext, PropertyFlags } from "./Property";
import PropertyObject from "./PropertyObject";

export default class PropertySelection {

    private readonly _refs: ReadonlySet<unknown>;
    private readonly _setFocus: boolean;

    private constructor(refs: Iterable<unknown>, setFocus = false) {
        this._refs = new Set(refs);
        this._setFocus = setFocus;
    }

    public static fromEmpty(): PropertySelection {
        return new PropertySelection([]);
    }

    public static fromObjects(objects: PropertyObject | Iterable<PropertyObject>): PropertySelection {
        return new PropertySelection(It(objects).map(o => o.ref()));
    }

    public isSelected(object: PropertyObject): boolean {
        return this._refs.has(object.ref());
    }

    public isFocused(property: Property): boolean {
        return this._setFocus && property.isFocused();
    }

    public addSelection(objects: PropertyObject | Iterable<PropertyObject>): PropertySelection {
        return new PropertySelection([...this._refs, ...It(objects).map(o => o.ref())]);
    }

    public remSelection(objects: PropertyObject | Iterable<PropertyObject>): PropertySelection {
        return new PropertySelection(It(this._refs).filter(r => !It(objects).some(o => o.ref() === r)));
    }

    public setSelection(objects: PropertyObject | Iterable<PropertyObject>): PropertySelection {
        return new PropertySelection(It(objects).map(o => o.ref()));
    }

    public setFocus(): PropertySelection {
        return new PropertySelection(this._refs, true);
    }

    public unionWith(other: PropertySelection): PropertySelection {
        return new PropertySelection([...this._refs, ...other._refs]);
    }

    public objects(roots: PropertyObject | Iterable<PropertyObject>): readonly PropertyObject[] {
        const objects: PropertyObject[] = [];
        return this.getSelectedObjects(objects, roots);
    }

    public properties(roots: PropertyObject | Iterable<PropertyObject>): readonly Property[] {
        const objects = this.objects(roots);
        if (objects.length === 0) return [];
        if (objects.length === 1) return objects[0].properties();

        const properties = objects.flatMap(o => o.properties());
        const propertiesByKey = MultiMap.groupBy(properties, p => p.key());
        const compoundProperties = It(propertiesByKey).map(([k, v]) => this.compoundProperty(k, v));

        return compoundProperties.toArray();
    }

    private compoundProperty(key: string, properties: Property[]): Property {
        const primary = properties[0];
        if (properties.length === 1) return primary;

        // are any properties read only?
        let flags = PropertyFlags.None;
        if (properties.some(p => p.isReadOnly())) flags = flags | PropertyFlags.IsReadOnly;

        // create compound property
        return new Property(primary.label(), `${primary.category()} (${properties.length})`, primary.type(), flags, null,
            (c, p) => this.compoundValue(c, p, properties),
            (c, p, v) => properties.forEach(p => p.setValue(c, v)),
            (c, p, v) => It(properties).all(p => p.validateValue(c, v)),
            (c, p) => this.compoundPlaceholder(c, p, properties));
    }

    private compoundValue(c: PropertyContext, p: Property, properties: Property[]): string {
        const values = It(properties).map(p => p.value(c)).distinct().toArray();
        return (values.length <= 1 || p.isReadOnly()) ? values.join(',') : '';
    }

    private compoundPlaceholder(c: PropertyContext, p: Property, properties: Property[]): string {
        return It(properties).map(p => p.value(c)).distinct().join(',');
    }

    private getSelectedObjects(objects: PropertyObject[], roots: PropertyObject | Iterable<PropertyObject>): PropertyObject[] {

        for (const root of It(roots)) {
            if (this.isSelected(root)) objects.push(root);
            this.getSelectedObjects(objects, root.children());
        }

        return objects;
    }
}
