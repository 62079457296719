import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import SudokuDef from 'sudokuku-common/src/core/SudokuDef';
import SudokuState from 'sudokuku-common/src/core/SudokuState';
import ViolationState from 'sudokuku-common/src/core/ViolationState';
import SudokuApi from '../api/v1/SudokuApi';
import UIMenu, { UIMenuProps } from '../ui-menu/UIMenu';
import UIMenuHamburger from '../ui-menu/UIMenuHamburger';
import UIMenuLink from '../ui-menu/UIMenuLink';
import Grid from './components/grid/Grid';
import UIInputInfo from './components/ui/UIInputInfo';
import SelectionState from './model/SelectionState';
import ViewState from './model/ViewState';
import './UIPrint.css';

export type UIPrintParams = {
    id: string
}

export type UIPrintProps = UIMenuProps & RouteComponentProps<UIPrintParams> & {
}

export type UIPrintState = {
    sudoku: SudokuState
}

export default class UIPrint extends React.PureComponent<UIPrintProps, UIPrintState> {

    private _info: string;

    constructor(props: UIPrintProps) {
        super(props);

        const params = new URLSearchParams(props.location.search);
        this._info = params.get('info') ?? 'show';

        this.state = {
            sudoku: SudokuState.fromDef(SudokuDef.fromEmpty()),
        };
    }

    override componentDidMount(): void {
        this.loadServerSudoku();
    }

    override render(): React.ReactNode {

        const sudoku = this.state.sudoku;
        const selection = SelectionState.fromSudoku(sudoku);
        const violations = ViolationState.fromEmpty();
        const view = ViewState.fromDefault();

        return (
            <div className="UIPrint">
                {this._info === 'show' ? <UIInputInfo orientation="any" sudoku={sudoku} view={view} /> : null}
                <div className="UIPrintGrid">
                    <Grid sudoku={sudoku} selection={selection} violations={violations} />
                </div>
                <UIMenu {...this.props}>
                    <UIMenuHamburger {...this.props} />
                    <UIMenuLink {...this.props} to="/">Home</UIMenuLink>
                </UIMenu>
            </div>
        );
    }

    private loadServerSudoku(canUndo = false): Promise<void> {
        return SudokuApi.getSudoku(this.props.match.params.id).then(sudoku => this.setState((s, p) => ({ sudoku: sudoku })));
    }
}