/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */

import Constraint from '../constraints/Constraint';
import ConstraintReg from '../constraints/ConstraintReg';
import DigitSet from '../core/DigitSet';
import MultiMap from '../core/MultiMap';
import Position from '../core/Position';
import PositionSet from '../core/PositionSet';
import PositionSets from '../core/PositionSets';
import SudokuChange, { SudokuChangeOp, SudokuChangeTarget } from '../core/SudokuChange';
import SudokuConstraints from '../core/SudokuConstraints';
import SudokuDef from '../core/SudokuDef';
import SudokuError from '../core/SudokuError';
import SudokuInfo from '../core/SudokuInfo';
import SudokuKeywords from '../core/SudokuKeywords';
import SudokuState from '../core/SudokuState';
import DbCreateResult from '../db/DbCreateResult';
import DbSearchResult from '../db/DbSearchResult';
import SolveDeduction from '../solver/SolveDeduction';
import SolveHint from '../solver/SolveHint';
import SolvePath from '../solver/SolvePath';
import SolveStep from '../solver/SolveStep';
import SudokuSerializerError from './SudokuSerializerError';

export type SudokuSerializerOptions = {
    maxHistoryDepth?: number;
}

export default class SudokuSerializer {

    public static readonly Version: string = '1';

    //#region save

    public static saveSudoku(sudoku: SudokuState, options?: SudokuSerializerOptions): any {

        const json = {
            version: SudokuSerializer.Version,
            sudoku: this.saveSudokuState(sudoku, undefined, 0, options)
        }

        return json;
    }

    public static saveSolution(solvePath: SolvePath): any {

        const json = {
            version: SudokuSerializer.Version,
            solvePath: this.saveSolvePath(solvePath),
        }

        return json;
    }

    private static saveSudokuState(sudoku: SudokuState, parent: SudokuState | undefined, depth: number, options?: SudokuSerializerOptions): any {

        const json: any = {};

        const maxDepth = options?.maxHistoryDepth ?? 0;

        const def = !parent || sudoku.def() !== parent.def() ? sudoku.def() : undefined;
        const digits = !parent || sudoku.allDigits() !== parent.allDigits() ? sudoku.allDigits() : undefined;
        const centerMarks = !parent || sudoku.allCenterMarks() !== parent.allCenterMarks() ? sudoku.allCenterMarks() : undefined;
        const cornerMarks = !parent || sudoku.allCornerMarks() !== parent.allCornerMarks() ? sudoku.allCornerMarks() : undefined;
        const colorMarks = !parent || sudoku.allColorMarks() !== parent.allColorMarks() ? sudoku.allColorMarks() : undefined;
        const prev = (depth < maxDepth) ? sudoku.prev() : undefined;

        if (def) json.def = this.saveSudokuDef(def);
        if (digits) json.digits = this.saveSudokuDigits(digits);
        if (centerMarks) json.centerMarks = this.saveSudokuDigits(centerMarks);
        if (cornerMarks) json.cornerMarks = this.saveSudokuDigits(cornerMarks);
        if (colorMarks) json.colorMarks = this.saveSudokuDigits(colorMarks);
        if (prev) json.prev = this.saveSudokuState(prev, sudoku, depth + 1, options);

        return json;
    }

    private static saveSudokuDef(def: SudokuDef) {

        const json: any = {};

        json.revision = def.revision();
        json.info = this.saveSudokuInfo(def.info());
        json.grid = def.grid();
        if (!def.constraints().isEmpty()) json.constraints = this.saveSudokuConstraints(def.constraints());

        const keywords = SudokuKeywords.fromDef(def);
        if (keywords.length > 0) json.keywords = keywords;

        return json;
    }

    private static saveSudokuInfo(info: SudokuInfo): any {

        const json: any = {};

        if (info.title()) json.title = info.title();
        if (info.author()) json.author = info.author();
        if (info.rules()) json.rules = info.rules();
        if (info.links()) json.links = info.links();
        if (info.isPublic()) json.isPublic = info.isPublic();
        if (info.keywords().length) json.keywords = info.keywords();

        return json;
    }


    private static saveSudokuConstraints(constraints: SudokuConstraints): any {

        const json = {} as any;

        // group by kind
        const byKind = MultiMap.groupBy(constraints.all(), c => c.kind());

        // sort kinds
        const kinds = Array.from(byKind.keys()).sort();

        // serialize constraints by kind
        for (const kind of kinds) {
            const byKindConstraints = byKind.get(kind) ?? [];
            const byKindJson = byKindConstraints.map(c => c.toString());
            json[kind] = byKindJson;
        }

        // done
        return json;
    }

    private static saveSudokuDigits(map: Map<string, string>): any {
        const json = {} as any;

        // group by digits
        const byDigits = MultiMap.groupByMap(map.entries(), ([k, v]) => v, ([k, v]) => Position.fromString(k));

        // sort digits
        const digits = Array.from(byDigits.keys()).sort();

        // get position set for each value
        for (const digit of digits) {
            const squares = byDigits.get(digit) ?? [];
            json[digit] = PositionSet.fromArray(squares).toString();
        }

        // done
        return json;
    }

    private static saveSolvePath(solvePath: SolvePath): any {

        const json: any = {};

        json.revision = solvePath.revision();
        json.isComplete = solvePath.isComplete();
        json.steps = solvePath.steps().map(s => this.saveSolveStep(s));

        return json;
    }

    private static saveSolveStep(step: SolveStep): any {

        const json: any = {};

        json.deduction = this.saveSolveDeduction(step.deduction());
        if (step.changes().length > 0) json.changes = step.changes().map(c => this.saveSudokuChange(c));

        return json;
    }

    private static saveSolveDeduction(deduction: SolveDeduction): any {

        const json: any = {};

        if (deduction.technique()) json.technique = deduction.technique();
        if (deduction.hints().length > 0) json.hints = deduction.hints().map(h => this.saveSolveHint(h));

        return json;
    }

    private static saveSolveHint(hint: SolveHint): any {

        const json: any = {};

        if (hint.text()) json.text = hint.text();
        if (hint.squares().size() > 0) json.squares = hint.squares().toString();
        if (hint.colors().size() > 0) json.colors = hint.colors().toArray().map(c => c.toString());
        if (hint.causes().size() > 0) json.causes = hint.causes().toArray().map(c => c.toString());

        return json;
    }

    private static saveSudokuChange(change: SudokuChange): any {

        const json = {
            op: change.op().toString(),
            target: change.target().toString(),
            squares: change.squares().toString(),
            digits: change.digits().toString(),
        }

        return json;
    }

    //#endregion save

    //#region load

    public static tryLoadSudoku(json: any): SudokuState | SudokuError {
        try {
            return this.loadSudoku(json);
        }
        catch (e) {
            if (e instanceof SudokuError) return e;
            throw e;
        }
    }

    public static loadSudoku(json: any): SudokuState {

        if (!json) throw SudokuSerializerError.fromMissing('json');

        // validate version
        const versionJson = json.version as string;
        if (!versionJson) throw SudokuSerializerError.fromMissing('version');
        if (versionJson !== SudokuSerializer.Version) throw SudokuSerializerError.fromMessage(`Invalid version (${versionJson} != ${SudokuSerializer.Version})`);

        // load
        const state = this.loadSudokuState(json.sudoku, 'sudoku', undefined);

        // done
        return state;
    }

    public static loadSolution(json: any): SolvePath {

        if (!json) throw SudokuSerializerError.fromMissing('json');

        // validate version
        const versionJson = json.version as string;
        if (!versionJson) throw SudokuSerializerError.fromMissing('version');
        if (versionJson !== SudokuSerializer.Version) throw SudokuSerializerError.fromMessage(`Invalid version (${versionJson} != ${SudokuSerializer.Version})`);

        // load
        const path = this.loadSolvePath(json.solvePath, 'solvePath');

        // done
        return path;
    }

    public static loadDbCreateResult(json: any): DbCreateResult {
        if (!json) throw SudokuSerializerError.fromMissing('json');

        // validate version
        const versionJson = json.version as string;
        if (!versionJson) throw SudokuSerializerError.fromMissing('version');
        if (versionJson !== SudokuSerializer.Version) throw SudokuSerializerError.fromMessage(`Invalid version (${versionJson} != ${SudokuSerializer.Version})`);

        // load
        const create_result = DbCreateResult.fromRow(json.create_result);

        // done
        return create_result;
    }

    public static loadDbSearchResults(json: any): DbSearchResult[] {
        if (!json) throw SudokuSerializerError.fromMissing('json');

        // validate version
        const versionJson = json.version as string;
        if (!versionJson) throw SudokuSerializerError.fromMissing('version');
        if (versionJson !== SudokuSerializer.Version) throw SudokuSerializerError.fromMessage(`Invalid version (${versionJson} != ${SudokuSerializer.Version})`);

        // load
        const search_results = this.loadArray(json.search_results, `search_results`, (j, p) => DbSearchResult.fromRow(j));

        // done
        return search_results;
    }

    private static loadSudokuState(json: any, path: string, parent: SudokuState | undefined): SudokuState {

        // empty state is not okay
        if (!json) throw SudokuSerializerError.fromMissing(path);

        // load
        const def = (parent && !json.def) ? parent.def() : this.loadSudokuDef(json.def, `${path}.def`);
        const digits = (parent && !json.digits) ? parent.allDigits() : this.loadSudokuDigits(json.digits, `${path}.digits`);
        const centerMarks = (parent && !json.centerMarks) ? parent.allCenterMarks() : this.loadSudokuDigits(json.centerMarks, `${path}.centerMarks`);
        const cornerMarks = (parent && !json.cornerMarks) ? parent.allCornerMarks() : this.loadSudokuDigits(json.cornerMarks, `${path}.cornerMarks`);
        const colorMarks = (parent && !json.colorMarks) ? parent.allColorMarks() : this.loadSudokuDigits(json.colorMarks, `${path}.colorMarks`);

        // create sudoku (without history)
        let sudoku = SudokuState.fromData(def, digits, centerMarks, cornerMarks, colorMarks);

        // load history
        if (json.prev) sudoku = sudoku.setPrev(this.loadSudokuState(json.prev, `${path}.prev`, sudoku));

        // done
        return sudoku;
    }

    private static loadSudokuDigits(json: any, path: string): Map<string, string> {

        const digits = new Map<string, string>();

        // empty digits is okay
        if (!json) return digits;

        // load digits
        for (const digit of Object.keys(json)) {
            const squaresStr = json[digit] as string;
            if (!squaresStr) throw SudokuSerializerError.fromMissing(`${path}.${digit}`);

            for (const pos of PositionSet.fromString(squaresStr).toArray()) {
                digits.set(pos.toString(), digit);
            }
        }

        return digits;
    }

    private static loadSudokuDef(json: any, path: string): SudokuDef {

        // empty def is not okay
        if (!json) throw SudokuSerializerError.fromMissing(path);

        // load
        const revision = json.revision as number ?? 1;
        const info = this.loadSudokuInfo(json.info);
        const grid = this.loadSudokuGrid(json.grid, `${path}.grid`);
        const constraints = this.loadSudokuConstraints(json.constraints, `${path}.constraints`);

        // done
        return SudokuDef.fromGrid(revision, info, grid, constraints);
    }

    private static loadSudokuInfo(json: any): SudokuInfo {

        // empty info is okay
        if (!json) return SudokuInfo.fromEmpty();

        const title = json.title as string ?? '';
        const author = json.author as string ?? '';
        const rules = json.rules as string ?? '';
        const links = json.links as string ?? '';
        const isPublic = json.isPublic as boolean ?? false;
        const keywords = json.keywords as string[] ?? [];

        return SudokuInfo.fromData(title, author, rules, links, isPublic, keywords);
    }

    private static loadSudokuGrid(json: any, path: string): string {

        // empty grid is not okay
        const grid = json as string;
        if (!grid) throw SudokuSerializerError.fromMissing(path);

        return grid;
    }

    public static loadSudokuConstraints(json: any, path: string): SudokuConstraints {

        // empty constraints is okay
        if (!json) return SudokuConstraints.fromEmpty();

        const constraints: Constraint[] = [];

        // load constraints
        for (const kind of Object.keys(json)) {
            const array = json[kind] as Array<any>;
            if (!array) throw SudokuSerializerError.fromMissing(`${path}.${kind}`);

            const byKind = array.map(j => this.loadSudokuConstraint(kind, j, `${path}.${kind}`));
            constraints.push(...byKind);
        }

        // done
        return SudokuConstraints.fromArray(constraints);
    }

    private static loadSudokuConstraint(kind: string, json: any, path: string): Constraint {

        const str = json as string;

        const constraint = ConstraintReg.fromString(kind, str);
        if (!constraint) throw SudokuSerializerError.fromMessage(`Invalid constraint kind ${path} (${kind})`);

        return constraint;
    }

    private static loadSolvePath(json: any, path: string): SolvePath {

        if (!json) throw SudokuSerializerError.fromMissing(path);

        const revision = json.revision as number ?? 1;
        const isComplete = json.isComplete as boolean ?? false;
        const steps = this.loadArray(json.steps, `${path}.steps`, (j, p) => this.loadSolveStep(j, p));

        return SolvePath.fromData(revision, isComplete, steps);
    }

    private static loadSolveStep(json: any, path: string): SolveStep {

        if (!json) throw SudokuSerializerError.fromMissing(path);

        const deduction = this.loadSolveDeduction(json.deduction, `${path}.deduction`);
        const changes = this.loadArray(json.changes, `${path}.changes`, (j, p) => this.loadSudokuChange(j, p));

        return SolveStep.fromDeduction(deduction, changes);
    }

    private static loadSolveDeduction(json: any, path: string): SolveDeduction {

        if (!json) throw SudokuSerializerError.fromMissing(path);

        const technique = json.technique as string ?? '';
        const hints = this.loadArray(json.hints, `${path}.hints`, (j, p) => this.loadSolveHint(j, p));

        return SolveDeduction.fromTechnique(technique, hints, []);
    }

    private static loadSolveHint(json: any, path: string): SolveHint {

        if (!json) throw SudokuSerializerError.fromMissing(path);

        const text = json.text ?? '';
        const squares = this.loadPositionSet(json.squares, `${path}.squares`);
        const colors = this.loadArray(json.colors, `${path}.colors`, (j, p) => this.loadPositionSet(j, p));
        const causes = this.loadArray(json.causes, `${path}.causes`, (j, p) => this.loadPositionSet(j, p));

        return SolveHint.fromText(text, squares, PositionSets.fromArray(colors), PositionSets.fromArray(causes));
    }

    private static loadSudokuChange(json: any, path: string): SudokuChange {

        const op = json.op as SudokuChangeOp ?? 'set';
        const target = json.target as SudokuChangeTarget ?? 'digit';
        const squares = this.loadPositionSet(json.squares, `${path}.squares`);
        const digits = this.loadDigitSet(json.digits, `${path}.digits`);

        return SudokuChange.fromData(op, target, squares, digits);
    }

    private static loadPosition(json: any, path: string): Position {

        const str = json as string;
        if (!str) throw SudokuSerializerError.fromMissing(path);

        return Position.fromString(str);
    }

    private static loadPositionSet(json: any, path: string): PositionSet {

        const str = json as string ?? '';

        return PositionSet.fromString(str);
    }

    private static loadDigitSet(json: any, path: string): DigitSet {

        const str = json as string ?? '';

        return DigitSet.fromString(str);
    }

    private static loadArray<T>(json: any, path: string, loadFunc: (j: any, p: string) => T): T[] {

        const array = json as any[];
        if (!array) return [];

        return array.map((s, i) => loadFunc(s, `${path}[${i}]`));
    }

    //#endregion load
}