import Position from 'sudokuku-common/src/core/Position';
import PositionLine from 'sudokuku-common/src/core/PositionLine';
import PositionSet from 'sudokuku-common/src/core/PositionSet';
import PositionSets from 'sudokuku-common/src/core/PositionSets';
import SudokuDef from 'sudokuku-common/src/core/SudokuDef';
import SudokuState from 'sudokuku-common/src/core/SudokuState';
import Property, { PropertyFlags } from 'sudokuku-common/src/properties/Property';
import PropertyObject from 'sudokuku-common/src/properties/PropertyObject';
import PropertySelection from 'sudokuku-common/src/properties/PropertySelection';

export default class SelectionState {

    private readonly _def: SudokuDef;
    private readonly _primary: Position | undefined;
    private readonly _squares: PositionSet;
    private readonly _ordered: Position[];
    private readonly _colors: PositionSets;
    private readonly _pointers: PositionSets;
    private readonly _propertyObject: PropertyObject;
    private readonly _prev: SelectionState | undefined;

    private constructor(def: SudokuDef, primary?: Position, squares?: PositionSet, ordered?: Position[], colors?: PositionSets, pointers?: PositionSets, prev?: SelectionState) {
        this._def = def;
        this._primary = primary;
        this._squares = squares || PositionSet.fromEmpty();
        this._ordered = ordered || [];
        this._colors = colors || PositionSets.fromEmpty();
        this._pointers = pointers || PositionSets.fromEmpty();
        this._propertyObject = new PropertyObject(prev?.propertyObject(), this, this.properties());
        this._prev = prev;
    }

    static fromDef(def: SudokuDef): SelectionState {
        return new SelectionState(def);
    }

    static fromSudoku(sudoku: SudokuState): SelectionState {
        return new SelectionState(sudoku.def());
    }

    primary(): Position | undefined {
        return this._primary;
    }

    squares(): PositionSet {
        return this._squares;
    }

    ordered(): Position[] {
        return this._ordered.filter(p => this._squares.has(p));
    }

    colors(): PositionSets {
        return this._colors;
    }

    pointers(): PositionSets {
        return this._pointers;
    }

    lines(): PositionLine[] {
        return PositionLine.fromArrayMany(this.ordered());
    }

    propertyObject(): PropertyObject {
        return this._propertyObject;
    }

    propertySelection(state: SudokuState): PropertySelection {
        const constraints = state.def().constraints().inAll(this._squares)
            .filter(c => !c.def().isStandard() && !c.def().isHidden())
            .map(c => c.propertyObject());

        return PropertySelection.fromEmpty().setSelection([this.propertyObject(), ...constraints]);
    }

    prev(): SelectionState | undefined {
        return this._prev;
    }

    isSelected(square: Position): boolean {
        return this._squares.has(square);
    }

    colorIndex(square: Position): number {
        return this._colors.toArray().findIndex(c => c.has(square)) + 1;
    }

    pointerIndex(square: Position): number {
        return this._pointers.toArray().findIndex(c => c.has(square)) + 1;
    }

    clearSelection(): SelectionState {
        return new SelectionState(this._def);
    }

    setSelection(square: Position | undefined, prev?: SelectionState): SelectionState {
        return this.setMultiSelection(square, square ? [square] : [], prev);
    }

    setMultiSelection(primary: Position | undefined, positions: Position[], prev?: SelectionState): SelectionState {
        return this.setColorSelection(primary, positions, PositionSets.fromEmpty(), PositionSets.fromEmpty(), prev);
    }

    setColorSelection(primary: Position | undefined, positions: Position[], colors: PositionSets, pointers: PositionSets, prev?: SelectionState): SelectionState {

        // clear selection
        if (!primary) return this.clearSelection();

        // primary must be valid
        if (!this._def.isSquare(primary)) return this;

        // make set
        let squares = PositionSet.fromArray(positions);

        // keep only valid squares
        squares = squares.intersectWith(this._def.squares());

        // nothing changed?
        if (primary.isEqual(this._primary) && squares.isEqual(this._squares) && colors.isEqual(this._colors)) return this;

        // set new selection
        return new SelectionState(this._def, primary, squares, positions, colors, pointers, prev);
    }

    addSelection(square: Position | undefined, prev?: SelectionState): SelectionState {
        return this.addMultiSelection(square, square ? [square] : [], prev);
    }

    addMultiSelection(primary: Position | undefined, positions: Position[], prev?: SelectionState): SelectionState {

        // primary must be valid
        if (!primary) return this;
        if (!this._def.isSquare(primary)) return this;

        // make set
        let squares = PositionSet.fromArray(positions);

        // keep only valid squares
        squares = squares.intersectWith(this._def.squares());

        // add to existing squares
        squares = squares.unionWith(this._squares);

        // nothing changed?
        if (primary.isEqual(this._primary) && squares.isEqual(this._squares)) return this;

        // preserve selection order
        const ordered = this._ordered.concat(positions);

        // set new selection
        return new SelectionState(this._def, primary, squares, ordered, undefined, undefined, prev);
    }

    toggleSelection(square: Position, prev?: SelectionState): SelectionState {
        if (!this._def.isSquare(square)) return this;

        const squares = this._squares.disjunctiveUnionWith(PositionSet.fromPos(square));
        const ordered = this._ordered.concat([square]);

        return new SelectionState(this._def, square, squares, ordered, undefined, undefined, prev);
    }

    firstSelectableSquare(): Position | undefined {
        return this._def.squares().middle();
    }

    nextSelectableSquare(square: Position | undefined, move: () => Position): Position | undefined {

        if (!square) return this.firstSelectableSquare();

        const numRows = this._def.numRows();
        const numCols = this._def.numCols();

        do {
            square = move.call(square);

            if (square.row() < 1 || square.row() > numRows) return undefined;
            if (square.col() < 1 || square.col() > numCols) return undefined;
        } while (!this._def.isSquare(square));

        return square;
    }

    private properties(): readonly Property[] {

        const count = this._squares.size();
        const category = count > 1 ? `Selection (${count})` : 'Selection';

        return [
            new Property('Cells', category, 'text', PropertyFlags.IsReadOnly, this, () => this._squares.toString())
        ]
    }
}