import React from "react";
import PropertySelection from "sudokuku-common/src/properties/PropertySelection";
import UIPropertyNode from "./UIPropertyNode";
import './UIPropertyTree.css';

type UIPropertyTreeProps = {
    selection: PropertySelection;
    onSelection: (selection: PropertySelection) => void;
}

type UIPropertyTreeState = {
}

export default class UIPropertyTree extends React.PureComponent<UIPropertyTreeProps, UIPropertyTreeState> {

    public override render(): React.ReactNode {
        return (
            <div className="UIPropertyTree">
                <UIPropertyNode label="" isExpanded={true} selection={this.props.selection} onSelection={this.props.onSelection} >
                    {this.props.children}
                </UIPropertyNode>
            </div>
        );
    }
}