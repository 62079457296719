import DigitSet from './DigitSet';
import Position from './Position';
import PositionSet from './PositionSet';
import SudokuState from './SudokuState';

export type SudokuChangeOp = 'set' | 'add' | 'rem' | 'toggle';

export type SudokuChangeTarget = 'digit' | 'center' | 'corner' | 'color';

export type SudokuChangeSquares = PositionSet | Position | Position[] | string;

export type SudokuChangeDigits = DigitSet | string | number;

export type SudokuChangeFunc = (square: Position, oldDigits: DigitSet, newDigits: DigitSet, change: SudokuChange, sudoku: SudokuState) => DigitSet;

export default class SudokuChange {

    private readonly _op: SudokuChangeOp;
    private readonly _target: SudokuChangeTarget;
    private readonly _squares: PositionSet;
    private readonly _digits: DigitSet;

    private constructor(op: SudokuChangeOp, target: SudokuChangeTarget, squares: SudokuChangeSquares, digits: SudokuChangeDigits) {
        this._op = op;
        this._target = target;
        this._squares = PositionSet.fromAny(squares);
        this._digits = DigitSet.fromAny(digits);
    }

    public op(): SudokuChangeOp {
        return this._op;
    }

    public target(): SudokuChangeTarget {
        return this._target;
    }

    public squares(): PositionSet {
        return this._squares;
    }

    public digits(): DigitSet {
        return this._digits;
    }

    public key(): string {
        return [this._op, this._target, this._digits.toString()].join(',');
    }
    
    public toString(): string {
        return `${this._op} ${this._target} ${this._squares} ${this._digits}`;
    }

    public static fromData(op: SudokuChangeOp, target: SudokuChangeTarget, squares: PositionSet, digits: DigitSet): SudokuChange {
        return new SudokuChange(op, target, squares, digits);
    }

    public static fromKey(key: string, squares: Position[]): SudokuChange {
        const [op, target, digits] = key.split(',');
        return new SudokuChange(op as SudokuChangeOp, target as SudokuChangeTarget, PositionSet.fromArray(squares), DigitSet.fromString(digits));
    }

    public static set(target: SudokuChangeTarget, squares: SudokuChangeSquares, digits: SudokuChangeDigits): SudokuChange {
        return new SudokuChange('set', target, squares, digits);
    }

    // #region Digit

    public static setDigit(squares: SudokuChangeSquares, digits: SudokuChangeDigits): SudokuChange {
        return new SudokuChange('set', 'digit', squares, digits);
    }

    // NOT SUPPORTED
    // public static addDigit(squares: SudokuChangeSquares, digits: SudokuChangeDigits): SudokuChange {
    //     return new SudokuChange('add', 'digit', squares, digits);
    // }

    // NOT SUPPORTED
    // public static removeDigit(squares: SudokuChangeSquares, digits: SudokuChangeDigits): SudokuChange {
    //     return new SudokuChange('rem', 'digit', squares, digits);
    // }

    public static toggleDigit(squares: SudokuChangeSquares, digits: SudokuChangeDigits): SudokuChange {
        return new SudokuChange('toggle', 'digit', squares, digits);
    }

    // #endregion Digit

    // #region Center Mark

    public static setCenterMark(squares: SudokuChangeSquares, digits: SudokuChangeDigits): SudokuChange {
        return new SudokuChange('set', 'center', squares, digits);
    }

    public static addCenterMark(squares: SudokuChangeSquares, digits: SudokuChangeDigits): SudokuChange {
        return new SudokuChange('add', 'center', squares, digits);
    }

    public static removeCenterMark(squares: SudokuChangeSquares, digits: SudokuChangeDigits): SudokuChange {
        return new SudokuChange('rem', 'center', squares, digits);
    }

    public static toggleCenterMark(squares: SudokuChangeSquares, digits: SudokuChangeDigits): SudokuChange {
        return new SudokuChange('toggle', 'center', squares, digits);
    }

    // #endregion Center Mark

    // #region Corner Mark

    public static setCornerMark(squares: SudokuChangeSquares, digits: SudokuChangeDigits): SudokuChange {
        return new SudokuChange('set', 'corner', squares, digits);
    }

    public static addCornerMark(squares: SudokuChangeSquares, digits: SudokuChangeDigits): SudokuChange {
        return new SudokuChange('add', 'corner', squares, digits);
    }

    public static removeCornerMark(squares: SudokuChangeSquares, digits: SudokuChangeDigits): SudokuChange {
        return new SudokuChange('rem', 'corner', squares, digits);
    }

    public static toggleCornerMark(squares: SudokuChangeSquares, digits: SudokuChangeDigits): SudokuChange {
        return new SudokuChange('toggle', 'corner', squares, digits);
    }

    // #endregion Corner Mark

    // #region Corner Mark

    public static setColorMark(squares: SudokuChangeSquares, digits: SudokuChangeDigits): SudokuChange {
        return new SudokuChange('set', 'color', squares, digits);
    }

    public static addColorMark(squares: SudokuChangeSquares, digits: SudokuChangeDigits): SudokuChange {
        return new SudokuChange('add', 'color', squares, digits);
    }

    public static removeColorMark(squares: SudokuChangeSquares, digits: SudokuChangeDigits): SudokuChange {
        return new SudokuChange('rem', 'color', squares, digits);
    }

    public static toggleColorMark(squares: SudokuChangeSquares, digits: SudokuChangeDigits): SudokuChange {
        return new SudokuChange('toggle', 'color', squares, digits);
    }

    // #endregion Corner Mark
}