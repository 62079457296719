import { withAuth0, WithAuth0Props } from '@auth0/auth0-react';
import React from 'react';
import { RouteComponentProps } from "react-router-dom";
import It from 'sudokuku-common/src/core/It';
import Auth from '../auth/Auth';
import UIMenu, { UIMenuProps } from '../ui-menu/UIMenu';
import UIMenuDivider from '../ui-menu/UIMenuDivider';
import UIMenuHamburger from '../ui-menu/UIMenuHamburger';
import UIMenuLink from '../ui-menu/UIMenuLink';
import UIRoot from '../ui-shell/UIRoot';
import UISearchForm from './components/UISearchForm';
import UISearchResult from './components/UISearchResult';
import SearchQuery from './model/SearchQuery';
import SearchResults from './model/SearchResults';
import './UISearch.css';

export type UISearchProps = UIMenuProps & WithAuth0Props & RouteComponentProps & {
}

export type UISearchState = {
    query: SearchQuery,
    results: SearchResults
}

export class UISearch extends React.PureComponent<UISearchProps, UISearchState> {

    private _auth: Auth;
    private readonly _inputRef: React.RefObject<HTMLInputElement>;

    public constructor(props: UISearchProps) {
        super(props);

        this._auth = new Auth(this.props.auth0);
        this._inputRef = React.createRef<HTMLInputElement>();

        this.state = {
            query: SearchQuery.fromLocation(props.location),
            results: SearchResults.fromEmpty(),
        };

        this.handleKeyPress = this.handleKeyPress.bind(this);
    }

    static getDerivedStateFromProps(nextProps: UISearchProps, prevState: UISearchState): Partial<UISearchState> | null {

        const nextQuery = SearchQuery.fromLocation(nextProps.location);
        if (nextQuery.isEqual(prevState.query)) return null;

        return { query: nextQuery, results: SearchResults.fromEmpty() };
    }

    override componentDidMount(): void {
        document.addEventListener("keypress", this.handleKeyPress);
        this.loadResults();
    }

    override componentWillUnmount(): void {
        document.removeEventListener("keypress", this.handleKeyPress);
    }

    override componentDidUpdate(prevProps: UISearchProps, prevState: UISearchState): void {

        // update auth
        if (this.props.auth0 !== prevProps.auth0) {
            this._auth = new Auth(this.props.auth0);
        }

        // update results
        if (this.state.query !== prevState.query || this.props.auth0 !== prevProps.auth0) {
            this.loadResults();
        }
    }

    override render(): React.ReactNode {

        const query = this.state.query;
        const results = this.state.results.metadata();

        const thisPage = query.pageNum();
        const nextPage = thisPage + 1;
        const prevPages = It.range(1, thisPage - 1).toArray();

        return (
            <div className="UISearch">

                <UISearchForm inputRef={this._inputRef} query={query} onSearch={q => this.search(q)} />

                <div className="results">
                    {results.map(r => <UISearchResult key={r.id()} result={r} />)}
                </div>

                <div className="navigation">
                    Page
                    {prevPages.map(p => <button key={p} onClick={e => this.handlePageClick(e, p)}>{p}</button>)}
                    <button disabled={true}>{thisPage}</button>
                    {!query.isLastPage(results.length) ? <button onClick={e => this.handlePageClick(e, nextPage)}>{nextPage}</button> : null}
                </div>

                <UIMenu {...this.props}>
                    <UIMenuHamburger {...this.props} />
                    <UIMenuLink to='/' {...this.props}>Home</UIMenuLink>
                    <UIMenuDivider />
                    <UIMenuLink to='/import' {...this.props}>Import Puzzle</UIMenuLink>
                    <UIMenuDivider />
                    <UIMenuLink to='/terms' target="_blank" {...this.props}>Terms</UIMenuLink>
                    <UIMenuLink to='/privacy' target="_blank" {...this.props}>Privacy</UIMenuLink>
                </UIMenu>
            </div>
        );
    }

    private loadResults(): Promise<void> {

        const query = this.state.query;

        document.title = query.title(UIRoot.Title);

        return SearchResults.fromQueryAsync(this._auth, query).then(results =>
            this.setState((s, p) => (this.state.query === query) ? ({
                results: results
            }) : null));
    }

    private handleKeyPress(event: KeyboardEvent) {

        // dont steal keyboard focus
        if (document.activeElement === this._inputRef.current) return;

        switch (event.key) {
            case '/':
                event.preventDefault();
                this._inputRef.current?.focus();
                break;

            case '1':
            case '2':
            case '3':
            case '4':
            case '5':
            case '6':
            case '7':
            case '8':
            case '9': {
                const pageNum = parseInt(event.key);
                if (pageNum === this.currentPageNum()) break;
                if (pageNum < this.firstPageNum()) break;
                if (pageNum > this.lastPageNum()) break;

                this.search(this.state.query.page(pageNum));
                break;
            }
        }
    }

    private handlePageClick(event: React.MouseEvent<HTMLButtonElement>, pageNum: number) {
        this.search(this.state.query.page(pageNum));
    }

    private search(query: SearchQuery) {
        this.props.history.push(query.toLocation('/search'));
    }

    private currentPageNum(): number {
        return this.state.query.pageNum();
    }

    private firstPageNum(): number {
        return 1;
    }

    private lastPageNum(): number {
        const results = this.state.results.metadata();
        const isLastPage = this.state.query.isLastPage(results.length);
        const pageNum = this.currentPageNum();

        return isLastPage ? pageNum : pageNum + 1;
    }
}

export default withAuth0(UISearch);