import React from 'react';
import { UIMenuProps } from './UIMenu';
import './UIMenuHamburger.css';

export type UIMenuHamburgerProps = UIMenuProps & {
}

export type UIMenuHamburgerState = {
}

export default class UIMenuHamburger extends React.PureComponent<UIMenuHamburgerProps,UIMenuHamburgerState> {

    constructor(props: UIMenuHamburgerProps) {
        super(props);

        this.handleOnClick = this.handleOnClick.bind(this);
        this.handleKeyPress = this.handleKeyPress.bind(this);
    }

    override render(): React.ReactNode {

        return (
            <div className="UIMenuHamburger" onClick={this.handleOnClick} onKeyPress={this.handleKeyPress} tabIndex={1}>
                <div className="icon">
                    <span className="line line-1"></span>
                    <span className="line line-2"></span>
                    <span className="line line-3"></span>
                </div>
            </div>
        );
    }

    private handleOnClick(event: React.MouseEvent<HTMLElement>) {
        this.toggleMenu(event);
        event.preventDefault();
    }

    private handleKeyPress(event: React.KeyboardEvent<HTMLElement>) {
        if (event.code === 'Enter') {
            this.toggleMenu(event);
            event.preventDefault();
        }
    }

    private toggleMenu(event: React.SyntheticEvent<HTMLElement>) {
        if (this.props.isMenuExpanded) {
            this.props.onMenuCollapse(event);
        }
        else {
            this.props.onMenuExpand(event);
        }   
    }
}