import Position from './Position';
import PositionSet from './PositionSet';

export default class ViolationState {

    private readonly _invalid: PositionSet;

    private constructor(invalid: PositionSet) {
        this._invalid = invalid;
    }

    static fromEmpty(): ViolationState {
        return new ViolationState(PositionSet.fromEmpty());
    }

    hasViolations(): boolean {
        return this._invalid.size() > 0;
    }

    isViolation(square: Position): boolean {
        return this._invalid.has(square);
    }

    addViolation(squares: PositionSet): ViolationState {
        return new ViolationState(this._invalid.unionWith(squares));
    }
}