import React from 'react';
import { Redirect, Route, Switch } from "react-router-dom";
import UIImport from '../ui-import/UIImport';
import { UIMenuProps } from '../ui-menu/UIMenu';
import UISearch from '../ui-search/UISearch';
import UIEdit from '../ui-sudoku/UIEdit';
import UIPlay from '../ui-sudoku/UIPlay';
import UIPrint from '../ui-sudoku/UIPrint';
import './UIBody.css';

export type UIBodyProps = UIMenuProps & {
}

export type UIBodyState = {
}

export default class UIBody extends React.PureComponent<UIBodyProps, UIBodyState> {
    override render(): React.ReactNode {
        return (
            <div className="UIBody">
                <Switch>
                    <Route exact path="/" render={(p) => <UISearch {...p} {...this.props} />} />
                    <Route path="/search" render={(p) => <UISearch {...p} {...this.props} />} />
                    <Route path="/import" render={(p) => <UIImport {...p} {...this.props} />} />
                    <Route path="/edit/:id" render={(p) => <UIEdit {...p} {...this.props} />} />
                    <Route path="/play/:id" render={(p) => <UIPlay {...p} {...this.props} />} />
                    <Route path="/print/:id" render={(p) => <UIPrint {...p} {...this.props} />} />
                    <Route path="*">
                        <Redirect to="/" />
                    </Route>
                </Switch>
            </div>
        );
    }
}