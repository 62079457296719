import React from 'react';
import { Link } from 'react-router-dom';
import DbSearchResult from 'sudokuku-common/src/db/DbSearchResult';
import SudokuStorage from '../../storage/SudokuStorage';
import './UISearchResult.css';

export type UISearchResultProps = {
    result: DbSearchResult,
}

export type UISearchResultState = {
}

export default class UISearchResult extends React.PureComponent<UISearchResultProps, UISearchResultState> {

    private static readonly OneSecond = 1000;
    private static readonly OneMinute = UISearchResult.OneSecond * 60;
    private static readonly OneHour = UISearchResult.OneMinute * 60;
    private static readonly OneDay = UISearchResult.OneHour * 24;
    private static readonly OneWeek = UISearchResult.OneDay * 7;
    private static readonly OneMonth = UISearchResult.OneDay * 30;
    private static readonly OneYear = UISearchResult.OneDay * 365;

    override render(): React.ReactNode {
        const result = this.props.result;

        const urlId = encodeURIComponent(result.id());
        const title = result.title() || 'Untitled Puzzle';
        const author = result.author() ? `by ${result.author()}` : '';
        const isPrivate = !result.isPublic();

        const updated = UISearchResult.updateText(result.updateTime(), Date.now());

        const playPath = `/play/${urlId}`;
        const imgPath = `/download/${urlId}.png`;

        const offlineSudoku = SudokuStorage.loadSudoku(playPath);
        const progressPercent = offlineSudoku ? offlineSudoku.solvedPercent() : 0;
        const progressStyle = { width: progressPercent + '%' };

        return (
            <div className="UISearchResult">
                <Link to={playPath}>
                    <div>
                        <div className="thumbnail">
                            <img src={imgPath} alt={title} title={title} />
                            <div className="progress" style={progressStyle}></div>
                        </div>

                        <div className="info">
                            {offlineSudoku ?
                                <div className="offline">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="grey" width="100%" height="100%">
                                        <title>Available Offline</title>
                                        <path d="M5 18h14v2H5v-2zm4.6-2.7L5 10.7l2-1.9 2.6 2.6L17 4l2 2-9.4 9.3z" />
                                    </svg>
                                </div> : null}

                            {isPrivate ?
                                <div className="private">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="grey" width="100%" height="100%">
                                        <title>Private</title>
                                        <path d="M12 1L3 5v6c0 5.55 3.84 10.74 9 12 5.16-1.26 9-6.45 9-12V5l-9-4z"/>
                                    </svg>
                                </div> : null}

                            <div className="title">{title}</div>

                            <div className="updated">{updated}</div>

                            <div className="author">{author}</div>
                        </div>
                    </div>
                </Link>
            </div>
        );
    }

    private static updateText(updateTime: number, now: number): string {

        const diff = now - updateTime;

        const days = Math.floor(diff / this.OneDay);
        if (days <= 1) return 'Today';
        if (days <= 2) return 'Yesterday';
        if (days < 14) return `${days} days ago`;

        const weeks = Math.floor(diff / this.OneWeek);
        if (weeks < 7) return `${weeks} weeks ago`;

        const months = Math.floor(diff / this.OneMonth);
        if (months < 12) return `${months} months ago`;

        const years = Math.floor(diff / this.OneYear);
        if (years < 2) return `${years} year ago`;

        return `${years} years ago`;
    }
}