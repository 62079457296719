import classNames from 'classnames';
import React from 'react';
import Property, { PropertyContext, PropertyValue } from 'sudokuku-common/src/properties/Property';
import './UIPropertyItem.css';

export type UIPropertyItemProps = {
    ctx: PropertyContext;
    property: Property;
    isFocused: boolean;
    onCommit: (ctx: PropertyContext) => void;
}

export type UIPropertyItemState = {
    value: PropertyValue;
}

export default class UIPropertyItem extends React.Component<UIPropertyItemProps, UIPropertyItemState> {

    private readonly FocusTimeout = 20; // a bit slower than one screen refresh (which is 16.6ms)

    private _ref: React.RefObject<HTMLElement>;

    constructor(props: UIPropertyItemProps) {
        super(props);

        this._ref = React.createRef();

        this.state = {
            value: props.property.value(this.props.ctx),
        }
    }

    override componentDidMount(): void {
        if (this.props.isFocused) this.focus();
    }

    override componentDidUpdate(prevProps: UIPropertyItemProps, prevState: UIPropertyItemState): void {
        if (this.props !== prevProps) {
            this.setState({ value: this.props.property.value(this.props.ctx) });
        }
    }

    public focus(): void {
        setTimeout(a => { this._ref.current?.focus({ preventScroll: true }) }, this.FocusTimeout);
    }

    public blur(): void {
        setTimeout(a => { this._ref.current?.blur() }, this.FocusTimeout);
    }

    override render(): React.ReactNode {

        const ctx = this.props.ctx;
        const key = this.props.property.key();
        const name = this.props.property.label();
        const type = this.props.property.type();
        const value = this.state.value;
        const placeholder = this.props.property.placeholder(ctx);
        const isValid = this.props.property.validateValue(ctx, value);
        const isReadOnly = this.props.property.isReadOnly();

        const className = classNames({
            "readonly": isReadOnly,
            "invalid": !isValid,
        });

        return (
            <div className="UIPropertyItem">
                <label htmlFor={key}>{name}</label>

                {type === 'textarea' ? <textarea className={className} id={key} ref={this._ref as React.RefObject<HTMLTextAreaElement>}
                    value={value} placeholder={placeholder} readOnly={isReadOnly} rows={4}
                    onChange={(e) => this.handleValueChange(e.currentTarget.value)}
                    onBlur={() => this.handleValueCommit(false)}></textarea> : null}

                {type === 'checkbox' ? <input className={className} id={key} ref={this._ref as React.RefObject<HTMLInputElement>}
                    type={type} checked={Property.parseBoolean(value)} placeholder={placeholder} readOnly={isReadOnly}
                    onClick={() => this.handleValueClick()}
                    onChange={(e) => this.handleValueChange(e.currentTarget.checked.toString())}
                    onBlur={() => this.handleValueCommit(false)}
                    onKeyDown={(e) => this.handleKeyDown(e)} /> : null}

                {type !== 'textarea' && type !== 'checkbox' ? <input className={className} id={key} ref={this._ref as React.RefObject<HTMLInputElement>}
                    type={type} value={value} placeholder={placeholder} readOnly={isReadOnly}
                    onClick={() => this.handleValueClick()}
                    onChange={(e) => this.handleValueChange(e.currentTarget.value)}
                    onBlur={() => this.handleValueCommit(false)}
                    onKeyDown={(e) => this.handleKeyDown(e)} /> : null}
            </div>
        );
    }

    private commitOnClick(): boolean {
        return this.props.property.type() === 'button';
    }

    private handleValueClick(): void {
        if (this.commitOnClick()) {
            this.handleValueCommit(true);
        }
    }

    private handleValueChange(newValue: string): void {
        this.setState({ value: newValue });
    }

    private handleValueCommit(isOnClick: boolean): void {
        const value = this.state.value;
        const ctx = this.props.ctx;
        const property = this.props.property;

        // buttons commit on click
        if (!isOnClick && this.commitOnClick()) return;

        // has value changed?
        if (!this.commitOnClick() && property.value(ctx) === value) return;

        // step 1: validate new value
        if (!property.validateValue(ctx, value)) return;

        // step 2: start transaction
        const txn = ctx.startTransaction ? ctx.startTransaction(ctx) : null;

        // step 3: set new value
        property.setValue(ctx, value);

        // step 4: end transaction
        if (ctx.endTransaction) ctx.endTransaction(ctx, txn);

        // sreo 5: commit
        this.props.onCommit(ctx);
    }

    private handleKeyDown(event: React.KeyboardEvent<HTMLInputElement>): void {
        switch (event.code) {

            case 'Escape':
                event.preventDefault();
                this.setState({ value: this.props.property.value(this.props.ctx) });
                this.blur();
                break;

            case 'Enter':
                event.preventDefault();
                this.handleValueCommit(true);
                setTimeout(a => { this._ref.current?.blur() }, this.FocusTimeout);
                break;
        }
    }
}
