
export type PropertyType = 'text' | 'textarea' | 'number' | 'checkbox' | 'button';

export type PropertyValue = string;

export enum PropertyFlags {
    None = 0,
    IsReadOnly = 1 << 1,
    IsFocused = 1 << 2,
}

export type PropertyStartTransaction = (ctx: PropertyContext) => unknown;

export type PropertyEndTransaction = (ctx: PropertyContext, txn: unknown) => void;

export type PropertyContext = { state?: unknown, startTransaction?: PropertyStartTransaction, endTransaction?: PropertyEndTransaction };

export type PropertyGetValue = (ctx: PropertyContext, prop: Property) => PropertyValue;

export type PropertySetValue = (ctx: PropertyContext, prop: Property, value: PropertyValue) => void;

export type PropertyValidateValue = (ctx: PropertyContext, prop: Property, value: PropertyValue) => boolean;

export default class Property {

    private readonly _label: string;
    private readonly _category: string;
    private readonly _type: PropertyType;
    private readonly _flags: PropertyFlags;
    private readonly _data: unknown;
    private readonly _getValue: PropertyGetValue;
    private readonly _setValue?: PropertySetValue;
    private readonly _validateValue?: PropertyValidateValue;
    private readonly _placeholderValue?: PropertyGetValue;

    public constructor(label: string, category: string, type: PropertyType, flags: PropertyFlags, data: unknown,
        getValue: PropertyGetValue, setValue?: PropertySetValue, validateValue?: PropertyValidateValue, placeholderValue?: PropertyGetValue) {
        this._label = label;
        this._category = category;
        this._type = type;
        this._flags = flags;
        this._data = data;
        this._getValue = getValue;
        this._setValue = setValue;
        this._validateValue = validateValue;
        this._placeholderValue = placeholderValue;
    }

    public label(): string {
        return this._label;
    }

    public category(): string {
        return this._category;
    }

    public type(): PropertyType {
        return this._type;
    }

    public key(): string {
        return `${this._label}:${this._category}:${this._type}`;
    }

    public isReadOnly(): boolean {
        return (this._flags & PropertyFlags.IsReadOnly) !== 0;
    }

    public isFocused(): boolean {
        return (this._flags & PropertyFlags.IsFocused) !== 0;
    }

    public data(): unknown {
        return this._data;
    }

    public value(ctx: PropertyContext): PropertyValue {
        return this._getValue(ctx, this);
    }

    public placeholder(ctx: PropertyContext): PropertyValue {
        return (this._placeholderValue && this._placeholderValue(ctx, this)) || '';
    }

    public setValue(ctx: PropertyContext, value: PropertyValue): void {
        if (!this._setValue) return;
        if (this.isReadOnly()) return;

        this._setValue(ctx, this, value);
    }

    public validateValue(ctx: PropertyContext, value: PropertyValue): boolean {
        if (!this._validateValue) return true;

        return this._validateValue(ctx, this, value);
    }

    public static parseBoolean(value: string): boolean {
        if (!value) return false;
        if (value === 'false') return false;
        return true;
    }
}