import classNames from "classnames";
import React from "react";
import PropertyObject from "sudokuku-common/src/properties/PropertyObject";
import PropertySelection from "sudokuku-common/src/properties/PropertySelection";
import './UIPropertyNode.css';

export type UIPropertyNodeProps = {
    label: string;
    object?: PropertyObject;
    isExpanded?: boolean; //
    parent?: UIPropertyNode; // automatically assigned
    index?: number,
    selection?: PropertySelection; // automatically assigned
    onSelection?: (selection: PropertySelection) => void; // automatically assigned
}

export type UIPropertyNodeState = {
    isExpanded: boolean;
}

export default class UIPropertyNode extends React.PureComponent<UIPropertyNodeProps, UIPropertyNodeState> {

    private readonly _ref = React.createRef<HTMLDivElement>();
    private readonly _children: (UIPropertyNode | undefined)[] = [];
    private readonly _object = new PropertyObject(undefined, this);

    public constructor(props: UIPropertyNodeProps) {
        super(props);

        this.state = {
            isExpanded: props.isExpanded ?? false,
        }
    }

    public isLabelled(): boolean {
        return this.props.label.length > 0;
    }

    public isParent(): boolean {
        return React.Children.count(this.props.children) > 0;
    }

    public isRoot(): boolean {
        return this.props.parent === undefined;
    }

    public isSelected(): boolean {
        if (!this.props.selection) return false;
        return this.props.selection.isSelected(this.object());
    }

    public isExpanded(): boolean {
        return this.state.isExpanded;
    }

    public children(): (UIPropertyNode | undefined)[] {
        return this._children;
    }

    public ref(): HTMLElement | null {
        return this._ref.current;
    }

    public object(): PropertyObject {
        return this.props.object ?? this._object;
    }

    public focus(): void {
        this.ref()?.focus();
    }

    public select(isMulti: boolean): void {
        if (!this.isLabelled()) return;
        if (!this.props.onSelection) return;
        if (!this.props.selection) return;

        let selection = this.props.selection;

        if (isMulti) {
            if (this.isSelected()) {
                selection = selection.remSelection(this.object());
            }
            else {
                selection = selection.addSelection(this.object());
            }
        }
        else {
            selection = selection.setSelection(this.object());
        }

        this.props.onSelection(selection);
    }

    public expand(): void {
        if (this.isLabelled() && this.isParent()) {
            this.setState({ isExpanded: true });
        }
    }

    public collapse(): void {
        if (this.isLabelled() && this.isParent()) {
            this.setState({ isExpanded: false });
        }
    }

    public override componentDidMount(): void {
        const parent = this.props.parent;
        if (parent) parent.mountChild(this, this.props.index ?? 0);
    }

    override componentWillUnmount(): void {
        const parent = this.props.parent;
        if (parent) parent.unmountChild(this, this.props.index ?? 0);
    }

    private mountChild(child: this, index: number): void {
        this._children[index] = child;
    }

    private unmountChild(child: this, index: number): void {
        this._children[index] = undefined;
    }

    public override render(): React.ReactNode {

        const isParent = this.isParent();
        const isRoot = this.isRoot();
        const isLabelled = this.isLabelled();
        const isSelected = this.isSelected();
        const isExpanded = this.isExpanded();

        const className = classNames({
            "UIPropertyNode": true,
            "root": isRoot,
            "parent": isParent,
            "indented": isLabelled,
            "expanded": isExpanded,
            "collapsed": !isExpanded,
            "selected": isSelected,
        });

        return (
            <div className={className}>

                {isLabelled ? <div className="node" ref={this._ref} tabIndex={0} onClick={(e) => this.handleClick(e)} onDoubleClick={(e) => this.handleDoubleClick(e)} onKeyDown={(e) => this.handleKeyDown(e)}>
                    {isParent ? <svg className="toggle" viewBox="0 8 48 24" xmlns="http://www.w3.org/2000/svg" onClick={() => this.handleToggle()}><path d="M33.17 17.17l-9.17 9.17-9.17-9.17-2.83 2.83 12 12 12-12z" /></svg> : null}
                    <label>{this.props.label}</label>
                </div> : null}

                {isParent ? <div className="children">
                    {React.Children.map(this.props.children, (c, i) => React.isValidElement(c) ? React.cloneElement(c, {
                        selection: this.props.selection,
                        onSelection: this.props.onSelection,
                        ...c.props,
                        parent: this,
                        index: i,
                    }) : null)}
                </div> : null}

            </div>
        );
    }

    private handleToggle() {
        this.isExpanded() ? this.collapse() : this.expand();
    }

    private handleClick(event: React.MouseEvent): void {
        this.handleSelect(event.ctrlKey || event.shiftKey);
    }

    private handleDoubleClick(event: React.MouseEvent): void {
        this.handleToggle();
        this.handleSelect(event.ctrlKey || event.shiftKey);
    }

    private handleSelect(isMulti: boolean) {
        this.select(isMulti);
    }

    private handleExpand() {
        this.expand();
    }

    private handleCollapse() {

        // collapse this
        if (this.isExpanded()) {
            this.collapse();
            return;
        }

        // collapse parent
        const parent = this.props.parent;
        if (parent) {
            parent.collapse();
            parent.select(false);
            parent.focus();
        }
    }

    private handleMoveSelection(delta: number, isMulti: boolean) {

        // ignore root (for now)
        if (!this.props.parent) return;

        const index = (this.props.index ?? 0) + delta;
        const siblings = this.props.parent.children();

        let node: UIPropertyNode | undefined = undefined;

        if (index < 0 && delta < 0) {
            // move to parent
            node = this.props.parent;
        }
        else if (this.isExpanded() && delta > 0) {
            // move to child
            const children = this.children();
            node = children[0];
        }
        else if (index >= siblings.length && delta > 0) {
            // move to parent sibling
            const parent = this.props.parent;
            const parentIndex = (parent.props.index ?? 0) + delta;
            const children = parent.props.parent?.children() ?? [];
            node = children[parentIndex];
        }
        else if (siblings[index]?.isExpanded() && delta < 0) {
            // move to parent child
            const children = siblings[index]?.children() ?? [];
            node = children[children.length - 1];
        }
        else {
            // move to sibling
            node = siblings[index];
        }

        // early out
        if (!node) return;

        // move selection
        if (isMulti && node.isSelected()) {
            this.select(isMulti);
        }
        else {
            node.select(isMulti);
        }

        // move focus
        node.focus();
    }

    private handleKeyDown(event: React.KeyboardEvent): void {

        switch (event.code) {

            case 'Enter':
            case 'Space':
                event.preventDefault();
                this.handleSelect(event.ctrlKey || event.shiftKey);
                break;

            case 'X':
            case 'Z':
                if (event.metaKey || event.ctrlKey) {
                    event.preventDefault();
                }
                break;

            case 'ArrowLeft':
                event.preventDefault();
                event.stopPropagation();
                this.handleCollapse();
                break;

            case 'ArrowRight':
                event.preventDefault();
                event.stopPropagation();
                this.handleExpand();
                break;

            case 'ArrowUp':
                event.preventDefault();
                event.stopPropagation();
                this.handleMoveSelection(-1, event.ctrlKey || event.shiftKey);
                break;

            case 'ArrowDown':
                event.preventDefault();
                event.stopPropagation();
                this.handleMoveSelection(1, event.ctrlKey || event.shiftKey);
                break;
        }
    }
}