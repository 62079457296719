import React from 'react';
import It from 'sudokuku-common/src/core/It';
import MultiMap from 'sudokuku-common/src/core/MultiMap';
import Property, { PropertyContext } from 'sudokuku-common/src/properties/Property';
import PropertyObject from 'sudokuku-common/src/properties/PropertyObject';
import PropertySelection from 'sudokuku-common/src/properties/PropertySelection';
import './UIPropertyBox.css';
import UIPropertyItem from './UIPropertyItem';

export type UIPropertyBoxProps = {
    ctx: PropertyContext;
    roots: PropertyObject | Iterable<PropertyObject>;
    selection: PropertySelection;
    onCommit: (ctx: PropertyContext) => void;
}

export type UIPropertyBoxState = {
}

export default class UIPropertyBox extends React.PureComponent<UIPropertyBoxProps, UIPropertyBoxState> {

    override render(): React.ReactNode {

        const properties = this.props.selection.properties(this.props.roots);
        const byCategory = It(MultiMap.groupBy(properties, p => p.category()).entries()).toArray();

        return (
            <div className="UIPropertyBox">
                {byCategory.map(([k, v]) => this.renderCategory(k, v))}
            </div>
        );
    }

    private renderCategory(category: string, properties: Property[]): React.ReactNode {
        return (
            <React.Fragment key={category}>
                <div className="category">{category}</div>
                {properties.map(p => <UIPropertyItem key={p.key()} ctx={this.props.ctx} property={p} isFocused={this.props.selection.isFocused(p)} onCommit={(ctx) => this.props.onCommit(ctx)} />)}
            </React.Fragment>
        );
    }
}
