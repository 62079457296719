import React from 'react';
import { Link } from 'react-router-dom';
import RichText from '../../../components/RichText';
import StatusState, { StatusFlags } from '../../model/StatusState';
import './UIError.css';

export type UIErrorProps = {
    status: StatusState
}

export type UIErrorState = {
}

export default class UIError extends React.PureComponent<UIErrorProps, UIErrorState> {

    override render(): React.ReactNode {

        const status = this.props.status;
        if (!status.hasFlags(StatusFlags.IsError)) return null;

        return (
            <div className="UIError">
                <p>Sorry, an error has occurred.</p>
                <p><RichText>{status.message()}</RichText></p>
                <p>Please return <Link to="/">Home</Link>.</p>
            </div>
        );
    }
}