import React from 'react';
import ConstraintReg from 'sudokuku-common/src/constraints/ConstraintReg';
import Position from 'sudokuku-common/src/core/Position';
import SudokuState from 'sudokuku-common/src/core/SudokuState';
import ViolationState from 'sudokuku-common/src/core/ViolationState';
import SelectionState from '../../model/SelectionState';
import ConstraintCellDefs from './../constraints/ConstraintCellDefs';
import GridCell, { GridCellFlags } from './GridCell';
import GridCenterMark from './GridCenterMark';
import GridColorMark from './GridColorMark';
import GridCornerMark from './GridCornerMark';
import GridDigit from './GridDigit';
import GridFixed from './GridFixed';
import GridRow from './GridRow';
import GridSelection from './GridSelection';
import GridViolation from './GridViolation';
import './Grid.css';

export type GridProps = {
    sudoku: SudokuState;
    selection: SelectionState,
    violations: ViolationState;
}

export type GridState = {
}

export default class Grid extends React.PureComponent<GridProps, GridState> {

    private static readonly _constraintCellDefs = new ConstraintCellDefs();

    override render(): React.ReactNode {
        const sudoku = this.props.sudoku;
        const selection = this.props.selection;
        const violations = this.props.violations;

        const sudokuDef = sudoku.def();
        const numRows = sudokuDef.numRows();
        const numCols = sudokuDef.numCols();

        const rowElements: JSX.Element[] = [];

        for (let row = 1; row <= numRows; row++) {
            const cellElements: JSX.Element[] = [];

            for (let col = 1; col <= numCols; col++) {
                const pos = Position.fromRowCol(row, col);
                const posId = pos.toString();

                let flags = GridCellFlags.None;
                if (violations.isViolation(pos)) flags |= GridCellFlags.IsViolation;
                if (selection.isSelected(pos)) flags |= GridCellFlags.IsSelected;
                if (sudokuDef.isSquare(pos)) flags |= GridCellFlags.IsSquare;
                if (sudokuDef.isFixed(pos)) flags |= GridCellFlags.IsFixed;

                const constraintElements = sudokuDef.constraints().visibleIn(pos)
                    .map(c => ConstraintReg.render(pos, sudoku, selection, c))
                    .filter(e => e != null);

                const cellElement =
                    <GridCell key={posId} id={posId} flags={flags} sudoku={sudoku}>
                        <GridFixed id={posId} flags={flags} />
                        <GridColorMark id={posId} sudoku={sudoku} selection={selection} />
                        <GridViolation id={posId} flags={flags} />
                        {constraintElements}
                        <GridSelection pos={pos} selection={selection} />
                        <GridDigit id={posId} sudoku={sudoku} />
                        <GridCenterMark id={posId} sudoku={sudoku} />
                        <GridCornerMark id={posId} sudoku={sudoku} />
                    </GridCell>;

                cellElements.push(cellElement);
            }

            const rowElement = <GridRow key={Position.rowId(row)} id={Position.rowId(row)}>{cellElements}</GridRow>;
            rowElements.push(rowElement);
        }

        const style = {
            fontSize: (50 / Math.max(numRows, numCols)) + 'vmin',
        }

        return (
            <div className="GridContainer">
                <div className="Grid" style={style}>
                    {rowElements}
                </div>
            </div>
        );
    }
}