import { AppState, Auth0Provider } from '@auth0/auth0-react';
import { createBrowserHistory } from 'history';
import React from 'react';
import ReactDOM from 'react-dom';
import UIRoot from './ui-shell/UIRoot';
import './index.css';

const withAuth = true;

const history = createBrowserHistory({ forceRefresh: true });

const redirectCallback = (appState?: AppState) => {
    history.replace(appState?.returnTo || window.location.pathname);
};

ReactDOM.render(
    <React.StrictMode>
        {withAuth ?
            <Auth0Provider
                domain="sudokuku.us.auth0.com"
                clientId="vHX2spoYRQeWSHj2odELGLGFarlYyKDJ"
                cacheLocation="localstorage"
                useRefreshTokens={true}
                redirectUri={window.location.origin}
                onRedirectCallback={redirectCallback}>
                <UIRoot />
            </Auth0Provider>
            : <UIRoot />}
    </React.StrictMode>,
    document.getElementById('root')
);