import { ConstraintProps } from "sudokuku-common/src/constraints/ConstraintReg";
import QuadConstraint from "sudokuku-common/src/constraints/local/QuadConstraint";
import './QuadConstraintCell.css';

export default function QuadConstraintCell(props: ConstraintProps): React.ReactElement | null {

    const constraint = props.constraint as QuadConstraint;

    const hasQuad = props.pos.isEqual(constraint.start());
    if (!hasQuad) return null;

    const digits = constraint.digits();

    return (
        <div className="QuadConstraint content">
            {digits[0] ? <div className="digit d1">{digits[0]}</div> : null}
            {digits[1] ? <div className="digit d2">{digits[1]}</div> : null}
            {digits[2] ? <div className="digit d3">{digits[2]}</div> : null}
            {digits[3] ? <div className="digit d4">{digits[3]}</div> : null}
        </div>
    );
}