import classNames from "classnames";
import { ConstraintProps } from "sudokuku-common/src/constraints/ConstraintReg";
import './CloneConstraintCell.css';

export default function CloneConstraintCell(props: ConstraintProps): React.ReactElement | null {

    const className = classNames({
        'CloneConstraint': true,
        'content': true,
    });

    return <div className={className} />;
}