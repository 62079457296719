import classNames from 'classnames';
import React from 'react';
import SudokuState from 'sudokuku-common/src/core/SudokuState';
import './GridCell.css';

export enum GridCellFlags {
    None = 0,
    IsSelected = 1 << 1,
    IsVoid = 1 << 2,
    IsSquare = 1 << 3,
    IsFixed = 1 << 4,
    IsViolation = 1 << 5,
}

export type GridCellProps = {
    id: string;
    flags: GridCellFlags;
    sudoku: SudokuState,
}

export type GridCellState = {
}

export default class GridCell extends React.PureComponent<GridCellProps, GridCellState> {

    override render(): React.ReactNode {

        const className = classNames({
            'GridCell': true,
            'void': this.props.flags & GridCellFlags.IsVoid,
            'square': this.props.flags & GridCellFlags.IsSquare,
            'fixed': this.props.flags & GridCellFlags.IsFixed,
            'violation': this.props.flags & GridCellFlags.IsViolation,
            'selected': this.props.flags & GridCellFlags.IsSelected
        });

        const def = this.props.sudoku.def();
        const width = Math.floor(100 / def.numCols());
        const height = Math.floor(100 / def.numRows())

        const style = {
            width: width + '%',
            height: height + '%',
        }

        return (
            <div id={this.props.id} className={className} style={style}>
                {this.props.children}
            </div>
        );
    }
}