import React from "react";
import ParityEvenConstraint from "sudokuku-common/src/constraints/cell/ParityEvenConstraint";
import ParityOddConstraint from "sudokuku-common/src/constraints/cell/ParityOddConstraint";
import ConstraintDef from "sudokuku-common/src/constraints/ConstraintDef";
import ConstraintReg, { ConstraintProps } from "sudokuku-common/src/constraints/ConstraintReg";
import DiffConstraint from "sudokuku-common/src/constraints/domino/DiffConstraint";
import InequalityConstraint from "sudokuku-common/src/constraints/domino/InequalityConstraint";
import RatioConstraint from "sudokuku-common/src/constraints/domino/RatioConstraint";
import SumConstraint from "sudokuku-common/src/constraints/domino/SumConstraint";
import KillerCageConstraint from "sudokuku-common/src/constraints/killer/KillerCageConstraint";
import LittleKillerConstraint from "sudokuku-common/src/constraints/killer/LittleKillerConstraint";
import SandwichConstraint from "sudokuku-common/src/constraints/killer/SandwichConstraint";
import ArrowConstraint from "sudokuku-common/src/constraints/line/ArrowConstraint";
import BetweenConstraint from "sudokuku-common/src/constraints/line/BetweenConstraint";
import LockoutConstraint from "sudokuku-common/src/constraints/line/LockoutConstraint";
import PalindromeConstraint from "sudokuku-common/src/constraints/line/PalindromeConstraint";
import RenbanConstraint from "sudokuku-common/src/constraints/line/RenbanConstraint";
import ThermoConstraint from "sudokuku-common/src/constraints/line/ThermoConstraint";
import WhisperConstraint from "sudokuku-common/src/constraints/line/WhisperConstraint";
import CloneConstraint from "sudokuku-common/src/constraints/local/CloneConstraint";
import QuadConstraint from "sudokuku-common/src/constraints/local/QuadConstraint";
import BoxConstraint from "sudokuku-common/src/constraints/unique/BoxConstraint";
import DiagConstraint from "sudokuku-common/src/constraints/unique/DiagConstraint";
import RegionConstraint from "sudokuku-common/src/constraints/unique/RegionConstraint";
import ArrowConstraintCell from "./ArrowConstraintCell";
import BetweenConstraintCell from "./BetweenConstraintCell";
import BoxConstraintCell from "./BoxConstraintCell";
import CloneConstraintCell from "./CloneConstraintCell";
import DiagConstraintCell from "./DiagConstraintCell";
import DiffConstraintCell from "./DiffConstraintCell";
import InequalityConstraintCell from "./InequalityConstraintCell";
import KillerConstraintCell from "./KillerCageConstraintCell";
import LittleKillerConstraintCell from "./LittleKillerConstraintCell";
import LockoutConstraintCell from "./LockoutConstraintCell";
import PalindromeConstraintCell from "./PalindromeConstraintCell";
import ParityConstraintCell from "./ParityConstraintCell";
import QuadConstraintCell from "./QuadConstraintCell";
import RatioConstraintCell from "./RatioConstraintCell";
import RenbanConstraintCell from "./RenbanConstraintCell";
import SandwichConstraintCell from "./SandwichConstraintCell";
import SumConstraintCell from "./SumConstraintCell";
import ThermoConstraintCell from "./ThermoConstraintCell";
import WhisperConstraintCell from "./WhisperConstraintCell";

export default class ConstraintCellDefs {

    private readonly box = ConstraintCellDefs.register(BoxConstraint.def(), BoxConstraintCell);
    private readonly region = ConstraintCellDefs.register(RegionConstraint.def(), BoxConstraintCell);
    private readonly killerCage = ConstraintCellDefs.register(KillerCageConstraint.def(), KillerConstraintCell);
    private readonly littleKiller = ConstraintCellDefs.register(LittleKillerConstraint.def(), LittleKillerConstraintCell);
    private readonly sandwich = ConstraintCellDefs.register(SandwichConstraint.def(), SandwichConstraintCell);
    private readonly thermo = ConstraintCellDefs.register(ThermoConstraint.def(), ThermoConstraintCell);
    private readonly arrow = ConstraintCellDefs.register(ArrowConstraint.def(), ArrowConstraintCell);
    private readonly palindrome = ConstraintCellDefs.register(PalindromeConstraint.def(), PalindromeConstraintCell);
    private readonly between = ConstraintCellDefs.register(BetweenConstraint.def(), BetweenConstraintCell);
    private readonly lockout = ConstraintCellDefs.register(LockoutConstraint.def(), LockoutConstraintCell);
    private readonly renban = ConstraintCellDefs.register(RenbanConstraint.def(), RenbanConstraintCell);
    private readonly whisper = ConstraintCellDefs.register(WhisperConstraint.def(), WhisperConstraintCell);
    private readonly ratio = ConstraintCellDefs.register(RatioConstraint.def(), RatioConstraintCell);
    private readonly diff = ConstraintCellDefs.register(DiffConstraint.def(), DiffConstraintCell);
    private readonly sum = ConstraintCellDefs.register(SumConstraint.def(), SumConstraintCell);
    private readonly diag = ConstraintCellDefs.register(DiagConstraint.def(), DiagConstraintCell);
    private readonly quad = ConstraintCellDefs.register(QuadConstraint.def(), QuadConstraintCell);
    private readonly odd = ConstraintCellDefs.register(ParityOddConstraint.def(), ParityConstraintCell);
    private readonly even = ConstraintCellDefs.register(ParityEvenConstraint.def(), ParityConstraintCell);
    private readonly ineq = ConstraintCellDefs.register(InequalityConstraint.def(), InequalityConstraintCell);
    private readonly clone = ConstraintCellDefs.register(CloneConstraint.def(), CloneConstraintCell);

    private static register(def: ConstraintDef, func: React.FunctionComponent<ConstraintProps>, render = ConstraintCellDefs.render) {
        return ConstraintReg.registerRender(def, p => render(func, p));
    }

    private static render(func: React.FunctionComponent<ConstraintProps>, props: ConstraintProps): React.ReactElement<ConstraintProps> | null {
        return props.constraint.visible().has(props.pos) ? React.createElement(func, props) : null;
    }
}