/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */

export default class DbSearchResult {

    private _row: {
        id: string;
        update_time: string;
        revision: number;
        title: string | null;
        author: string | null;
        is_public: boolean | null;
        puzzle_keywords: string[] | null;
        solution_keywords: string[] | null;
    }

    private constructor(row: any) {
        this._row = row;
    }

    public id(): string {
        return this._row.id;
    }

    public updateTime(): number {
        return Date.parse(this._row.update_time);
    }

    public revision(): number {
        return this._row.revision;
    }

    public title(): string | null {
        return this._row.title;
    }

    public author(): string | null {
        return this._row.author;
    }

    public isPublic(): boolean | null {
        return this._row.is_public;
    }

    public static fromRow(row: any): DbSearchResult {
        return new DbSearchResult(row);
    }
}