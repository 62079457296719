/* eslint-disable @typescript-eslint/no-explicit-any */

import AuthAudiences from "sudokuku-common/src/auth/AuthAudiences";
import AuthScopes from "sudokuku-common/src/auth/AuthScopes";
import SudokuState from "sudokuku-common/src/core/SudokuState";
import DbCreateResult from "sudokuku-common/src/db/DbCreateResult";
import DbSearchResult from "sudokuku-common/src/db/DbSearchResult";
import SudokuSerializer from "sudokuku-common/src/serialization/SudokuSerializer";
import SolvePath from "sudokuku-common/src/solver/SolvePath";
import Auth from "../../auth/Auth";
import SudokuApiError from "./SudokuApiError";

export default abstract class SudokuApi {

    public static getSudoku(id: string): Promise<SudokuState> {

        const urlId = encodeURIComponent(id);

        const sudoku = fetch(`/api/v1/sudoku/${urlId}`)
            .then(res => this.json(res))
            .then(json => SudokuSerializer.loadSudoku(json))

        return sudoku;
    }

    public static getSolution(id: string): Promise<SolvePath> {

        const urlId = encodeURIComponent(id);

        const sudoku = fetch(`/api/v1/solution/${urlId}`)
            .then(res => this.json(res))
            .then(json => SudokuSerializer.loadSolution(json));

        return sudoku;
    }

    public static async getSearchResults(auth: Auth, query: string, page: number, pageSize: number): Promise<DbSearchResult[]> {

        const authHeaders = await auth.fetchHeaders(AuthAudiences.Puzzle, AuthScopes.PuzzlesRead);

        query = query.trim();

        const limit = pageSize;
        const offset = ((page - 1) * limit) + 1;

        const url = new URL('/api/v1/sudoku', window.location.href);
        if (query.length) url.searchParams.append('q', query);
        if (offset) url.searchParams.append('o', offset.toString());
        if (limit) url.searchParams.append('l', limit.toString());

        const init = {
            method: 'GET',
            headers: authHeaders,
        }

        const sudokus = fetch(url.toString(), init)
            .then(res => this.json(res))
            .then(json => SudokuSerializer.loadDbSearchResults(json));

        return sudokus;
    }

    public static async putSudoku(auth: Auth, id: string, sudoku: SudokuState): Promise<DbCreateResult> {

        const authHeaders = await auth.fetchHeaders(AuthAudiences.Puzzle, AuthScopes.PuzzlesWrite);

        const urlId = encodeURIComponent(id);

        const body = SudokuSerializer.saveSudoku(sudoku);

        const init = {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                ...authHeaders,
            },
            body: JSON.stringify(body),
        }

        const result = fetch(`/api/v1/sudoku/${urlId}`, init)
            .then(res => this.json(res))
            .then(json => SudokuSerializer.loadDbCreateResult(json));

        return result;
    }

    public static async importSudoku(auth: Auth, value: string): Promise<DbCreateResult> {

        const authHeaders = await auth.fetchHeaders(AuthAudiences.Puzzle, AuthScopes.PuzzlesWrite);

        const body = {
            value: value,
        }

        const init = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                ...authHeaders,
            },
            body: JSON.stringify(body),
        }

        const result = fetch('/api/v1/sudoku', init)
            .then(res => this.json(res))
            .then(json => SudokuSerializer.loadDbCreateResult(json));

        return result;
    }

    public static async deleteSudoku(auth: Auth, id: string): Promise<void> {

        const urlId = encodeURIComponent(id);
        const authHeaders = await auth.fetchHeaders(AuthAudiences.Puzzle, AuthScopes.PuzzlesWrite);

        const init = {
            method: 'DELETE',
            headers: authHeaders,
        }

        await fetch(`/api/v1/sudoku/${urlId}`, init)
            .then(res => this.json(res));
    }

    private static async json(res: Response): Promise<any> {
        if (!res.ok) throw new SudokuApiError(res, await res.text());
        return res.json();
    }
}