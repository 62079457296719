import React from 'react';
import classNames from 'classnames';
import SudokuState from 'sudokuku-common/src/core/SudokuState';
import Position from 'sudokuku-common/src/core/Position';
import './GridCenterMark.css';

export type GridCenterMarkProps = {
    id: string;
    sudoku: SudokuState;
}

export type GridCenterMarkState = {    
}

export default class GridCenterMark extends React.PureComponent<GridCenterMarkProps,GridCenterMarkState> {

    override render(): React.ReactNode {

        const sudoku = this.props.sudoku;
        const square = Position.fromString(this.props.id);

        const hasDigit = sudoku.hasDigit(square);
        if (hasDigit) return null;

        const centerMark = sudoku.centerMark(square);
        if (!centerMark) return null;

        const lengthClass = 'd' + centerMark.length;

        const className = classNames({
            'GridCenterMark': true,
            'content': true,
            [lengthClass]: true,
          });
  
        return <div className={className}>{centerMark}</div>;
    }
}