import Position from '../../core/Position';
import PositionLine from '../../core/PositionLine';
import SudokuError from '../../core/SudokuError';
import SudokuState from '../../core/SudokuState';
import PropertySelection from '../../properties/PropertySelection';
import Constraint from '../Constraint';
import ConstraintDef from '../ConstraintDef';
import ThermoConstraint from '../line/ThermoConstraint';

export default class InequalityConstraint extends ThermoConstraint { // shouldnt be a thermo!

    private static readonly _ineq = new ConstraintDef('ineq', 'Inequality', 'Domino', ['Inequality', 'Greater Than', 'Less Than']);

    private constructor(line: PositionLine) {
        super(line);
    }

    public static override def(): ConstraintDef {
        return this._ineq;
    }

    public override def(): ConstraintDef {
        return InequalityConstraint.def();
    }

    public override rules(): string {
        return 'Cells separated by an inequality sign (A > B) require the digit A to be greater than B.';
    }

    public static override fromString(str: string): ThermoConstraint {
        return InequalityConstraint.fromLine(PositionLine.fromString(str));
    }

    public static override fromLine(line: PositionLine): ThermoConstraint {

        if (!this.isValidLine(line)) {
            throw new SudokuError('Line is not a valid inequality constraint.');
        }

        return new InequalityConstraint(line);
    }

    public static override fromSelection(sudoku: SudokuState, selection: Position[], propertySelection: [PropertySelection]): SudokuState {
        
        for (const line of PositionLine.fromArrayMany(selection)) {
            sudoku = Constraint.add(sudoku, new InequalityConstraint(line.reverse()), propertySelection);
        }

        return sudoku;
    }
}