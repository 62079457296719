import PositionSet from '../../core/PositionSet';
import SudokuState from '../../core/SudokuState';
import ViolationState from '../../core/ViolationState';
import Constraint from '../Constraint';

export default abstract class UniqueConstraint extends Constraint {

    private readonly _squares: PositionSet;

    protected constructor(squares: PositionSet, prev?: UniqueConstraint) {
        super(prev);

        this._squares = squares;
    }

    public squares(): PositionSet {
        return this._squares;
    }

    public isUnique(): boolean {
        return true;
    }

    public override validate(sudoku: SudokuState, violations: ViolationState): ViolationState {
        violations = super.validate(sudoku, violations);

        for (const dupeSquares of Constraint.findDuplicates(sudoku, this._squares).toArray()) {
            violations = violations.addViolation(dupeSquares);
        }

        return violations;
    }
}