import SudokuDef from './SudokuDef';

export default abstract class SudokuKeywords {

    public static fromDef(def: SudokuDef): string[] {

        // start with custom keywords
        const keywords = new Set<string>(def.info().keywords());

        // add puzzle keywords
        const size = def.squares().size();
        const constraints = def.constraints().all();
        const is9x9 = size === (9 * 9);
        const isSamurai = size === (5 * 9 * 9) - (4 * 9);
        const isVariant = constraints.find(c => !c.def().isStandard()) != undefined;
        if (isSamurai) keywords.add('Samurai');
        if (isVariant) keywords.add('Variant');
        if (is9x9 && !isVariant) keywords.add('Classic');

        // add constraint keywords
        for (const keyword of constraints.flatMap(c => c.keywords())) {
            keywords.add(keyword);
        }

        // done
        return Array.from(keywords).sort();
    }
}