import React from "react";
import RichTextScanner, { RichTextToken, RichTextTokenKind } from "./RichTextScanner";

export default class RichTextParser {

    private _tokens: Iterator<RichTextToken>;
    private _token: RichTextToken;

    private constructor(tokens: Iterator<RichTextToken>) {
        this._tokens = tokens;
        this._token = this.consume();
    }

    public static parseString(str: string): React.ReactNode {
        return this.parseTokens(RichTextScanner.scan(str));
    }

    public static parseTokens(tokens: Iterator<RichTextToken>): React.ReactNode {
        return new RichTextParser(tokens).parseRoot();
    }

    private parseRoot(): React.ReactNode {

        const children: React.ReactNode[] = [];

        while (!this.is(RichTextTokenKind.End)) {
            const child = this.parseNode();
            children.push(child);
        }

        this.consume(RichTextTokenKind.End);

        return <React.Fragment>{children}</React.Fragment>;
    }

    private parseNode(): React.ReactNode {

        if (this.is(RichTextTokenKind.Text)) {
            return this.parseText();
        }
        else if (this.is(RichTextTokenKind.StartSpan)) {
            return this.parseSpan();
        }

        // error, ignore
        this.consume();
        return null;
    }

    private parseText(): React.ReactNode {

        const text = this.value();
        this.consume(RichTextTokenKind.Text);

        return text;
    }

    private parseSpan(): React.ReactNode {

        const children: React.ReactNode[] = [];
        let className: string | undefined = undefined;

        const index = this.index();
        this.consume(RichTextTokenKind.StartSpan);

        if (this.is(RichTextTokenKind.ClassName)) {
            className = this.value();
            this.consume(RichTextTokenKind.ClassName);
        }

        while (!this.is(RichTextTokenKind.EndSpan) && !this.is(RichTextTokenKind.End)) {
            const child = this.parseNode();
            children.push(child);
        }

        this.consume(RichTextTokenKind.EndSpan);

        return <span key={index} className={className}>{children}</span>
    }

    private is(kind: RichTextTokenKind): boolean {
        return this._token.kind === kind;
    }

    private value(): string {
        return this._token.value;
    }

    private index(): number {
        return this._token.index;
    }

    private consume(kind?: RichTextTokenKind): RichTextToken {

        // we could validate kind here...

        // consume
        const result = this._tokens.next();
        this._token = result.done ? { kind: RichTextTokenKind.End, value: '', index: -1 } : result.value;

        // done
        return this._token;
    }
}