import classNames from 'classnames';
import React, { MouseEventHandler } from 'react';
import { InputMode } from '../../model/InputState';
import './UIInputMode.css';

export type UIInputModeProps = {
    mode: InputMode,
    onClick: MouseEventHandler
}

export type UIInputModeState = {
}

export default class UIInputMode extends React.PureComponent<UIInputModeProps, UIInputModeState> {

    override render(): React.ReactNode {

        const className = classNames({
            'UIInputMode': true,
            'digit': this.props.mode === InputMode.Digits,
            'centerMark': this.props.mode === InputMode.CenterMarks,
            'cornerMark': this.props.mode === InputMode.CornerMarks,
            'colorMark': this.props.mode === InputMode.ColorMarks
        });

        return (
            <div className={className}>

                <button id="InputDigits" onClick={this.props.onClick}>
                    1
                </button>

                <button id="InputCornerMarks" onClick={this.props.onClick}>
                    <div className="m1">1</div>
                    <div className="m2">2</div>
                    <div className="m3">3</div>
                </button>

                <button id="InputCenterMarks" onClick={this.props.onClick}>
                    123
                </button>

                <button id="InputColorMarks" onClick={this.props.onClick}>
                    <div className="cb">
                        <div className="c c1"></div>
                        <div className="c c2"></div>
                        <div className="c c3"></div>
                    </div>
                </button>
            </div>
        );
    }
}