
export default class SudokuInfo {

    private readonly _title: string;
    private readonly _author: string;
    private readonly _rules: string;
    private readonly _links: string;
    private readonly _isPublic: boolean;
    private readonly _keywords: readonly string[];

    private constructor(title: string, author: string, rules: string, links: string, isPublic: boolean, keywords: readonly string[]) {
        this._title = title;
        this._author = author;
        this._rules = rules;
        this._links = links;
        this._isPublic = isPublic;
        this._keywords = keywords;
    }

    public title(): string {
        return this._title;
    }

    public author(): string {
        return this._author;
    }

    public rules(): string {
        return this._rules;
    }

    public links(): string {
        return this._links;
    }

    public isPublic(): boolean {
        return this._isPublic;
    }

    public keywords(): readonly string[] {
        return this._keywords;
    }

    public setTitle(title: string): SudokuInfo {
        return new SudokuInfo(title, this._author, this._rules, this._links, this._isPublic, this._keywords);
    }

    public setAuthor(author: string): SudokuInfo {
        return new SudokuInfo(this._title, author, this._rules, this._links, this._isPublic, this._keywords);
    }

    public setLinks(links: string): SudokuInfo {
        return new SudokuInfo(this._title, this._author, this._rules, links, this._isPublic, this._keywords);
    }

    public setPublic(isPublic: boolean): SudokuInfo {
        return new SudokuInfo(this._title, this._author, this._rules, this._links, isPublic, this._keywords);
    }

    static fromEmpty(): SudokuInfo {
        return new SudokuInfo('', '', '', '', false, []);
    }

    static fromTitle(title: string, author?: string, rules?: string, links?: string, isPublic?: boolean): SudokuInfo {
        return new SudokuInfo(title, author ?? '', rules ?? '', links ?? '', isPublic ?? false, []);
    }

    static fromData(title: string, author: string, rules: string, links: string, isPublic: boolean, keywords: readonly string[]): SudokuInfo {
        return new SudokuInfo(title, author, rules, links, isPublic, keywords);
    }
}
