import SudokuChange from '../core/SudokuChange';
import SolveDeduction from './SolveDeduction';

export default class SolveStep {

    private readonly _deduction: SolveDeduction;
    private readonly _changes: readonly SudokuChange[];

    private constructor(deduction: SolveDeduction, changes: readonly SudokuChange[]) {
        this._deduction = deduction;
        this._changes = changes;
    }

    static fromDeduction(deduction: SolveDeduction, changes: readonly SudokuChange[]): SolveStep {
        return new SolveStep(deduction, changes);
    }

    public deduction(): SolveDeduction {
        return this._deduction;
    }

    public changes(): readonly SudokuChange[] {
        return this._changes;
    }
}