import Property, { PropertyContext, PropertyFlags } from '../properties/Property';
import PropertyObject from '../properties/PropertyObject';
import DigitSet from './DigitSet';
import Position from './Position';
import PositionSet from './PositionSet';
import SudokuChange, { SudokuChangeDigits, SudokuChangeFunc, SudokuChangeSquares } from './SudokuChange';
import SudokuDef from './SudokuDef';
import SudokuError from './SudokuError';
import SudokuInfo from './SudokuInfo';

export default class SudokuState {

    private readonly _def: SudokuDef;
    private readonly _digits: Map<string, string>;
    private readonly _centerMarks: Map<string, string>;
    private readonly _cornerMarks: Map<string, string>;
    private readonly _colorMarks: Map<string, string>;
    private readonly _propertyObject: PropertyObject;
    private readonly _prev: SudokuState | undefined;
    private readonly _next: SudokuState | undefined;

    private constructor(def: SudokuDef, digits?: Map<string, string>, centerMarks?: Map<string, string>, cornerMarks?: Map<string, string>, colorMarks?: Map<string, string>, prev?: SudokuState, next?: SudokuState) {
        this._def = def;
        this._digits = digits || new Map<string, string>();
        this._centerMarks = centerMarks || new Map<string, string>();
        this._cornerMarks = cornerMarks || new Map<string, string>();
        this._colorMarks = colorMarks || new Map<string, string>();
        this._propertyObject = new PropertyObject(prev?.propertyObject(), this, this.properties(), this.children());
        this._prev = prev;
        this._next = next;
    }

    static fromDef(def: SudokuDef): SudokuState {
        return new SudokuState(def);
    }

    static fromState(oldState: SudokuState, prev?: SudokuState, next?: SudokuState): SudokuState {
        return new SudokuState(
            oldState._def,
            oldState._digits,
            oldState._centerMarks,
            oldState._cornerMarks,
            oldState._colorMarks,
            prev, next);
    }

    static fromData(def: SudokuDef, digits: Map<string, string>, centerMarks: Map<string, string>, cornerMarks: Map<string, string>, colorMarks: Map<string, string>): SudokuState {
        return new SudokuState(def, digits, centerMarks, cornerMarks, colorMarks);
    }

    def(): SudokuDef {
        return this._def;
    }

    isEmpty(): boolean {
        return this.def().isEmpty();
    }

    allDigits(): Map<string, string> {
        return this._digits;
    }

    allCenterMarks(): Map<string, string> {
        return this._centerMarks;
    }

    allCornerMarks(): Map<string, string> {
        return this._cornerMarks;
    }

    allColorMarks(): Map<string, string> {
        return this._colorMarks;
    }

    allSquares(): PositionSet {
        return this._def.squares();
    }

    emptySquares(): PositionSet {
        return this.allSquares().filter(s => !this.hasDigit(s));
    }

    solvedSquares(): PositionSet {
        return this.allSquares().filter(s => this.hasDigit(s));
    }

    solvedPercent(): number {
        let total = 0;
        let solved = 0;

        for (const square of this.allSquares().toArray()) {
            if (this.isFixed(square)) continue;

            total += 3;

            if (this.hasDigit(square)) {
                solved += 3;
            }
            else if (this.hasCenterMark(square)) {
                solved += 2;
            }
            else if (this.hasCornerMark(square)) {
                solved += 1;
            }
        }

        return Math.floor(100 * solved / total);
    }

    digitSquares(digit: string): PositionSet {
        return this.allSquares().filter(s => this.digit(s) === digit);
    }

    propertyObject(): PropertyObject {
        return this._propertyObject;
    }

    prev(): SudokuState | undefined {
        return this._prev;
    }

    undo(): SudokuState {
        return this._prev ? SudokuState.fromState(this._prev, this._prev._prev, this) : this;
    }

    redo(): SudokuState {
        return this._next ? this._next : this;
    }

    isComplete(): boolean {
        return this.emptySquares().isEmpty();
    }

    isFixed(square: Position): boolean {
        return this._def.isFixed(square);
    }

    isSquare(square: Position): boolean {
        return this._def.isSquare(square);
    }

    isGuess(square: Position): boolean {
        return this._digits.has(square.toString());
    }

    hasDigit(square: Position): boolean {
        return this.isFixed(square) || this.isGuess(square);
    }

    hasCenterMark(square: Position): boolean {
        return this._centerMarks.has(square.toString());
    }

    hasCornerMark(square: Position): boolean {
        return this._cornerMarks.has(square.toString());
    }

    digit(square: Position): string | undefined {
        return this._def.digit(square) ?? this._digits.get(square.toString());
    }

    digits(squares: PositionSet): string[] {
        const digits: string[] = [];

        for (const square of squares.toArray()) {
            const digit = this.digit(square);
            if (digit) digits.push(digit);
        }

        return digits;
    }

    centerMark(square: Position): string | undefined {
        return this._centerMarks.get(square.toString());
    }

    cornerMark(square: Position): string | undefined {
        return this._cornerMarks.get(square.toString());
    }

    colorMark(square: Position): string | undefined {
        return this._colorMarks.get(square.toString());
    }

    setDef(def: SudokuDef): SudokuState {

        // no change?
        if (def === this._def) return this;

        return new SudokuState(def,
            this.mergeDigitsImpl(def, this._digits),
            this.mergeDigitsImpl(def, this._centerMarks),
            this.mergeDigitsImpl(def, this._cornerMarks),
            this.mergeDigitsImpl(def, this._colorMarks),
            this);
    }

    setDigit(squares: SudokuChangeSquares, digit: SudokuChangeDigits): SudokuState {
        return this.applyChange(SudokuChange.setDigit(squares, digit));
    }

    toggleDigit(squares: SudokuChangeSquares, digit: SudokuChangeDigits): SudokuState {
        return this.applyChange(SudokuChange.toggleDigit(squares, digit));
    }

    clearDigit(squares: SudokuChangeSquares): SudokuState {
        return this.applyChange(SudokuChange.setDigit(squares, ''));
    }

    setCenterMark(squares: SudokuChangeSquares, digit: SudokuChangeDigits): SudokuState {
        return this.applyChange(SudokuChange.setCenterMark(squares, digit));
    }

    addCenterMark(squares: SudokuChangeSquares, digit: SudokuChangeDigits): SudokuState {
        return this.applyChange(SudokuChange.addCenterMark(squares, digit));
    }

    toggleCenterMark(squares: SudokuChangeSquares, digit: SudokuChangeDigits): SudokuState {
        return this.applyChange(SudokuChange.toggleCenterMark(squares, digit));
    }

    clearCenterMarks(squares: SudokuChangeSquares): SudokuState {
        return this.applyChange(SudokuChange.setCenterMark(squares, ''));
    }

    addCornerMark(squares: SudokuChangeSquares, digit: SudokuChangeDigits): SudokuState {
        return this.applyChange(SudokuChange.addCornerMark(squares, digit));
    }

    removeCornerMark(squares: SudokuChangeSquares, digit: SudokuChangeDigits): SudokuState {
        return this.applyChange(SudokuChange.removeCornerMark(squares, digit));
    }

    toggleCornerMark(squares: SudokuChangeSquares, digit: SudokuChangeDigits): SudokuState {
        return this.applyChange(SudokuChange.toggleCornerMark(squares, digit));
    }

    clearCornerMarks(squares: SudokuChangeSquares): SudokuState {
        return this.applyChange(SudokuChange.setCornerMark(squares, ''));
    }

    setColorMark(squares: SudokuChangeSquares, digit: SudokuChangeDigits): SudokuState {
        return this.applyChange(SudokuChange.setColorMark(squares, digit));
    }

    addColorMark(squares: SudokuChangeSquares, digit: SudokuChangeDigits): SudokuState {
        return this.applyChange(SudokuChange.addColorMark(squares, digit));
    }

    toggleColorMark(squares: SudokuChangeSquares, digit: SudokuChangeDigits): SudokuState {
        return this.applyChange(SudokuChange.toggleColorMark(squares, digit));
    }

    clearColorMarks(squares: SudokuChangeSquares): SudokuState {
        return this.applyChange(SudokuChange.setColorMark(squares, ''));
    }

    setPrev(prev: SudokuState | undefined): SudokuState {
        return prev !== this ? new SudokuState(this._def, this._digits, this._centerMarks, this._cornerMarks, this._colorMarks, prev) : this;
    }

    startTransaction(): SudokuState {
        return this;
    }

    endTransaction(txn: SudokuState): SudokuState {
        return this.setPrev(txn);
    }

    applyChanges(changes: readonly SudokuChange[], changeFunc?: SudokuChangeFunc, changedSquares?: Position[]): SudokuState {
        const txn = this.startTransaction();

        const sudoku = changes.reduce((s, c) => s.applyChange(c, changeFunc, changedSquares), txn);

        return sudoku.endTransaction(txn);
    }

    applyChange(change: SudokuChange, customFunc?: SudokuChangeFunc, changedSquares?: Position[]): SudokuState {
        const newDigits = this.startChange(change);
        let hasChanged = false;

        switch (change.op()) {
            case 'set': hasChanged = this.applyChangeImpl(change, newDigits, (s, o, n) => n, customFunc, changedSquares); break;
            case 'add': hasChanged = this.applyChangeImpl(change, newDigits, (s, o, n) => o.unionWith(n), customFunc, changedSquares); break;
            case 'rem': hasChanged = this.applyChangeImpl(change, newDigits, (s, o, n) => o.exceptWith(n), customFunc, changedSquares); break;
            case 'toggle': hasChanged = this.applyChangeImpl(change, newDigits, (s, o, n) => o.disjunctiveUnionWith(n), customFunc, changedSquares); break;
            default: throw new SudokuError(`Unknown change op: ${change.op()}`);
        }

        return hasChanged ? this.commitChange(change, newDigits) : this;
    }

    private startChange(change: SudokuChange): Map<string, string> {
        switch (change.target()) {
            case 'digit': return new Map(this._digits);
            case 'center': return new Map(this._centerMarks);
            case 'corner': return new Map(this._cornerMarks);
            case 'color': return new Map(this._colorMarks);
            default: throw new SudokuError(`Unknown change target: ${change.target()}`);
        }
    }

    private commitChange(change: SudokuChange, newDigits: Map<string, string>): SudokuState {
        switch (change.target()) {
            case 'digit': return new SudokuState(this._def, newDigits, this._centerMarks, this._cornerMarks, this._colorMarks, this);
            case 'center': return new SudokuState(this._def, this._digits, newDigits, this._cornerMarks, this._colorMarks, this);
            case 'corner': return new SudokuState(this._def, this._digits, this._centerMarks, newDigits, this._colorMarks, this);
            case 'color': return new SudokuState(this._def, this._digits, this._centerMarks, this._cornerMarks, newDigits, this);
            default: throw new SudokuError(`Unknown change target: ${change.target()}`);
        }
    }

    private applyChangeImpl(change: SudokuChange, newDigits: Map<string, string>, changeFunc: SudokuChangeFunc, customFunc?: SudokuChangeFunc, changedSquares?: Position[]): boolean {
        let hasChanged = false;
        const updateFixed = (change.target() === "color")

        for (const square of change.squares().toArray()) {

            // dont update squares outside the grid
            if (!this.isSquare(square)) continue;

            // dont update fixed squares
            if (this.isFixed(square) && !updateFixed) continue;

            // update square
            const key = square.toString();
            const oldValue = DigitSet.fromString(newDigits.get(key) || '');
            let newValue = changeFunc(square, oldValue, change.digits(), change, this);
            if (customFunc) newValue = customFunc(square, oldValue, newValue, change, this);
            if (oldValue.isEqual(newValue)) continue;

            // set new value
            newValue.isEmpty() ? newDigits.delete(key) : newDigits.set(key, newValue.toString());
            hasChanged = true;

            // accumulate changes
            if (changedSquares) changedSquares.push(square);
        }

        return hasChanged;
    }

    private mergeDigitsImpl(def: SudokuDef, digits: Map<string, string>) {
        const newDigits = new Map<string, string>();

        for (const posId of Array.from(digits.keys())) {
            const pos = Position.fromString(posId);
            const value = digits.get(posId);

            if (!value) continue;
            if (!def.isSquare(pos)) continue;
            if (def.isFixed(pos)) continue;

            newDigits.set(posId, value);
        }

        return newDigits;
    }

    private properties(): Property[] {
        return [
            new Property('Title', 'Info', 'text', PropertyFlags.None, this, () => this.def().info().title(), (c, p, v) => SudokuState.setTitle(c, p, v)),
            new Property('Author', 'Info', 'text', PropertyFlags.None, this, () => this.def().info().author(), (c, p, v) => SudokuState.setAuthor(c, p, v)),
            new Property('Links', 'Info', 'textarea', PropertyFlags.None, this, () => this.def().info().links(), (c, p, v) => SudokuState.setLinks(c, p, v)),
            new Property('Revision', 'Info', 'number', PropertyFlags.IsReadOnly, this, () => this.def().revision().toString()),
            new Property('Publicly Visible', 'Info', 'checkbox', PropertyFlags.None, this, () => this.def().info().isPublic().toString(), (c, p, v) => SudokuState.setPrivate(c, p, Property.parseBoolean(v))),
        ];
    }

    private children(): PropertyObject[] {
        return this.def().constraints().all().map(c => c.propertyObject());
    }

    private static setTitle(ctx: PropertyContext, property: Property, value: string): void {
        this.setInfo(ctx, (ctx.state as SudokuState).def().info().setTitle(value));
    }

    private static setAuthor(ctx: PropertyContext, properties: Property, value: string): void {
        this.setInfo(ctx, (ctx.state as SudokuState).def().info().setAuthor(value));
    }

    private static setLinks(ctx: PropertyContext, properties: Property, value: string): void {
        this.setInfo(ctx, (ctx.state as SudokuState).def().info().setLinks(value));
    }

    private static setPrivate(ctx: PropertyContext, properties: Property, value: boolean): void {
        this.setInfo(ctx, (ctx.state as SudokuState).def().info().setPublic(value));
    }

    private static setInfo(ctx: PropertyContext, newInfo: SudokuInfo): void {
        const oldSudoku = ctx.state as SudokuState;
        const oldDef = oldSudoku.def();

        const newDef = oldDef.setInfo(newInfo);
        const newSudoku = oldSudoku.setDef(newDef);

        ctx.state = newSudoku;
    }
}