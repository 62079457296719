import PositionSet from '../../core/PositionSet';
import ConstraintDef from '../ConstraintDef';
import RatioConstraint from '../domino/RatioConstraint';
import NegativeConstraint from './NegativeConstraint';

export default class RatioNegativeConstraint extends NegativeConstraint {

    private static readonly _ratioNeg = new ConstraintDef('ratio-', 'Ratio (Negative)', 'Global', ['Ratio', 'Negative']);

    protected constructor(squares: PositionSet) {
        super(squares, RatioConstraint, RatioConstraint.fromSquares(squares));
    }

    public static def(): ConstraintDef {
        return this._ratioNeg;
    }

    public override def(): ConstraintDef {
        return RatioNegativeConstraint.def();
    }

    public rules(): string {
        return 'Adjacent cells cannot contain digits with a ratio of 1:2 (unless explicitly allowed by a dot).';
    }

    public static fromString(str: string): RatioNegativeConstraint {
        return RatioNegativeConstraint.fromSquares(PositionSet.fromString(str));
    }

    public static fromSquares(squares: PositionSet): RatioNegativeConstraint {
        return new RatioNegativeConstraint(squares);
    }

    public static fromDefault(): RatioNegativeConstraint {
        return new RatioNegativeConstraint(PositionSet.fromEmpty());
    }
}