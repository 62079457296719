import classNames from "classnames";
import { ConstraintProps } from "sudokuku-common/src/constraints/ConstraintReg";
import KillerCageConstraint from "sudokuku-common/src/constraints/killer/KillerCageConstraint";
import './KillerCageConstraintCell.css';

export default function KillerConstraintCell(props: ConstraintProps): React.ReactElement | null {

    const constraint = props.constraint as KillerCageConstraint;
    const sum = constraint.sum();
    const squares = constraint.squares();

    const isTop = squares.isTop(props.pos);
    const isRight = squares.isRight(props.pos);
    const isBottom = squares.isBottom(props.pos);
    const isLeft = squares.isLeft(props.pos);
    if (!isTop && !isRight && !isBottom && !isLeft) return null;

    const className = classNames({
        'KillerCageConstraint': true,
        'content': true,
        't': isTop,
        'r': isRight,
        'b': isBottom,
        'l': isLeft,
    });

    let valueElement: React.ReactElement | null = null;
    if (sum && squares.isFirst(props.pos)) {
        valueElement = <div className="v">{sum}</div>
    }

    return (
        <div className={className}>
            {valueElement}
        </div>
    );
}