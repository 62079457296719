import Position from './Position';
import PositionSet from './PositionSet';

export default class PositionSets {

    private readonly _sets: PositionSet[];

    private constructor(sets: PositionSet[]) {
        this._sets = sets;
    }

    static fromAny(sets: PositionSets | PositionSet[] | undefined): PositionSets {
        if (sets === undefined) return PositionSets.fromEmpty();
        if (sets instanceof PositionSets) return sets;
        return PositionSets.fromArray(sets);
    }

    static fromEmpty(): PositionSets {
        return new PositionSets([]);
    }

    static fromArray(sets: PositionSet[]): PositionSets {
        return new PositionSets(sets);
    }

    static fromString(str: string): PositionSets {
        const sets = str.split('|').map(s => PositionSet.fromString(s));
        return new PositionSets(sets);
    }

    size(): number {
        return this._sets.length;
    }

    isEmpty(): boolean {
        return this._sets.length === 0;
    }

    isEqual(other: PositionSets | undefined): boolean {
        if (other === undefined) return false;
        if (this.size() !== other.size()) return false;

        const thisArray = this.toArray();
        const otherArray = other.toArray();
        if (thisArray.some((s, i) => !s.isEqual(otherArray[i]))) return false;

        return true;
    }

    toString(): string {
        return this._sets.map(s => s.toString()).join('|');
    }

    toArray(): PositionSet[] {
        return this._sets;
    }

    has(pos: Position): boolean {
        return this._sets.some(s => s.has(pos));
    }

    find(pos: Position): PositionSet | undefined {
        return this._sets.find(s => s.has(pos));
    }
}