import React, { MouseEventHandler } from 'react';
import RichText from '../../../components/RichText';
import HintState from '../../model/HintState';
import StatusState, { StatusFlags } from '../../model/StatusState';
import './UIInputStatus.css';

export type UIInputStatusProps = {
    status: StatusState,
    hint: HintState,
    onClick: MouseEventHandler
}

export type UIInputStatusState = {
}

export default class UIInputStatus extends React.PureComponent<UIInputStatusProps, UIInputStatusState> {

    override render(): React.ReactNode {

        const isLoading = this.props.status.hasFlags(StatusFlags.IsLoading);
        const isComplete = this.props.status.hasFlags(StatusFlags.IsComplete);

        const showLoading = isLoading;
        const showNewerRevision = this.props.status.hasFlags(StatusFlags.HasNewerRevision);
        const showCompletedViolations = !isLoading && isComplete && this.props.status.hasFlags(StatusFlags.HasViolations);
        const showCompletedCorrect = !isLoading && isComplete && !this.props.status.hasFlags(StatusFlags.HasViolations);
        const showHint = !isLoading && !isComplete && this.props.hint.hasHint();

        return (
            <div className="UIInputStatus">

                {showLoading ?
                    <div id="InputLoading" className="msg" onClick={this.props.onClick}>
                        Loading...
                    </div> : null}

                {showNewerRevision ?
                    <div id="InputNewerRevisionAvailable" className="msg" onClick={this.props.onClick}>
                        A newer revision of this puzzle is available.
                        Click here to load it.
                    </div> : null}

                {showCompletedViolations ?
                    <div id="AllSquaresFilled" className="msg" onClick={this.props.onClick}>
                        You have completed the puzzle, but some of the digits are incorrect.
                    </div> : null}

                {showCompletedCorrect ?
                    <div id="AllSquaresFilled" className="msg" onClick={this.props.onClick}>
                        Congratulations, you have completed the puzzle!
                    </div> : null}

                {showHint ?
                    <div id="InputHint" className="msg" onClick={this.props.onClick}>
                        <RichText>{this.props.hint.hintText()}</RichText>
                    </div> : null}

            </div>
        );
    }
}