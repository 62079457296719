import React, { HTMLAttributeAnchorTarget } from 'react';
import { Link } from 'react-router-dom';
import UIMenuItem from './UIMenuItem';
import './UIMenuLink.css';

export type UIMenuLinkProps = {
    to: string,
    target?: HTMLAttributeAnchorTarget;
    rel?: string;
    onMenuCollapse: React.MouseEventHandler<HTMLElement>
}

export type UIMenuLinkState = {
}

export default class UIMenuLink extends React.PureComponent<UIMenuLinkProps,UIMenuLinkState> {

    override render(): React.ReactNode {
        return (
            <UIMenuItem onMenuCollapse={this.props.onMenuCollapse}>
                <Link to={this.props.to} target={this.props.target} rel={this.props.rel} tabIndex={2}>
                    {this.props.children}
                </Link>
            </UIMenuItem>
        );
    }
}