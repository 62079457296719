import Position from '../../core/Position';
import PositionLine from '../../core/PositionLine';
import PositionSet from '../../core/PositionSet';
import SudokuState from '../../core/SudokuState';
import ViolationState from '../../core/ViolationState';
import PropertySelection from '../../properties/PropertySelection';
import Constraint from '../Constraint';
import ConstraintDef from '../ConstraintDef';
import LineConstraint from './LineConstraint';

export default class BetweenConstraint extends LineConstraint {

    private static readonly _between = new ConstraintDef('between', 'Between Line', 'Line');

    public static def(): ConstraintDef {
        return this._between;
    }

    public override def(): ConstraintDef {
        return BetweenConstraint.def();
    }

    public toString(): string {
        return this.line().toString();
    }

    public static fromString(str: string): BetweenConstraint {
        return BetweenConstraint.fromLine(PositionLine.fromString(str));
    }

    public static fromLine(line: PositionLine): BetweenConstraint {
        return new BetweenConstraint(line);
    }

    public static fromSelection(sudoku: SudokuState, selection: Position[], propertySelection: [PropertySelection]): SudokuState {
        
        for (const line of PositionLine.fromArrayMany(selection)) {
            sudoku = Constraint.add(sudoku, new BetweenConstraint(line), propertySelection);
        }

        return sudoku;
    }

    public rules(): string {
        return 'Digits along each between line must be inside the range created by the digits in the circle ends.';
    }

    public override validate(sudoku: SudokuState, violations: ViolationState): ViolationState {
        violations = super.validate(sudoku, violations);

        const violatingSquares: Position[] = [];
        const squares = this.line().toArray();
        const head = this.line().start();
        const tail = this.line().end();

        const headDigit = parseInt(sudoku.digit(head) ?? '0');
        const tailDigit = parseInt(sudoku.digit(tail) ?? '0');
        if (!headDigit || !tailDigit) return violations;

        const min = headDigit < tailDigit ? headDigit : tailDigit;
        const max = headDigit > tailDigit ? headDigit : tailDigit;

        for (let i = 1; i < squares.length - 1; i++) {
            const square = squares[i];

            const digit = parseInt(sudoku.digit(square) ?? '0');
            if (!digit) continue;

            if (digit <= min || digit >= max) {
                violatingSquares.push(square);
            }
        }

        if (violatingSquares.length > 0) {
            violatingSquares.push(head);
            violatingSquares.push(tail);

            violations = violations.addViolation(PositionSet.fromArray(violatingSquares));
        }

        return violations;
    }
}