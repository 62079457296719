import React from 'react';
import { GridCellFlags } from './GridCell';
import './GridViolation.css';

export type GridViolationProps = {
    id: string;
    flags: GridCellFlags;
}

export type GridViolationState = {    
}

export default class GridViolation extends React.PureComponent<GridViolationProps,GridViolationState> {

    override render(): React.ReactNode {

        // reduce number of divs...
        if ((this.props.flags & GridCellFlags.IsViolation) === 0) return null;
        
        return <div className="GridViolation content" />;
    }
}