import LZString from 'lz-string';
import SudokuState from "sudokuku-common/src/core/SudokuState";
import SudokuSerializer from "sudokuku-common/src/serialization/SudokuSerializer";
import LocalStorage from "./LocalStorage";

export default class SudokuStorage {

    public static saveSudoku(path: string, sudoku: SudokuState, isCompressed = true): void {

        const json = SudokuSerializer.saveSudoku(sudoku, { maxHistoryDepth: 500 });

        let str = JSON.stringify(json);
        if (isCompressed) {
            str = LZString.compressToUTF16(str);
        }

        SudokuStorage.saveString(path, 'sudoku', str);
    }

    public static loadSudoku(path: string): SudokuState | null {

        try {
            let str = SudokuStorage.loadString(path, 'sudoku');
            if (!str) return null;

            if (str[0] !== '{') str = LZString.decompressFromUTF16(str);
            if (!str) return null;

            const json = JSON.parse(str);
            const sudoku = SudokuSerializer.loadSudoku(json);

            return sudoku;
        }
        catch {
            return null;
        }
    }

    public static saveString(path: string, suffix: string, str: string): void {
        LocalStorage.saveString(SudokuStorage.pathKey(path, suffix), str);
    }

    public static loadString(path: string, suffix: string): string | null {
        return LocalStorage.loadString(SudokuStorage.pathKey(path, suffix));
    }

    private static pathKey(path: string, suffix: string): string {
        return path + '#' + suffix;
    }
}