import classNames from 'classnames';
import React, { MouseEventHandler } from 'react';
import ConfigState from '../../model/ConfigState';
import { InputMode } from '../../model/InputState';
import './UIInputDigits.css';

export type UIInputDigitsProps = {
    mode: InputMode,
    config: ConfigState,
    onClick: MouseEventHandler
}

export type UIInputDigitsState = {
}

export default class UIInputDigits extends React.PureComponent<UIInputDigitsProps, UIInputDigitsState> {

    override render(): React.ReactNode {

        const className = classNames({
            'UIInputDigits': true,
            'digit': this.props.mode === InputMode.Digits,
            'centerMark': this.props.mode === InputMode.CenterMarks,
            'cornerMark': this.props.mode === InputMode.CornerMarks,
            'colorMark': this.props.mode === InputMode.ColorMarks
        });

        const keypad = this.props.config.keypad();
        const digits = (keypad === 'numpad') ? [[7, 4, 1], [8, 5, 2], [9, 6, 3]] : [[1, 4, 7], [2, 5, 8], [3, 6, 9]];

        return (
            <div className={className}>

                {digits.map((column, key) =>
                    <div key={key} className="UIInputDigitsCol">
                        {column.map(digit =>
                            <button key={digit} id={`Input${digit}`} className={`digit m${digit}`} onClick={this.props.onClick}>
                                {digit}
                            </button>
                        )}
                    </div>
                )}

                <div className="UIInputDigitsCol">

                    <button id="InputUndo" onClick={this.props.onClick}>
                        <svg width="2em" height="2em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path clipRule="evenodd" d="M5 3C5.55228 3 6 3.44772 6 4V6.70615C8.46639 3.90478 12.6435 3.13391 16 5.0718C19.8263 7.28094 21.1373 12.1737 18.9282 16C16.7191 19.8263 11.8263 21.1373 8 18.9282C7.52171 18.6521 7.35783 18.0405 7.63397 17.5622C7.91012 17.0839 8.52171 16.92 9 17.1962C11.8698 18.853 15.5393 17.8698 17.1962 15C18.853 12.1302 17.8698 8.4607 15 6.80385C12.4945 5.35729 9.37931 5.92318 7.52624 8H10C10.5523 8 11 8.44772 11 9C11 9.55228 10.5523 10 10 10H5C4.44772 10 4 9.55228 4 9V4C4 3.44772 4.44772 3 5 3Z" fill="black" fillRule="evenodd" />
                        </svg>
                    </button>

                    <button id="InputRedo" onClick={this.props.onClick}>
                        <svg width="2em" height="2em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path clipRule="evenodd" d="M19 3C19.5523 3 20 3.44772 20 4V9C20 9.55228 19.5523 10 19 10H14C13.4477 10 13 9.55228 13 9C13 8.44772 13.4477 8 14 8H16.4738C14.6207 5.92318 11.5055 5.35728 8.99999 6.80385C6.13024 8.4607 5.14699 12.1302 6.80384 15C8.4607 17.8698 12.1302 18.853 15 17.1962C15.4783 16.92 16.0899 17.0839 16.366 17.5622C16.6422 18.0405 16.4783 18.6521 16 18.9282C12.1737 21.1373 7.28093 19.8263 5.07179 16C2.86265 12.1737 4.17365 7.28093 7.99999 5.07179C11.3565 3.13391 15.5336 3.90478 18 6.70614V4C18 3.44772 18.4477 3 19 3Z" fill="black" fillRule="evenodd" />
                        </svg>
                    </button>

                    <button id="InputClear" onClick={this.props.onClick}>
                        <svg width="2em" height="2em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path clipRule="evenodd" d="M18.7071 5.29289C19.0976 5.68341 19.0976 6.31658 18.7071 6.7071L13.4142 12L18.7071 17.2929C19.0976 17.6834 19.0976 18.3166 18.7071 18.7071C18.3166 19.0976 17.6834 19.0976 17.2929 18.7071L12 13.4142L6.70711 18.7071C6.31658 19.0976 5.68342 19.0976 5.29289 18.7071C4.90237 18.3166 4.90237 17.6834 5.29289 17.2929L10.5858 12L5.2929 6.70711C4.90238 6.31658 4.90238 5.68342 5.2929 5.29289C5.68342 4.90237 6.31659 4.90237 6.70711 5.29289L12 10.5858L17.2929 5.29289C17.6834 4.90237 18.3166 4.90237 18.7071 5.29289Z" fill="black" fillRule="evenodd" />
                        </svg>
                    </button>

                </div>

            </div>
        );
    }
}