import DigitSet from '../../core/DigitSet';
import PositionSet from '../../core/PositionSet';
import SudokuState from '../../core/SudokuState';
import ViolationState from '../../core/ViolationState';
import Constraint from '../Constraint';
import ConstraintDef from '../ConstraintDef';

export default class CloneConstraint extends Constraint {

    private readonly _squares: PositionSet;

    private static readonly _clone = new ConstraintDef('clone', 'Clone', 'Local');

    protected constructor(squares: PositionSet) {
        super();

        this._squares = squares;
    }

    public static def(): ConstraintDef {
        return this._clone;
    }

    public override def(): ConstraintDef {
        return CloneConstraint.def();
    }

    public longName(): string {
        return `${this.name()} ${this._squares.toString()}`;
    }

    public rules(): string {
        return 'Shaded regions are clones. Digits that appear in one part of a clone must appear in exactly the same position in the other part.';
    }

    public override squares(): PositionSet {
        return this._squares;
    }

    public override isUnique(): boolean {
        return false;
    }

    public toString(): string {
        return this.squares().toString();
    }

    public static fromString(str: string): CloneConstraint {
        return CloneConstraint.fromSquares(PositionSet.fromString(str));
    }

    static fromSquares(squares: PositionSet): CloneConstraint {
        return new CloneConstraint(squares);
    }

    public override validate(sudoku: SudokuState, violations: ViolationState): ViolationState {

        const digits = DigitSet.fromString(sudoku.digits(this.squares()).join(''));
        if (digits.size() <= 1) return violations;

        return violations.addViolation(this.squares());
    }
}