import It from "sudokuku-common/src/core/It";
import SudokuConstraints from "sudokuku-common/src/core/SudokuConstraints";
import SearchQuery from "./SearchQuery";

export default class SearchForm {

    private readonly _text: string;
    private readonly _constraints: Set<string>;

    private constructor(text: string, constraints: Set<string>) {
        this._text = text;
        this._constraints = constraints;
    }

    public text(): string {
        return this._text;
    }

    public setText(text: string): SearchForm {
        return new SearchForm(text, this._constraints);
    }

    public isConstraintChecked(constraint: string): boolean {
        return this._constraints.has(constraint);
    }

    public checkConstraint(constraint: string, checked: boolean): SearchForm {
        if (checked) {
            this._constraints.add(constraint);
        }
        else {
            this._constraints.delete(constraint);
        }

        return this;
    }

    public toQuery(): SearchQuery {
        let query = this._text;

        for (const constraint of this._constraints) {
            if (query.length) query += ' ';
            query += SearchForm.constraintQuery(constraint);
        }

        return SearchQuery.fromQuery(query);
    }

    static fromEmpty(): SearchForm {
        return new SearchForm('', new Set());
    }

    public static fromQuery(query: SearchQuery): SearchForm {
        let text = query.query();
        const set = new Set<string>();

        const constraints = It(SudokuConstraints.defs())
            .filter(d => !d.isHidden() && !d.isStandard())
            .map(d => d.name())
            .toArray();

        for (const constraint of constraints) {
            const constraintQuery = SearchForm.constraintQuery(constraint);
            const prevText = text;

            text = text.replace(' ' + constraintQuery, '');
            text = text.replace(constraintQuery, '');

            if (text !== prevText) set.add(constraint);
        }

        return new SearchForm(text, set);
    }

    private static constraintQuery(constraint: string): string {
        return `"${constraint}"`;
    }
}