
export interface ReadonlyMultiMap<K, V> extends ReadonlyMap<K, V[]> {
    flatEntries(): IterableIterator<[K, V]>;
}

export default class MultiMap<K, V> extends Map<K, V[]> {

    public *flatEntries(): IterableIterator<[K, V]> {
        for (const [k, va] of this.entries()) {
            for (const v of va) {
                yield [k, v];
            }
        }
    }

    public add(key: K, value: V): MultiMap<K, V> {
        const array = this.get(key);
        if (array) {
            array.push(value);
        }
        else {
            this.set(key, [value]);
        }

        return this;
    }

    public addMany(key: K, value: Iterable<V>): MultiMap<K, V> {
        const array = this.get(key);
        if (array) {
            array.push(...value);
        }
        else {
            this.set(key, [...value]);
        }

        return this;
    }

    public static groupBy<K, V>(iterable: Iterable<V>,
        keyFunc: (v: V, i: number, m: MultiMap<K, V>) => K | undefined): MultiMap<K, V> {

        const map = new MultiMap<K, V>();
        let index = -1;

        for (const val of iterable) {
            index++;

            const key = keyFunc(val, index, map);
            if (key === undefined) continue;

            map.add(key, val);
        }

        return map;
    }

    public static groupByMap<K, AV, MV>(iterable: Iterable<AV>,
        keyFunc: (v: AV, i: number, m: MultiMap<K, MV>) => K | undefined,
        valueFunc: (v: AV, i: number, m: MultiMap<K, MV>) => MV | undefined): MultiMap<K, MV> {

        const map = new MultiMap<K, MV>();
        let index = -1;

        for (const av of iterable) {
            index++;

            const key = keyFunc(av, index, map);
            if (key === undefined) continue;

            const mv = valueFunc(av, index, map);
            if (mv === undefined) continue;

            map.add(key, mv);
        }

        return map;
    }

    public static groupByMapArray<K, AV, MV>(iterable: Iterable<AV>,
        keyFunc: (v: AV, i: number, m: MultiMap<K, MV>) => K | undefined,
        valueFunc: (v: AV, i: number, m: MultiMap<K, MV>) => MV[] | undefined): MultiMap<K, MV> {

        const map = new MultiMap<K, MV>();
        let index = -1;

        for (const av of iterable) {
            index++;

            const key = keyFunc(av, index, map);
            if (key === undefined) continue;

            const mv = valueFunc(av, index, map);
            if (mv === undefined) continue;

            mv.forEach(v => map.add(key as K, v));
        }

        return map;
    }

    public static groupByArrayMap<K, AV, MV>(iterable: Iterable<AV>,
        keyFunc: (v: AV, i: number, m: MultiMap<K, MV>) => K[] | undefined,
        valueFunc: (v: AV, i: number, m: MultiMap<K, MV>) => MV | undefined): MultiMap<K, MV> {

        const map = new MultiMap<K, MV>();
        let index = -1;

        for (const av of iterable) {
            index++;

            const keys = keyFunc(av, index, map);
            if (keys === undefined) continue;

            const value = valueFunc(av, index, map);
            if (value === undefined) continue;

            keys.forEach(k => map.add(k, value));
        }

        return map;
    }
}