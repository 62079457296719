import PositionSet from '../../core/PositionSet';
import ConstraintDef from '../ConstraintDef';
import GlobalConstraint from './GlobalConstraint';

export default class KnightConstraint extends GlobalConstraint {

    private static readonly _knight = new ConstraintDef('knight', 'Chess Knight', 'Global', ['Chess Knight', 'Anti-Knight']);

    private static readonly _offsets: [number, number][] = [
        [+0, +0],
        [-2, -1], [-2, +1],
        [+2, -1], [+2, +1],
        [-1, -2], [-1, +2],
        [+1, -2], [+1, +2]
    ];

    private constructor(squares: PositionSet) {
        super(squares);
    }

    public static def(): ConstraintDef {
        return this._knight;
    }

    public override def(): ConstraintDef {
        return KnightConstraint.def();
    }

    override isUniqueCandidates(): boolean {
        return true;
    }

    public rules(): string {
        return 'Cells separated by a chess knight\'s move cannot contain the same digit.'
    }

    public toString(): string {
        return this.squares().toString();
    }

    public static fromString(str: string): KnightConstraint {
        return KnightConstraint.fromSquares(PositionSet.fromString(str));
    }

    public static fromSquares(squares: PositionSet): KnightConstraint {
        return new KnightConstraint(squares);
    }

    public static fromDefault(): KnightConstraint {
        return new KnightConstraint(PositionSet.fromEmpty());
    }

    protected override candidateOffsets(): [number, number][] {
        return KnightConstraint._offsets;
    }
}