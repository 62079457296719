
export enum InputMode {
    Digits,
    CornerMarks,
    CenterMarks,
    ColorMarks,
    NumInputModes // must be last
}

export default class InputState {

    private readonly _mode: InputMode;

    private constructor(mode: InputMode) {
        this._mode = mode;
    }

    static fromDefault(): InputState {
        return new InputState(InputMode.Digits);
    }

    mode(): InputMode {
        return this._mode;
    }

    setMode(mode: InputMode): InputState {
        return new InputState(mode);
    }

    nextMode(): InputState {
        return new InputState((this._mode + 1) % InputMode.NumInputModes);
    }

    prevMode(): InputState {
        return new InputState((this._mode + InputMode.NumInputModes - 1) % InputMode.NumInputModes);
    }
}