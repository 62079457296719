import PositionSet from '../../core/PositionSet';
import ConstraintDef from '../ConstraintDef';
import DiffConstraint from '../domino/DiffConstraint';
import NegativeConstraint from './NegativeConstraint';

export default class DiffNegativeConstraint extends NegativeConstraint {

    private static readonly _diffNeg = new ConstraintDef('diff-', 'Diff (Negative)', 'Global', ['Diff', 'Difference', 'Consecutive', 'Negative']);

    private constructor(squares: PositionSet) {
        super(squares, DiffConstraint, DiffConstraint.fromSquares(squares));
    }

    public static def(): ConstraintDef {
        return this._diffNeg;
    }

    public override def(): ConstraintDef {
        return DiffNegativeConstraint.def();
    }

    public rules(): string {
        return 'Adjacent cells cannot contain consecutive digits (unless explicitly allowed by a dot).';
    }

    public static fromString(str: string): DiffNegativeConstraint {
        return DiffNegativeConstraint.fromSquares(PositionSet.fromString(str));
    }

    public static fromSquares(squares: PositionSet): DiffNegativeConstraint {
        return new DiffNegativeConstraint(squares);
    }

    public static fromDefault(): DiffNegativeConstraint {
        return new DiffNegativeConstraint(PositionSet.fromEmpty());
    }
}