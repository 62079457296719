
export enum ConstraintDefFlags {
    None = 0,
    IsStandard = 1, // eg: row, column, box
    IsHidden = 2,   // eg: hiddenKiller
}

export default class ConstraintDef {

    private readonly _kind: string;
    private readonly _name: string;
    private readonly _category: string;
    private readonly _keywords: readonly string[];
    private readonly _order: number;
    private readonly _flags: ConstraintDefFlags;

    public constructor(kind: string, name: string, category: string, keywords: readonly string[] = [name], order = 0, flags = ConstraintDefFlags.None) {
        this._kind = kind;
        this._name = name;
        this._category = category;
        this._keywords = keywords;
        this._order = order;
        this._flags = flags;
    }

    public kind(): string {
        return this._kind;
    }

    public name(): string {
        return this._name;
    }

    public category(): string {
        return this._category;
    }

    public keywords(): readonly string[] {
        return this._keywords;
    }

    public order(): number {
        return this._order;
    }

    public isStandard(): boolean {
        return (this._flags & ConstraintDefFlags.IsStandard) !== 0;
    }

    public isHidden(): boolean {
        return (this._flags & ConstraintDefFlags.IsHidden) !== 0;
    }
}
