import Position from './Position';
import PositionSet from './PositionSet';
import SudokuError from './SudokuError';

export default class PositionLine {

    // directions:
    //  123
    //  4 6
    //  789
    private static readonly _offsets = [
        [-1, -1], [-1, 0], [-1, +1],
        [+0, -1], [+0, 0], [+0, +1],
        [+1, -1], [+1, 0], [+1, +1]
    ];

    public static readonly UpLeft = '1';
    public static readonly Up = '2';
    public static readonly UpRight = '3';
    public static readonly Left = '4';
    public static readonly Stay = '5';
    public static readonly Right = '6';
    public static readonly DownLeft = '7';
    public static readonly Down = '8';
    public static readonly DownRight = '9';

    private readonly _directions: string;
    private readonly _array: Position[];
    private readonly _squares: PositionSet;

    private constructor(start: Position, directions: string) {
        this._directions = directions;
        this._array = PositionLine.findArray(start, directions);
        this._squares = PositionSet.fromArray(this._array);
    }

    public start(): Position {
        return this._array[0];
    }

    public end(): Position {
        return this._array[this._array.length - 1];
    }

    public directions(): string {
        return this._directions;
    }

    public squares(): PositionSet {
        return this._squares;
    }

    public size(): number {
        return this._array.length;
    }

    public isLoop(): boolean {
        return this.start().isEqual(this.end());
    }

    public isOverlapping(): boolean {
        const array = this.toArray().map(p => p.toString());
        return array.length !== new Set(array).size;
    }

    public reverse(): PositionLine {
        return PositionLine.fromArray(this.toArray().reverse());
    }

    public static fromPos(pos: Position, directions = ''): PositionLine {
        return new PositionLine(pos, directions);
    }

    public static fromArray(array: Position[]): PositionLine {

        const lines = this.fromArrayMany(array);
        if (lines.length < 1) throw new SudokuError('Empty line');
        if (lines.length > 1) throw new SudokuError(`Disconnected positions in line`);

        return lines[0];
    }

    public static fromArrayMany(array: Position[]): PositionLine[] {

        if (!array.length) return [];

        const lines: PositionLine[] = [];
        let prev = array[0];
        let startIndex = 0;
        let directions = '';

        for (let index = 1; index < array.length; index++) {
            const next = array[index];

            const direction = this.findDirection(prev, next);
            if (!direction) {
                lines.push(new PositionLine(array[startIndex], directions));
                startIndex = index;
                directions = '';
            }
            else if (direction !== this.Stay) {
                directions += direction;
            }

            prev = next;
        }

        lines.push(new PositionLine(array[startIndex], directions));

        return lines;
    }

    public static fromString(str: string): PositionLine {
        const [start, directions] = str.split(':');
        return new PositionLine(Position.fromString(start), directions);
    }

    public toArray(): Position[] {
        return this._array;
    }

    public toString(): string {
        return this._directions ? this.start().toString() + ':' + this._directions : this.start().toString();
    }

    private static findPosition(prev: Position, direction: string): Position | null {
        const index = parseInt(direction) - 1;
        const offset = this._offsets[index];
        return !offset ? null : prev.offset(offset[0], offset[1]);
    }

    private static findDirection(prev: Position, next: Position): string | null {
        const index = this._offsets.findIndex(o => next.isEqual(prev.offset(o[0], o[1])));
        return (index < 0) ? null : (index + 1).toString();
    }

    private static findArray(start: Position, directions: string): Position[] {
        let pos = start;
        const squares = [pos];

        for (const direction of directions) {
            pos = PositionLine.findPosition(pos, direction) || pos;
            squares.push(pos);
        }

        return squares;
    }
}