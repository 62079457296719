import { ConstraintProps } from "sudokuku-common/src/constraints/ConstraintReg";
import SumConstraint from "sudokuku-common/src/constraints/domino/SumConstraint";
import './SumConstraintCell.css';

export default function SumConstraintCell(props: ConstraintProps): React.ReactElement | null {

    const constraint = props.constraint as SumConstraint;

    const hasTopSquare = constraint.topSquare(props.pos) !== null;
    const hasLeftSquare = constraint.leftSquare(props.pos) !== null;
    if (!hasTopSquare && !hasLeftSquare) return null;

    return (
        <div className="SumConstraint content">
            {hasTopSquare ? <div className="dot t">{constraint.sum()}</div> : null}
            {hasLeftSquare ? <div className="dot l">{constraint.sum()}</div> : null}
        </div>);
}