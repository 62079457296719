import classNames from "classnames";
import React from "react";
import { ConstraintProps } from "sudokuku-common/src/constraints/ConstraintReg";
import ThermoConstraint from "sudokuku-common/src/constraints/line/ThermoConstraint";
import './ThermoConstraintCell.css';

export default function ThermoConstraintCell(props: ConstraintProps): React.ReactElement| null {

    const constraint = props.constraint as ThermoConstraint;

    const [iDir, oDir] = constraint.cellDirections(props.pos);
    const bulb = constraint.isStart(props.pos);
    if (!iDir && !oDir && !bulb) return null;

    const iClassName = classNames(['ThermoConstraint', 'content', 'line', 'd' + iDir]);
    const oClassName = classNames(['ThermoConstraint', 'content', 'line', 'd' + oDir]);
    const bClassName = classNames(['ThermoConstraint', 'content', 'bulb']);

    return (
        <React.Fragment>
            {iDir ? <div className={iClassName} /> : null}
            {oDir ? <div className={oClassName} /> : null}
            {bulb ? <div className={bClassName} /> : null}
        </React.Fragment>);
}