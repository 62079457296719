export default class DigitSet {

    private static readonly _emptyBits = 0;
    private static readonly _fullBits = 0x3FE;

    private readonly _bits: number;

    private constructor(bits: number) {
        this._bits = bits;
    }

    public static fromAny(digits?: DigitSet | string | number): DigitSet {
        if (digits instanceof DigitSet) return digits;
        if (typeof digits === "string") return DigitSet.fromString(digits);
        if (typeof digits === "number") return DigitSet.fromDigit(digits);
        return DigitSet.fromEmpty();
    }

    public static fromEmpty(): DigitSet {
        return new DigitSet(this._emptyBits);
    }

    public static fromFull(): DigitSet {
        return new DigitSet(this._fullBits);
    }

    public static fromDigit(digit: number): DigitSet {
        return new DigitSet((digit >= 1 && digit <= 9 && Number.isInteger(digit)) ? (1 << digit) : 0);
    }

    public static fromString(digits: string): DigitSet {
        let bits = 0;
        const len = digits.length;
        const zero = '0'.charCodeAt(0);

        for (let i = 0; i < len; i++) {
            const digit = digits.charCodeAt(i) - zero;
            if (digit >= 1 && digit <= 9) bits = bits | (1 << digit);
        }

        return new DigitSet(bits);
    }

    static fromArray(digits: number[]): DigitSet {
        return digits.reduce((a, d) => a.unionWith(DigitSet.fromDigit(d)), DigitSet.fromEmpty());
    }

    static fromArrays(digits: DigitSet[]): DigitSet {
        return digits.reduce((a, d) => a.unionWith(d), DigitSet.fromEmpty());
    }

    static fromBits(bits: number): DigitSet {
        return new DigitSet(bits & this._fullBits);
    }

    public firstDigit(): DigitSet {
        return new DigitSet(this._bits & ~(this._bits - 1));
    }

    public firstValue(): number {

        if (this._bits) {
            for (let digit = 1; digit <= 9; digit++) {
                const mask = 1 << digit;

                if ((this._bits & mask) !== 0) {
                    return digit;
                }
            }
        }

        return 0;
    }

    public all(): DigitSet[] {
        const digits: DigitSet[] = [];

        let remaining = this as DigitSet;

        while (!remaining.isEmpty()) {

            const digit = remaining.firstDigit();
            remaining = remaining.exceptWith(digit);

            digits.push(digit);
        }

        return digits;
    }

    public digitAt(index: number): DigitSet {
        let bits = this._bits;

        for (let i = 0; i < index; i++) {
            bits = bits & bits - 1;
        }

        return new DigitSet(bits & ~(bits - 1));
    }

    public isEmpty(): boolean {
        return this._bits === 0;
    }

    public isFull(): boolean {
        return this._bits === DigitSet._fullBits;
    }

    public has(digit: number): boolean {
        return (digit >= 1 && digit <= 9) ? (this._bits & (1 << digit)) !== 0 : false;
    }

    public hasAll(digits: DigitSet): boolean {
        return (this._bits & digits._bits) === digits._bits;
    }

    public hasAny(digits: DigitSet): boolean {
        return (this._bits & digits._bits) !== 0;
    }

    public size(): number {
        let n = this._bits;

        // https://graphics.stanford.edu/~seander/bithacks.html
        n = n - ((n >> 1) & 0x55555555);
        n = (n & 0x33333333) + ((n >> 2) & 0x33333333);
        n = ((n + (n >> 4) & 0xF0F0F0F) * 0x1010101) >> 24;

        return n;
    }

    public isEqual(other: DigitSet | number): boolean {
        if (typeof other === 'number') other = DigitSet.fromDigit(other);

        return this._bits === other._bits;
    }

    public unionWith(digits: DigitSet): DigitSet {
        return new DigitSet(this._bits | digits._bits);
    }

    public exceptWith(digits: DigitSet): DigitSet {
        return new DigitSet(this._bits & ~digits._bits);
    }

    public intersectWith(digits: DigitSet): DigitSet {
        return new DigitSet(this._bits & digits._bits);
    }

    public disjunctiveUnionWith(digits: DigitSet): DigitSet {
        return new DigitSet(this._bits ^ digits._bits);
    }

    public invert(): DigitSet {
        return new DigitSet(this._bits ^ DigitSet._fullBits);
    }

    public fillHigh(): DigitSet {
        let bits = 0;
        let digit = 1;

        // search for first bit set
        for (; digit <= 9; digit++) {
            if (this.has(digit)) break;
        }

        // set remaining bits
        for (; digit <= 9; digit++) {
            bits = bits | (1 << digit);
        }

        return new DigitSet(bits);
    }

    public fillLow(): DigitSet {
        let bits = 0;
        let digit = 9;

        // search for last bit set
        for (; digit >= 0; digit--) {
            if (this.has(digit)) break;
        }

        // set remaining bits
        for (; digit >= 0; digit--) {
            bits = bits | (1 << digit);
        }

        return new DigitSet(bits);
    }

    public increase(count: number): DigitSet {
        return new DigitSet((this._bits << count) & DigitSet._fullBits);
    }

    public decrease(count: number): DigitSet {
        return new DigitSet((this._bits >> count) & DigitSet._fullBits);
    }

    public permute(degree: number): DigitSet[] {
        if (degree < 1) return [];

        const size = this.size();
        if (size < degree) return [];
        if (size === degree) return [this];

        const permutations: DigitSet[] = [];
        let remaining = this as DigitSet;

        while (!remaining.isEmpty()) {
            const first = remaining.firstDigit();
            remaining = remaining.exceptWith(first);

            if (degree === 1) {
                permutations.push(first);
            }
            else {
                for (const p of remaining.permute(degree - 1)) {
                    permutations.push(p.unionWith(first));
                }
            }
        }

        return permutations;
    }

    public toString(): string {
        let str = '';

        for (let digit = 1; digit <= 9; digit++) {

            const mask = 1 << digit;

            if ((this._bits & mask) !== 0) {
                str += digit.toString();
            }
        }

        return str;
    }

    public toArray(): number[] {
        const array: number[] = [];

        for (let digit = 1; digit <= 9; digit++) {

            const mask = 1 << digit;

            if ((this._bits & mask) !== 0) {
                array.push(digit);
            }
        }

        return array;
    }

    public toBits(): number {
        return this._bits;
    }
}