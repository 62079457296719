import classNames from "classnames";
import { ConstraintProps } from "sudokuku-common/src/constraints/ConstraintReg";
import BoxConstraint from "sudokuku-common/src/constraints/unique/BoxConstraint";
import './BoxConstraintCell.css';

export default function BoxConstraintCell(props: ConstraintProps): React.ReactElement | null {

    const constraint = props.constraint as BoxConstraint;
    const squares = constraint.squares();

    const isTop = squares.isTop(props.pos);
    const isRight = squares.isRight(props.pos);
    const isBottom = squares.isBottom(props.pos);
    const isLeft = squares.isLeft(props.pos);
    if (!isTop && !isRight && !isBottom && !isLeft) return null;

    const className = classNames({
        'BoxConstraint': true,
        'content': true,
        't': isTop,
        'r': isRight,
        'b': isBottom,
        'l': isLeft
    });

    return <div className={className} />;
}