/* eslint-disable @typescript-eslint/no-unused-vars */
import Position from '../core/Position';
import PositionSet from '../core/PositionSet';
import PositionSets from '../core/PositionSets';
import SudokuState from '../core/SudokuState';
import ViolationState from '../core/ViolationState';
import Property, { PropertyContext, PropertyFlags } from '../properties/Property';
import PropertyObject from '../properties/PropertyObject';
import PropertySelection from '../properties/PropertySelection';
import ConstraintDef from './ConstraintDef';
import ConstraintReg from './ConstraintReg';

export default abstract class Constraint {

    private readonly _key: string;
    private readonly _propertyObject: PropertyObject;

    protected constructor(prev?: Constraint) {
        this._key = ConstraintReg.newInstanceKey();
        this._propertyObject = new PropertyObject(prev?.propertyObject(), this, this.properties());
    }

    public key(): string {
        return this._key;
    }

    public abstract toString(): string;

    public abstract def(): ConstraintDef;

    public abstract squares(): PositionSet;

    public abstract isUnique(): boolean;

    public abstract longName(): string;

    public abstract rules(constraints: Constraint[]): string;

    public isComplete(): boolean {
        return this.squares().size() === 9;
    }

    public isUniqueComplete(): boolean {
        return this.isUnique() && this.isComplete();
    }

    public rulesExample(sudoku: SudokuState, square: Position): PositionSet {

        let constraint = Constraint.findConstraint(sudoku, this.kind(), square);
        if (!constraint) constraint = this;

        return constraint.squares();
    }

    public kind(): string {
        return this.def().kind();
    }

    public name(): string {
        return this.def().name();
    }

    public category(): string {
        return this.def().category();
    }

    public keywords(): readonly string[] {
        return this.def().keywords();
    }

    public order(): number {
        return this.def().order();
    }

    public visible(): PositionSet {
        return this.squares();
    }

    public validate(sudoku: SudokuState, violations: ViolationState): ViolationState {
        return violations;
    }

    public propertyObject(): PropertyObject {
        return this._propertyObject;
    }

    public static findDuplicates(sudoku: SudokuState, squares: PositionSet): PositionSets {
        const sets: PositionSet[] = [];

        for (const square of squares.toArray()) {
            const digit = sudoku.digit(square);
            if (!digit) continue;

            const dupeSquares = Constraint.findDigits(sudoku, digit, squares);
            if (dupeSquares.size() < 2) continue;

            sets.push(dupeSquares);
        }

        return PositionSets.fromArray(sets);
    }

    public static findDigits(sudoku: SudokuState, digit: string, squares: PositionSet): PositionSet {
        return squares.filter(s => sudoku.digit(s) === digit);
    }

    public static findConstraint(sudoku: SudokuState, kind: string, square: Position): Constraint | undefined {

        const constraints = sudoku.def().constraints();
        const constraint = constraints.in(square).find(c => (c.kind() === kind));

        return constraint;
    }

    public static squares(constraints: Constraint[]): PositionSet {
        return PositionSet.fromArrays(constraints.map(c => c.squares()));
    }

    public static add(sudoku: SudokuState, constraint: Constraint, propertySelection: [PropertySelection]): SudokuState {
        const oldDef = sudoku.def();
        const oldConstraints = oldDef.constraints();
        const newConstraints = oldConstraints.add(constraint);
        const newDef = oldDef.setConstraints(newConstraints);
        const newSudoku = sudoku.setDef(newDef);

        propertySelection[0] = propertySelection[0].setSelection(constraint.propertyObject());

        return newSudoku;
    }

    public static replace(ctx: PropertyContext, prop: Property, newConstraint: Constraint): void {
        const oldConstraint = prop.data() as Constraint;
        const oldSudoku = ctx.state as SudokuState;
        const oldDef = oldSudoku.def();

        const newConstraints = oldDef.constraints().replace(oldConstraint, newConstraint);
        const newDef = oldDef.setConstraints(newConstraints);
        const newSudoku = oldSudoku.setDef(newDef);

        ctx.state = newSudoku;
    }

    public static remove(ctx: PropertyContext, prop: Property): void {
        const oldConstraint = prop.data() as Constraint;
        const oldSudoku = ctx.state as SudokuState;
        const oldDef = oldSudoku.def();

        const newConstraints = oldDef.constraints().remove(oldConstraint);
        const newDef = oldDef.setConstraints(newConstraints);
        const newSudoku = oldSudoku.setDef(newDef);

        ctx.state = newSudoku;
    }

    private longSuffix(): string {
        const name = this.name();
        const longName = this.longName();

        return longName.startsWith(name) ? longName.substring(name.length + 1) : longName;
    }

    private properties(): Property[] {
        const properties: Property[] = [];

        // constraint properties
        this.getProperties(properties);

        // delete is last
        if (!this.def().isStandard()) {
            properties.push(new Property('Delete', this.name(), 'button', PropertyFlags.None, this, () => 'X', (c, p) => Constraint.remove(c, p)));
        }

        // done
        return properties;
    }

    protected getProperties(properties: Property[]): void {
        // overridden
    }
}