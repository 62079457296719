import Position from '../../core/Position';
import PositionLine from '../../core/PositionLine';
import PositionSet from '../../core/PositionSet';
import Constraint from '../Constraint';

export default abstract class LineConstraint extends Constraint {

    private _line: PositionLine;
    private _map: Map<string, number>;

    protected constructor(line: PositionLine) {
        super();

        this._line = line;
        this._map = new Map(line.toArray().map((s, i) => [s.toString(), i]));
    }

    public line(): PositionLine {
        return this._line;
    }

    public squares(): PositionSet {
        return this._line.squares();
    }

    public isUnique(): boolean {
        return false;
    }

    public isStart(square: Position): boolean {
        return this._line.start().isEqual(square);
    }

    public isEnd(square: Position): boolean {
        return this._line.end().isEqual(square);
    }

    public longName(): string {
        return `${this.name()} ${this.line().start().toString()}-${this.line().end().toString()}`;
    }

    public cellDirections(square: Position): [string, string] {
        const directions = this._line.directions();

        const index = this._map.get(square.toString());
        if (index === undefined) return ['', ''];
        if (index === 0) return ['', directions[index]];
        if (index === directions.length) return [this.reverse(directions[index - 1]), ''];

        return [this.reverse(directions[index - 1]), directions[index]];
    }

    private reverse(direction: string): string {
        switch (direction) {
            case PositionLine.UpLeft: return PositionLine.DownRight;
            case PositionLine.Up: return PositionLine.Down;
            case PositionLine.UpRight: return PositionLine.DownLeft;
            case PositionLine.Left: return PositionLine.Right;
            case PositionLine.Right: return PositionLine.Left;
            case PositionLine.DownRight: return PositionLine.UpLeft;
            case PositionLine.Down: return PositionLine.Up;
            case PositionLine.DownLeft: return PositionLine.UpRight;
            default: return '';
        }
    }
}