import DigitSet from '../../core/DigitSet';
import Position from '../../core/Position';
import PositionSet from '../../core/PositionSet';
import SudokuState from '../../core/SudokuState';
import Property, { PropertyFlags } from '../../properties/Property';
import PropertySelection from '../../properties/PropertySelection';
import Constraint from '../Constraint';
import ConstraintDef from '../ConstraintDef';
import RatioNegativeConstraint from '../global/RatioNegativeConstraint';
import DominoConstraint from './DominoConstraint';

export default class RatioConstraint extends DominoConstraint {

    private static readonly _ratio = new ConstraintDef('ratio', 'Kropki Ratio', 'Domino');

    protected constructor(squares: PositionSet) {
        super(squares, d => RatioConstraint.getValidDigits(d, 2));
    }

    public static def(): ConstraintDef {
        return this._ratio;
    }

    public override def(): ConstraintDef {
        return RatioConstraint.def();
    }

    public rules(constraints: Constraint[]): string {
        const negative = constraints.find(c => c instanceof RatioNegativeConstraint);
        const negativeRule = negative ? '' : ' Not all black dots are given.';

        return 'Cells separated by a black dot contain digits with a ratio of 1:2.' + negativeRule;
    }

    public toString(): string {
        return this.squares().toString();
    }

    public static fromString(str: string): RatioConstraint {
        return RatioConstraint.fromSquares(PositionSet.fromString(str));
    }

    public static fromSquares(squares: PositionSet): RatioConstraint {
        return new RatioConstraint(squares);
    }

    public static fromSelection(sudoku: SudokuState, selection: Position[], propertySelection: [PropertySelection]): SudokuState {
        
        const squares = PositionSet.fromArray(selection);

        return Constraint.add(sudoku, new RatioConstraint(squares), propertySelection);
    }

    private static getValidDigits(digits: DigitSet, ratio: number): DigitSet {
        let result = DigitSet.fromEmpty();

        for (let digit = 1; digit <= 9; digit++) {
            if (!digits.has(digit)) continue;

            const div = digit / ratio;
            result = result.unionWith(DigitSet.fromDigit(div));

            const mul = digit * ratio;
            result = result.unionWith(DigitSet.fromDigit(mul));
        }

        return result;
    }

    protected override getProperties(properties: Property[]): void {
        properties.push(new Property('Ratio', this.name(), 'text', PropertyFlags.IsReadOnly, this, () => '2'));
    }
}