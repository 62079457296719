import Position from '../../core/Position';
import PositionSet from '../../core/PositionSet';
import SudokuState from '../../core/SudokuState';
import ViolationState from '../../core/ViolationState';
import Property, { PropertyContext, PropertyFlags, PropertyValue } from '../../properties/Property';
import Constraint from '../Constraint';
import ConstraintDef from '../ConstraintDef';

export default class LittleKillerConstraint extends Constraint {

    private static readonly _littleKiller = new ConstraintDef('littleKiller', 'Little Killer', 'Killer');

    private readonly _squares: PositionSet;
    private readonly _pos: Position;
    private readonly _dir: string;
    private readonly _sum: string;

    protected constructor(squares: PositionSet, pos: Position, dir: string, sum: string, prev?: LittleKillerConstraint) {
        super(prev);

        this._squares = squares;
        this._pos = pos;
        this._dir = dir;
        this._sum = sum;
    }

    public static def(): ConstraintDef {
        return this._littleKiller;
    }

    public override def(): ConstraintDef {
        return LittleKillerConstraint.def();
    }

    public longName(): string {
        return `${this.name()} ${this.pos().toString()}`;
    }

    public rules(): string {
        return 'Numbers outside the grid indicate the sum of the digits on the indicated diagonal.';
    }

    public override isUnique(): boolean {
        return false;
    }

    public override squares(): PositionSet {
        return this._squares;
    }

    public override visible(): PositionSet {
        return PositionSet.fromPos(this._pos);
    }

    public pos(): Position {
        return this._pos;
    }

    public dir(): string {
        return this._dir;
    }

    public sum(): string {
        return this._sum;
    }

    public sumAsNumber(): number {
        return parseInt(this._sum);
    }

    public toString(): string {
        return `${this._squares.toString()}:${this._pos.toString()}:${this._dir}:${this._sum}`;
    }

    public static fromString(str: string): LittleKillerConstraint {
        const [squares, pos, dir, sum] = str.split(':');
        return LittleKillerConstraint.fromSquares(PositionSet.fromString(squares), Position.fromString(pos), dir, sum);
    }

    public static fromSquares(squares: PositionSet, pos: Position, dir: string, sum: string): LittleKillerConstraint {
        return new LittleKillerConstraint(squares, pos, dir, sum);
    }

    public override validate(sudoku: SudokuState, violations: ViolationState): ViolationState {
        violations = super.validate(sudoku, violations);

        // dont bother if we have no sum
        const expectedSum = this.sumAsNumber();
        if (!expectedSum) return violations;

        // don't bother if we have empty squares
        const squares = this.squares().toArray();
        const emptySquares = squares.filter(s => !sudoku.digit(s));
        if (emptySquares.length > 0) return violations;

        // validate sum
        const sum = squares.reduce((a, s) => a + parseInt(sudoku.digit(s) || '0'), 0);
        if (sum !== expectedSum) violations = violations.addViolation(this.squares());

        return violations;
    }

    protected override getProperties(properties: Property[]): void {
        properties.push(new Property('Sum', this.name(), 'text', PropertyFlags.None, this, () => this.sum(),
            (ctx, prop, value) => Constraint.replace(ctx, prop, new LittleKillerConstraint(this._squares, this._pos, this._dir, value, this)),
            (ctx, prop, value) => LittleKillerConstraint.validate(ctx, prop, value)));
    }

    private static validate(ctx: PropertyContext, prop: Property, value: PropertyValue): boolean {
        return value.length < 4;
    }
}