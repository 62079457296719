import React from 'react';
import './UIMenuDivider.css';

export type UIMenuDividerProps = {
}

export type UIMenuDividerState = {
}

export default class UIMenuDivider extends React.PureComponent<UIMenuDividerProps, UIMenuDividerState> {

    override render(): React.ReactNode {
        return (
            <div className="UIMenuDivider" />
        );
    }
}