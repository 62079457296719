import { withAuth0, WithAuth0Props } from '@auth0/auth0-react';
import React from 'react';
import './UIUserButton.css';

export type UIUserButtonProps = WithAuth0Props & {
    onClick: React.MouseEventHandler<HTMLButtonElement>;
}

export type UIUserButtonState = {
}

class UIUserButton extends React.PureComponent<UIUserButtonProps, UIUserButtonState> {

    override render(): React.ReactNode {

        const auth0 = this.props.auth0;
        const showLoading = auth0.isLoading;
        const showLoginButton = !auth0.isLoading && !auth0.isAuthenticated;
        const showProfileButton = !auth0.isLoading && auth0.isAuthenticated;

        return (
            <div className="UIUserButton" tabIndex={3}>
                {showLoading ? "..." : null}
                {showLoginButton ? <button onClick={() => auth0.loginWithRedirect()}>Log in</button> : null}
                {showProfileButton ? <button onClick={e => this.props.onClick(e)}>{auth0.user?.nickname}</button> : null}
            </div>
        );
    }
}

export default withAuth0(UIUserButton);