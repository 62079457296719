import * as H from 'history';

export default class SearchQuery {

    private readonly _query: string;
    private readonly _pageNum: number;
    private readonly _pageSize: number;

    private constructor(query: string, pageNum: number) {
        this._query = query;
        this._pageNum = pageNum;
        this._pageSize = 24;
    }

    public query(): string {
        return this._query;
    }

    public pageNum(): number {
        return this._pageNum;
    }

    public pageSize(): number {
        return this._pageSize;
    }

    public title(title: string): string {

        title += ' - Search';

        if (this._query) {
            title += ` ${this._query}`;
        }

        if (this._pageNum > 1) {
            title += ` (${this._pageNum})`;
        }

        return title;
    }

    public isEqual(other: SearchQuery): boolean {
        return (this._query === other._query &&
            this._pageNum === other._pageNum &&
            this._pageSize === other._pageSize);
    }

    public isFirstPage(): boolean {
        return this._pageNum <= 1;
    }

    public isLastPage(results: number): boolean {
        return results < this._pageSize;
    }

    public page(pageNum:number): SearchQuery {
        return new SearchQuery(this._query, pageNum);
    }

    public toURL(location: Location): string {

        const url = new URL('/search', location.href);
        if (this._query) url.searchParams.append('q', this._query);
        if (this._pageNum > 1) url.searchParams.append('p', this._pageNum.toString());

        return url.toString();
    }

    public toTitle(): string {
        return 'Sudokuku';
    }

    public toLocation(path: string): H.LocationDescriptor {

        const searchParams = new URLSearchParams();
        if (this._query) searchParams.append('q', this._query);
        if (this._pageNum > 1) searchParams.append('p', this._pageNum.toString());

        return { pathname: path, search: searchParams.toString() };
    }

    static fromEmpty(): SearchQuery {
        return new SearchQuery('', 1);
    }

    static fromQuery(query: string, pageNum = 1): SearchQuery {
        return new SearchQuery(query, pageNum || 1);
    }

    public static fromLocation(location: H.Location): SearchQuery {

        const searchParams = new URLSearchParams(location.search);
        const query = searchParams.get('q') ?? '';
        const pageNum = parseInt(searchParams.get('p') ?? '');

        return new SearchQuery(query, pageNum || 1);
    }
}