import Position from './Position';

export default class PositionSet {

    private readonly _set: Set<string>;

    private constructor(set: Iterable<string>) {
        this._set = new Set(set);
    }

    static fromAny(pos?: PositionSet | Position | Position[] | string): PositionSet {
        if (pos === undefined) return PositionSet.fromEmpty();
        if (pos instanceof PositionSet) return pos;
        if (pos instanceof Position) return PositionSet.fromPos(pos);
        if (typeof pos === 'string') return PositionSet.fromString(pos);
        return PositionSet.fromArray(pos);
    }

    static fromEmpty(): PositionSet {
        return new PositionSet([]);
    }

    static fromPos(pos: Position): PositionSet {
        return new PositionSet([pos.toString()]);
    }

    static fromArray(array: Position[]): PositionSet {
        const set = array.map(pos => pos.toString());
        return new PositionSet(set);
    }

    static fromArrays(array: PositionSet[]): PositionSet {
        return array.reduce((a, p) => a.unionWith(p), PositionSet.fromEmpty());
    }

    static fromString(str: string): PositionSet {

        enum State {
            Pos,
            Range,
        }

        function consume(array: string[], pos: string, state: State): void {

            // no position to consume?
            if (pos === '') return;

            // consume position
            if (state === State.Pos) {
                array.push(pos);
                return;
            }

            // consume range
            if (state === State.Range) {
                const startPos = (array.length > 0) ? array[array.length - 1] : 'a1';
                const endPos = pos;

                const start = Position.fromString(startPos);
                const end = Position.fromString(endPos);

                const endRow = end.row();
                const endCol = end.col();

                for (let row = start.row(); row <= endRow; row++) {
                    for (let col = start.col(); col <= endCol; col++) {
                        pos = Position.fromRowCol(row, col).toString();
                        array.push(pos);
                    }
                }

                return;
            }
        }

        const array: string[] = [];
        let state = State.Pos;
        let pos = '';

        for (let index = 0; index < str.length; index++) {
            if (str[index] === ' ') {
                // skip whitespace
            }
            else if (str[index] === ',') {
                // consuame pos
                consume(array, pos, state);

                // start new position
                state = State.Pos;
                pos = '';
            }
            else if (str[index] === '-') {
                // consuame pos
                consume(array, pos, state);

                // start range
                state = State.Range;
                pos = '';
            }
            else {
                pos += str[index];
            }
        }

        // consume final position
        consume(array, pos, state);

        // done
        return new PositionSet(array);
    }

    toString(): string {
        let str = '';
        const ranges = [];

        // get ranges
        for (const pos of this.toSortedArray()) {
            ranges.push([pos, pos]);
        }

        // collapse rows
        for (let index = ranges.length - 1; index > 0; index--) {
            const aLo = ranges[index - 1][0] as Position;
            const aHi = ranges[index - 1][1] as Position;
            const bLo = ranges[index][0] as Position;
            const bHi = ranges[index][1] as Position;

            if (aLo.row() !== bLo.row()) continue;
            if (aHi.row() !== bHi.row()) continue;
            if (aHi.col() + 1 !== bLo.col()) continue;

            ranges[index - 1][1] = ranges[index][1];
            ranges.splice(index, 1);
        }

        // collapse cols
        for (let index = ranges.length - 1; index > 0; index--) {
            const aLo = ranges[index - 1][0] as Position;
            const aHi = ranges[index - 1][1] as Position;
            const bLo = ranges[index][0] as Position;
            const bHi = ranges[index][1] as Position;

            if (aLo.col() !== bLo.col()) continue;
            if (aHi.col() !== bHi.col()) continue;
            if (aHi.row() + 1 !== bLo.row()) continue;

            ranges[index - 1][1] = ranges[index][1];
            ranges.splice(index, 1);
        }

        // convert to string
        for (const [lo, hi] of ranges) {
            if (str.length > 0) str += ',';

            str += lo.toString();

            if (!lo.isEqual(hi)) {
                str += '-';
                str += hi.toString();
            }
        }

        return str;
    }

    toArray(): Position[] {
        return Array.from(this._set.values(), s => Position.fromString(s));
    }

    toSortedArray(): Position[] {
        return this.toArray().sort((a, b) => a.row() - b.row() || a.col() - b.col());
    }

    isEmpty(): boolean {
        return this._set.size === 0;
    }

    isEqual(other: PositionSet | undefined): boolean {
        if (other === undefined) return false;
        if (other.size() !== this.size()) return false;
        if (other.toArray().some(p => !this.has(p))) return false;

        return true;
    }

    unionWith(other: PositionSet): PositionSet {
        const thisSet = Array.from(this._set);
        const otherSet = Array.from(other._set);
        const combinedSet = thisSet.concat(otherSet);

        return new PositionSet(combinedSet);
    }

    disjunctiveUnionWith(other: PositionSet): PositionSet {
        const combinedSet: Position[] = [];

        for (const pos of this.toArray()) {
            if (!other.has(pos)) combinedSet.push(pos);
        }

        for (const pos of other.toArray()) {
            if (!this.has(pos)) combinedSet.push(pos);
        }

        return PositionSet.fromArray(combinedSet);
    }

    exceptWith(other: PositionSet): PositionSet {
        const combinedSet: Position[] = [];

        for (const pos of this.toArray()) {
            if (!other.has(pos)) combinedSet.push(pos);
        }

        return PositionSet.fromArray(combinedSet);
    }

    intersectWith(other: PositionSet): PositionSet {
        const combinedSet: Position[] = [];

        for (const pos of this.toArray()) {
            if (other.has(pos)) combinedSet.push(pos);
        }

        return PositionSet.fromArray(combinedSet);
    }

    first(): Position | undefined {
        const squares = this.toSortedArray();
        const first = 0;

        return squares.length ? squares[first] : undefined;
    }

    last(): Position | undefined {
        const squares = this.toSortedArray();
        const last = squares.length - 1;

        return squares.length ? squares[last] : undefined;
    }

    middle(): Position | undefined {
        const squares = this.toSortedArray();
        const middle = Math.floor(squares.length / 2);

        return squares.length ? squares[middle] : undefined;
    }

    boundsTopLeft(): Position | undefined {

        let topLeft: Position | undefined = undefined;

        for (const pos of this.toArray()) {
            topLeft = (topLeft === undefined) ? pos : Position.fromRowCol(
                Math.min(topLeft.row(), pos.row()),
                Math.min(topLeft.col(), pos.col())
            );
        }

        return topLeft;
    }

    boundsBottomRight(): Position | undefined {

        let bottomRight: Position | undefined = undefined;

        for (const pos of this.toArray()) {
            bottomRight = (bottomRight === undefined) ? pos : Position.fromRowCol(
                Math.max(bottomRight.row(), pos.row()),
                Math.max(bottomRight.col(), pos.col())
            );
        }

        return bottomRight;
    }

    size(): number {
        return this._set.size;
    }

    numRows(): number {
        const tl = this.boundsTopLeft();
        const br = this.boundsBottomRight();
        if (!tl || !br) return 0;

        return br.row() - tl.row() + 1;
    }

    numCols(): number {
        const tl = this.boundsTopLeft();
        const br = this.boundsBottomRight();
        if (!tl || !br) return 0;

        return br.col() - tl.col() + 1;
    }

    isFirst(pos: Position): boolean {
        return pos.isEqual(this.first());
    }

    isTop(pos: Position): boolean {
        return this.has(pos) && !this.has(pos.up());
    }

    isRight(pos: Position): boolean {
        return this.has(pos) && !this.has(pos.right());
    }

    isBottom(pos: Position): boolean {
        return this.has(pos) && !this.has(pos.down());
    }

    isLeft(pos: Position): boolean {
        return this.has(pos) && !this.has(pos.left());
    }

    has(pos: Position): boolean {
        return this._set.has(pos.toString());
    }

    hasAll(set: PositionSet): boolean {
        for (const pos of set.toArray()) {
            if (!this.has(pos)) return false;
        }

        return true;
    }

    hasAny(set: PositionSet): boolean {
        for (const pos of set.toArray()) {
            if (this.has(pos)) return true;
        }

        return false;
    }

    permute(degree: number): PositionSet[] {
        return PositionSet.permuteImpl(this.toArray(), degree).map(s => PositionSet.fromArray(s));
    }

    private static permuteImpl(set: Position[], degree: number): Position[][] {
        if (degree < 1) return [];

        const size = set.length;
        if (size < degree) return [];
        if (size === degree) return [set];

        const permutations: Position[][] = [];
        const remaining = Array.from(set);

        while (remaining.length > 0) {
            const first = [remaining.shift() as Position];

            if (degree === 1) {
                permutations.push(first);
            }
            else {
                for (const p of this.permuteImpl(remaining, degree - 1)) {
                    permutations.push(first.concat(p));
                }
            }
        }

        return permutations;
    }

    find(findFunc: (value: Position, index: number, array: Position[]) => boolean): Position | undefined {
        return this.toArray().find(findFunc);
    }

    filter(filterFunc: (value: Position, index: number, array: Position[]) => boolean): PositionSet {
        return PositionSet.fromArray(this.toArray().filter(filterFunc));
    }

    map<T>(mapFunc: (value: Position, index: number, array: Position[]) => T): T[] {
        return this.toArray().map(mapFunc);
    }

    reduce<T>(reduceFunc: (prevValue: T, value: Position, index: number, array: Position[]) => T, initialValue: T): T {
        return this.toArray().reduce(reduceFunc, initialValue);
    }

    count(filterFunc: (value: Position, index: number, array: Position[]) => boolean): number {
        return this.reduce((p, v, i, a) => p + (filterFunc(v, i, a) ? 1 : 0), 0);
    }
}