/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */

import SudokuSerializerError from "sudokuku-common/src/serialization/SudokuSerializerError";
import ConfigState, { ConfigKeypad, ConfigValidate } from "../model/ConfigState";

export default class ConfigSerializer {

    private static readonly _version: string = '1';

    //#region save

    public static saveConfig(config: ConfigState): any {

        const json = {
            version: ConfigSerializer._version,
            config: this.saveConfigState(config)
        }

        return json;
    }

    private static saveConfigState(config: ConfigState): any {

        const json: any = {};
        if (config.keypad()) json.keypad = config.keypad();
        if (config.validate()) json.validaye = config.validate();

        return json;
    }

    //#endregion save

    //#region load

    public static loadConfig(json: any): ConfigState {

        if (!json) throw SudokuSerializerError.fromMissing('json');

        // validate version
        const versionJson = json.version as string;
        if (!versionJson) throw SudokuSerializerError.fromMissing('version');
        if (versionJson !== ConfigSerializer._version) throw SudokuSerializerError.fromMessage(`Invalid version (${versionJson} != ${ConfigSerializer._version})`);

        // load
        const config = this.loadConfigState(json.config, 'config');

        // done
        return config;
    }

    private static loadConfigState(json: any, path: string): ConfigState {

        // empty info is okay
        if (!json) return ConfigState.fromDefault();

        // load settings
        const keypad = json.keypad as ConfigKeypad | undefined;
        const validate = json.validate as ConfigValidate | undefined;

        return ConfigState.fromData(keypad, validate);
    }

    //#endregion load
}