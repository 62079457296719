import DigitSet from '../../core/DigitSet';
import Position from '../../core/Position';
import PositionSet from '../../core/PositionSet';
import SudokuState from '../../core/SudokuState';
import PropertySelection from '../../properties/PropertySelection';
import Constraint from '../Constraint';
import ConstraintDef from '../ConstraintDef';
import CellConstraint from './CellConstraint';

export default class ParityOddConstraint extends CellConstraint {

    private static readonly _odd = new ConstraintDef('odd', 'Parity Odd', 'Cell');

    private static readonly _validDigits = DigitSet.fromString("13579");

    public static def(): ConstraintDef {
        return this._odd;
    }

    public override def(): ConstraintDef {
        return ParityOddConstraint.def();
    }

    public rules(): string {
        return 'Cells marked with a gray circle contain odd digits.';
    }

    public override validDigits(): DigitSet {
        return ParityOddConstraint._validDigits;
    }

    public override toString(): string {
        return this.squares().toString();
    }

    public static fromString(str: string): ParityOddConstraint {
        return ParityOddConstraint.fromSquares(PositionSet.fromString(str));
    }

    public static fromSquares(squares: PositionSet): ParityOddConstraint {
        return new ParityOddConstraint(squares);
    }

    public static fromSelection(sudoku: SudokuState, selection: Position[], propertySelection: [PropertySelection]): SudokuState {
        return Constraint.add(sudoku, new ParityOddConstraint(PositionSet.fromArray(selection)), propertySelection);
    }
}