/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */

import SudokuSerializerError from "sudokuku-common/src/serialization/SudokuSerializerError";
import ViewState, { ViewInfo } from "../model/ViewState";

export default class ViewSerializer {

    private static readonly _version: string = '1';

    //#region save

    public static saveView(view: ViewState): any {

        const json = {
            version: ViewSerializer._version,
            view: this.saveViewState(view)
        }

        return json;
    }

    private static saveViewState(view: ViewState): any {

        const json: any = {};
        if (view.info()) json.info = view.info();

        return json;
    }

    //#endregion save

    //#region load

    public static loadView(json: any): ViewState {

        if (!json) throw SudokuSerializerError.fromMissing('json');

        // validate version
        const versionJson = json.version as string;
        if (!versionJson) throw SudokuSerializerError.fromMissing('version');
        if (versionJson !== ViewSerializer._version) throw SudokuSerializerError.fromMessage(`Invalid version (${versionJson} != ${ViewSerializer._version})`);

        // load
        const view = this.loadViewState(json.view, 'view');

        // done
        return view;
    }

    private static loadViewState(json: any, path: string): ViewState {

        // empty info is okay
        if (!json) return ViewState.fromDefault();

        // load settings
        const info = json.info ?? 'show' as ViewInfo;

        return ViewState.fromData(info);
    }

    //#endregion load
}