import Position from '../../core/Position';
import PositionLine from '../../core/PositionLine';
import PositionSet from '../../core/PositionSet';
import SudokuState from '../../core/SudokuState';
import ViolationState from '../../core/ViolationState';
import PropertySelection from '../../properties/PropertySelection';
import Constraint from '../Constraint';
import ConstraintDef from '../ConstraintDef';
import LineConstraint from './LineConstraint';

export default class WhisperConstraint extends LineConstraint {

    private static readonly _whisper = new ConstraintDef('whisper', 'Whisper Line', 'Line', ['Whisper Line', 'German Whispers']);

    public static def(): ConstraintDef {
        return this._whisper;
    }

    public override def(): ConstraintDef {
        return WhisperConstraint.def();
    }

    public toString(): string {
        return this.line().toString();
    }

    public static fromString(str: string): WhisperConstraint {
        return WhisperConstraint.fromLine(PositionLine.fromString(str));
    }

    public static fromLine(line: PositionLine): WhisperConstraint {
        return new WhisperConstraint(line);
    }

    public static fromSelection(sudoku: SudokuState, selection: Position[], propertySelection: [PropertySelection]): SudokuState {
        
        for (const line of PositionLine.fromArrayMany(selection)) {
            sudoku = Constraint.add(sudoku, new WhisperConstraint(line), propertySelection);
        }

        return sudoku;
    }
    
    public rules(): string {
        return 'Digits along each green german whispers line must differ by at least five.';
    }

    public override validate(sudoku: SudokuState, violations: ViolationState): ViolationState {
        violations = super.validate(sudoku, violations);

        // get squares
        const squares = this.line().toArray();
        const digits = squares.map(s => parseInt(sudoku.digit(s) ?? '0'));

        // accumulate violations
        const violatingSqares: Position[] = [];

        for (let i = 1; i < digits.length; i++) {
            const prev = digits[i - 1];
            const next = digits[i];

            // digits must be consecutive
            if (prev && next && Math.abs(next - prev) < 5) {
                violatingSqares.push(squares[i - 1]);
                violatingSqares.push(squares[i]);
            }
        }

        // create violation
        if (violatingSqares.length > 0) {
            violations = violations.addViolation(PositionSet.fromArray(violatingSqares));
        }

        // done
        return violations;
    }
}