/* eslint-disable @typescript-eslint/no-explicit-any */

import { Auth0ContextInterface } from "@auth0/auth0-react";
import AuthUser from "./AuthUser";

export default class Auth {

    private static readonly UserRolesKey = 'https://www.sudokuku.com/roles';

    private readonly _auth0: Auth0ContextInterface;
    private readonly _accessTokens: Map<string, string>;
    private _user: AuthUser | null;

    public constructor(auth0: Auth0ContextInterface) {
        this._auth0 = auth0;
        this._accessTokens = new Map();
        this._user = null;
    }

    public update(auth0: Auth0ContextInterface, prevAuth0?: Auth0ContextInterface, updateUser?: (user: AuthUser | null) => void): Auth {

        // update auth
        const auth = (auth0 === prevAuth0) ? this : new Auth(auth0);

        // update user
        if (updateUser && (auth0.user !== prevAuth0?.user)) {
            auth.fetchUser().then(updateUser);
        }

        // done
        return auth;
    }

    public logout(returnTo: string): void {
        this._auth0.logout({ returnTo: returnTo });
    }

    public async fetchUser(): Promise<AuthUser | null> {
        const isAuth = this._auth0.isAuthenticated;
        if (!isAuth) return null;

        const user = this._auth0.user;
        if (!user) return null;

        if (!AuthUser.IsValid(this._user, user)) {
            const roles = await this.fetchUserRolesImpl();
            this._user = new AuthUser(user, roles);
        }

        return this._user;
    }

    public async fetchAccessToken(audience: string, scopes: string): Promise<string> {
        const isAuth = this._auth0.isAuthenticated;
        if (!isAuth) return '';

        const key = audience + scopes;

        let token = this._accessTokens.get(key);
        if (token === undefined) {
            token = await this.fetchAccessTokenImpl(audience, scopes);
            this._accessTokens.set(key, token);
        }

        return token;
    }

    public async fetchHeaders(audience: string, scopes: string): Promise<HeadersInit> {
        const token = await this.fetchAccessToken(audience, scopes);

        return token ? { Authorization: `Bearer ${token}` } : {};
    }

    private async fetchUserRolesImpl(): Promise<string[]> {
        let roles = [];

        try {
            const token = await this._auth0.getIdTokenClaims();
            roles = (token && token[Auth.UserRolesKey]) ?? [];
        } catch (e) {
            console.error(e);
        }

        return roles;
    }

    private async fetchAccessTokenImpl(audience: string, scope: string): Promise<string> {
        let token = '';
        const options = { audience: audience, scope: scope };

        try {
            token = await this._auth0.getAccessTokenSilently(options);
        }
        catch (e: any) {
            if (e.error === 'consent_required') {
                try {
                    token = await this._auth0.getAccessTokenWithPopup(options);
                }
                catch (e) {
                    console.error(e);
                }
            }
            else {
                console.error(e);
            }
        }

        return token;
    }
}