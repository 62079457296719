import PositionSet from '../../core/PositionSet';
import ConstraintDef, { ConstraintDefFlags } from '../ConstraintDef';
import KillerCageConstraint from './KillerCageConstraint';

export default class HiddenKillerConstraint extends KillerCageConstraint {

    private static readonly _hiddenKiller = new ConstraintDef('hiddenKiller', 'Hidden Killer', 'Killer', [], 0, ConstraintDefFlags.IsHidden);

    public static override def(): ConstraintDef {
        return this._hiddenKiller;
    }

    public override def(): ConstraintDef {
        return HiddenKillerConstraint.def();
    }

    public override longName(): string {
        return `${this.name()} ${this.squares().toString()}`;
    }

    public static override fromSquares(squares: PositionSet, value: string): HiddenKillerConstraint {
        return new HiddenKillerConstraint(squares, value);
    }
}