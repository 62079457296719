import Position from '../../core/Position';
import PositionLine from '../../core/PositionLine';
import PositionSet from '../../core/PositionSet';
import SudokuState from '../../core/SudokuState';
import ViolationState from '../../core/ViolationState';
import PropertySelection from '../../properties/PropertySelection';
import Constraint from '../Constraint';
import ConstraintDef from '../ConstraintDef';
import LineConstraint from './LineConstraint';

export default class PalindromeConstraint extends LineConstraint {

    private static readonly _palindrome = new ConstraintDef('palindrome', 'Palindrome Line', 'Line');

    public static def(): ConstraintDef {
        return this._palindrome;
    }

    public override def(): ConstraintDef {
        return PalindromeConstraint.def();
    }

    public toString(): string {
        return this.line().toString();
    }

    public static fromString(str: string): PalindromeConstraint {
        return PalindromeConstraint.fromLine(PositionLine.fromString(str));
    }

    public static fromLine(line: PositionLine): PalindromeConstraint {
        return new PalindromeConstraint(line);
    }

    public static fromSelection(sudoku: SudokuState, selection: Position[], propertySelection: [PropertySelection]): SudokuState {

        for (const line of PositionLine.fromArrayMany(selection)) {
            sudoku = Constraint.add(sudoku, new PalindromeConstraint(line), propertySelection);
        }

        return sudoku;
    }

    public rules(): string {
        return 'Digits along each gray palindrome line must read the same forwards and backwards.';
    }

    public override validate(sudoku: SudokuState, violations: ViolationState): ViolationState {
        violations = super.validate(sudoku, violations);

        const squares = this.line().toArray();

        for (let i = 0, j = squares.length - 1; i < j; i++, j--) {

            const squareHead = squares[i];
            const squareTail = squares[j];

            const digitHead = parseInt(sudoku.digit(squareHead) ?? '0');
            const digitTail = parseInt(sudoku.digit(squareTail) ?? '0');
            if (!digitHead || !digitTail) continue;

            if (digitHead !== digitTail) {
                violations = violations.addViolation(PositionSet.fromArray([squareHead, squareTail]));
            }
        }

        return violations;
    }
}