import classNames from 'classnames';
import React from 'react';
import './UIMenu.css';

export type UIMenuProps = {
    isMenuExpanded: boolean;
    onMenuExpand: React.ReactEventHandler<HTMLElement>;
    onMenuCollapse: React.ReactEventHandler<HTMLElement>;
}

export type UIMenuState = {
}

export default class UIMenu extends React.PureComponent<UIMenuProps, UIMenuState> {

    constructor(props: UIMenuProps) {
        super(props);

        this.handleKeyDown = this.handleKeyDown.bind(this);
    }

    override render(): React.ReactNode {

        const className = classNames({
            'UIMenu': true,
            'expanded': this.props.isMenuExpanded,
            'collapsed': !this.props.isMenuExpanded
        });

        return (
            <div className={className} onKeyDown={this.handleKeyDown}>
                {this.props.children}
            </div>
        );
    }

    private handleKeyDown(event: React.KeyboardEvent<HTMLElement>) {
        if (event.code === 'Escape') {
            event.preventDefault();
            this.props.onMenuCollapse(event);
        }
    }
}