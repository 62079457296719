import Position from '../../core/Position';
import PositionSet from '../../core/PositionSet';
import SudokuState from '../../core/SudokuState';
import ViolationState from '../../core/ViolationState';
import Constraint from '../Constraint';

export default abstract class GlobalConstraint extends Constraint {

    private readonly _squares: PositionSet;

    protected constructor(squares: PositionSet) {
        super();

        this._squares = squares;
    }

    public squares(): PositionSet {
        return this._squares;
    }

    public longName(): string {
        return this.squares().isEmpty() ? this.name() : `${this.name()} ${this.squares().first()?.toString()}`;
    }

    public isUnique(): boolean {
        return false;
    }

    public abstract isUniqueCandidates(): boolean;

    public override rulesExample(sudoku: SudokuState, square: Position): PositionSet {
        return this.candidates(square, sudoku);
    }

    public override validate(sudoku: SudokuState, violations: ViolationState): ViolationState {
        violations = super.validate(sudoku, violations);

        // get squares to check
        const squares = this.validateSquares(sudoku);
        let checked = PositionSet.fromEmpty();

        // check squares
        for (const square of squares.toArray()) {

            // get candidates
            const candidates = this.candidates(square, sudoku).exceptWith(checked);

            // validate candidates
            violations = this.validateCandidates(square, candidates, sudoku, violations);

            // dont check cells more than once
            checked = checked.unionWith(PositionSet.fromPos(square));
        }

        return violations;
    }

    public validateSquares(sudoku: SudokuState): PositionSet {
        return this._squares.isEmpty() ? sudoku.def().squares() : this._squares;
    }

    protected candidateOffsets(): [number, number][] {
        return [];
    }

    public candidates(square: Position, sudoku: SudokuState): PositionSet {

        const candidates = this.candidateOffsets()
            .map(o => square.offset(o[0], o[1]))
            .filter(p => sudoku.def().isSquare(p));

        return PositionSet.fromArray(candidates);
    }

    protected validateCandidates(square: Position, candidates: PositionSet, sudoku: SudokuState, violations: ViolationState): ViolationState {

        // get digit
        const digit = sudoku.digit(square);
        if (!digit) return violations;

        // check that candidates dont contain the same digit
        const dupeSquares = Constraint.findDigits(sudoku, digit, candidates);
        if (dupeSquares.size() < 2) return violations;

        // report violation
        return violations.addViolation(dupeSquares);
    }
}