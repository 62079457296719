import LocalStorage from "../../storage/LocalStorage";
import ConfigState from "../model/ConfigState";
import ConfigSerializer from "../serialization/ConfigSerializer";

export default class ConfigStorage {

    public static saveConfig(config: ConfigState): void {

        const json = ConfigSerializer.saveConfig(config);
        const str = JSON.stringify(json);

        LocalStorage.saveString('config', str);
    }

    public static loadConfig(): ConfigState | null {

        try {
            const str = LocalStorage.loadString('config');
            if (!str) return null;
    
            const json = JSON.parse(str);
            const sudoku = ConfigSerializer.loadConfig(json);

            return sudoku;
        }
        catch {
            return null;
        }
    }
}