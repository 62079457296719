import React from "react";
import It from "sudokuku-common/src/core/It";
import MultiMap from "sudokuku-common/src/core/MultiMap";
import SudokuState from "sudokuku-common/src/core/SudokuState";
import { PropertyContext } from "sudokuku-common/src/properties/Property";
import PropertySelection from "sudokuku-common/src/properties/PropertySelection";
import UIPropertyBox from "../../../ui-properties/UIPropertyBox";
import UIPropertyNode from "../../../ui-properties/UIPropertyNode";
import UIPropertyTree from "../../../ui-properties/UIPropertyTree";
import SelectionState from "../../model/SelectionState";
import "./UIEditProperties.css";

type UIEditPropertiesProps = {
    sudoku: SudokuState;
    selection: SelectionState;
    propertySelection: PropertySelection;
    onSudoku: (sudoku: SudokuState) => void;
    onSelection: (selection: PropertySelection) => void;
}

type UIEditPropertiesState = {
}

export default class UIEditProperties extends React.PureComponent<UIEditPropertiesProps, UIEditPropertiesState> {

    public override render(): React.ReactNode {

        const ctx: PropertyContext = {
            state: this.props.sudoku,
            startTransaction: (c) => (c.state as SudokuState).startTransaction(),
            endTransaction: (c, t) => { c.state = (c.state as SudokuState).endTransaction(t as SudokuState) },
        }

        const constraints = this.props.sudoku.def().constraints().all().filter(c => !c.def().isStandard() && !c.def().isHidden());
        const constraintsByName = MultiMap.groupBy(constraints, c => c.name());

        const roots = [this.props.sudoku.propertyObject(), this.props.selection.propertyObject()];

        return (
            <div className="UIEditProperties">
                <UIPropertyTree selection={this.props.propertySelection} onSelection={s => this.props.onSelection(s)}>
                    <UIPropertyNode label="Info" object={this.props.sudoku.propertyObject()} />
                    <UIPropertyNode label="Constraints" isExpanded={true}>
                        {It(constraintsByName.entries()).map(([k, v]) =>
                            <UIPropertyNode key={k} label={k}>
                                {v.map(c => <UIPropertyNode key={c.key()} label={c.longName()} object={c.propertyObject()} />)}
                            </UIPropertyNode>).toArray()}
                    </UIPropertyNode>
                </UIPropertyTree>
                <UIPropertyBox ctx={ctx} roots={roots} selection={this.props.propertySelection} onCommit={(ctx) => this.handleCommit(ctx)} />
            </div>
        );
    }

    private handleCommit(ctx: PropertyContext) {
        if (ctx.state !== this.props.sudoku) {
            this.props.onSudoku(ctx.state as SudokuState);
        }
    }
}