import React from 'react';
import Position from 'sudokuku-common/src/core/Position';
import SudokuState from 'sudokuku-common/src/core/SudokuState';
import SelectionState from '../../model/SelectionState';
import './GridColorMark.css';

export type GridColorMarkProps = {
    id: string,
    sudoku: SudokuState,
    selection: SelectionState,
}

export type GridColorMarkState = {
}

export default class GridColorMark extends React.PureComponent<GridColorMarkProps, GridColorMarkState> {

    override render(): React.ReactNode {
        const sudoku = this.props.sudoku;
        const selection = this.props.selection;

        if (!selection.colors().isEmpty()) return null;

        const square = Position.fromString(this.props.id);
        const colorMark = sudoku.colorMark(square);
        if (!colorMark) return null;

        const numColors = colorMark.length;
        if (numColors === 0) return null;
        if (numColors === 1) return this.renderOneColor(colorMark);
        if (numColors === 2) return this.renderTwoColors(colorMark);

        return this.renderMultiColors(colorMark);
    }

    private renderOneColor(colorMark: string) {
        const className = 'content c' + colorMark[0];

        return (
            <div className="GridColorMark content">
                <div className={className} />
            </div>
        );
    }

    private renderTwoColors(colorMark: string) {
        const className0 = 'content c' + colorMark[0];
        const className1 = 'content c' + colorMark[1];
        const style0 = { transform: 'scale(2) rotate(45deg)', transformOrigin: '100% 0%' };
        const style1 = { transform: 'scale(2) rotate(-45deg)', transformOrigin: '100% 0%' };

        return (
            <div className="GridColorMark content">
                <div className={className0} style={style0} />
                <div className={className1} style={style1} />
            </div>
        );
    }

    private renderMultiColors(colorMark: string) {

        const numSlices = colorMark.length;
        const halfAngle = Math.PI / numSlices;
        const scaleY = Math.cos(halfAngle) / Math.sin(halfAngle);
        const roundedScaleY = (Math.round(scaleY * 100) / 100) - 0.01;

        const sliceElements: React.ReactElement[] = [];

        for (let sliceIndex = 0; sliceIndex < numSlices; sliceIndex++) {
            const color = colorMark[sliceIndex];

            const rotate = sliceIndex * halfAngle * 2;
            const roundedRotate = Math.round(rotate * 1800 / Math.PI) / 10;

            const className = 'content c c' + color;
            const style = { transform: `rotate(${roundedRotate}deg) scaleY(${roundedScaleY}) scale(2) rotate(45deg)`, transformOrigin: '0 0' };

            const sliceElement = <div key={sliceIndex} className={className} style={style} />;
            sliceElements.push(sliceElement);
        }

        return (
            <div className="GridColorMark content">
                {sliceElements}
            </div>
        );
    }
}