import classNames from 'classnames';
import React, { MouseEventHandler } from 'react';
import ConstraintDef from 'sudokuku-common/src/constraints/ConstraintDef';
import ConstraintReg from 'sudokuku-common/src/constraints/ConstraintReg';
import It from 'sudokuku-common/src/core/It';
import MultiMap from 'sudokuku-common/src/core/MultiMap';
import SudokuConstraints from 'sudokuku-common/src/core/SudokuConstraints';
import './UIInputConstraints.css';

export type UIInputConstraintsProps = {
    onClick: MouseEventHandler
}

export type UIInputConstraintsState = {
}

export default class UIInputConstraints extends React.PureComponent<UIInputConstraintsProps, UIInputConstraintsState> {

    override render(): React.ReactNode {

        const constraints = SudokuConstraints.defs().filter(c => ConstraintReg.hasFromSelection(c.kind()));
        const byCategory = It(MultiMap.groupBy(constraints, c => c.category()).entries()).toArray();

        const className = classNames({
            'UIInputConstraints': true
        });

        return (
            <div className={className}>
                {byCategory.map(([k, v]) => this.renderCategory(k, v))}
            </div>
        );
    }

    private renderCategory(category: string, defs: ConstraintDef[]): React.ReactNode {
        return (
            <React.Fragment key={category}>
                <div className="category">{category}</div>
                {defs.map(c => <button id="InputConstraint" key={c.kind()} value={c.kind()} onClick={this.props.onClick}>{c.name()}</button>)}
            </React.Fragment>
        );
    }
}