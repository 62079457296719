import Position from '../../core/Position';
import PositionLine from '../../core/PositionLine';
import SudokuState from '../../core/SudokuState';
import ViolationState from '../../core/ViolationState';
import PropertySelection from '../../properties/PropertySelection';
import Constraint from '../Constraint';
import ConstraintDef from '../ConstraintDef';
import LineConstraint from './LineConstraint';

export default class ArrowConstraint extends LineConstraint {

    private static readonly _arrow = new ConstraintDef('arrow', 'Arrow', 'Line');

    public static def(): ConstraintDef {
        return this._arrow;
    }

    public override def(): ConstraintDef {
        return ArrowConstraint.def();
    }

    public toString(): string {
        return this.line().toString();
    }

    public static fromString(str: string): ArrowConstraint {
        return ArrowConstraint.fromLine(PositionLine.fromString(str));
    }

    public static fromLine(line: PositionLine): ArrowConstraint {
        return new ArrowConstraint(line);
    }

    public static fromSelection(sudoku: SudokuState, selection: Position[], propertySelection: [PropertySelection]): SudokuState {

        for (const line of PositionLine.fromArrayMany(selection)) {
            sudoku = Constraint.add(sudoku, new ArrowConstraint(line), propertySelection);
        }

        return sudoku;
    }

    public rules(): string {
        return 'Digits along each arrow must sum to the digit in the circle end.';
    }

    public override validate(sudoku: SudokuState, violations: ViolationState): ViolationState {
        violations = super.validate(sudoku, violations);

        const squares = this.line().toArray();

        const expectedSum = parseInt(sudoku.digit(squares[0]) ?? '0');
        if (!expectedSum) return violations;

        let arrowSum = 0;

        for (let i = 1; i < squares.length; i++) {
            const square = squares[i];

            const digit = parseInt(sudoku.digit(square) ?? '0');
            if (!digit) return violations;

            arrowSum += digit;
        }

        if (arrowSum !== expectedSum) {
            violations = violations.addViolation(this.squares());
        }

        return violations;
    }
}