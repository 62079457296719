import Position from '../../core/Position';
import PositionSet from '../../core/PositionSet';
import SudokuState from '../../core/SudokuState';
import ViolationState from '../../core/ViolationState';
import Property, { PropertyContext, PropertyFlags, PropertyValue } from '../../properties/Property';
import PropertySelection from '../../properties/PropertySelection';
import Constraint from '../Constraint';
import ConstraintDef from '../ConstraintDef';
import UniqueConstraint from '../unique/UniqueConstraint';

export default class KillerCageConstraint extends UniqueConstraint {

    private static readonly _killer = new ConstraintDef('killer', 'Killer Cage', 'Killer');

    private readonly _sum: string;

    protected constructor(squares: PositionSet, sum: string, prev?: KillerCageConstraint) {
        super(squares, prev);

        this._sum = sum;
    }

    public static def(): ConstraintDef {
        return this._killer;
    }

    public override def(): ConstraintDef {
        return KillerCageConstraint.def();
    }

    public longName(): string {
        return `${this.name()} ${this.squares().toString()}`;
    }

    public rules(): string {
        return 'Digits cannot repeat in each killer cage and must sum to the cage total (if given).'
    }

    public sum(): string {
        return this._sum;
    }

    public sumAsNumber(): number {
        return (this.squares().size() === 9) ? 45 : parseInt(this._sum);
    }

    public toString(): string {
        return `${this.squares().toString()}:${this.sum()}`;
    }

    public static fromString(str: string): KillerCageConstraint {
        const [squares, sum] = str.split(':');
        return KillerCageConstraint.fromSquares(PositionSet.fromString(squares), sum);
    }

    public static fromSquares(squares: PositionSet, value: string): KillerCageConstraint {
        return new KillerCageConstraint(squares, value);
    }

    public static fromSelection(sudoku: SudokuState, selection: Position[], propertySelection: [PropertySelection]): SudokuState {

        const squares = PositionSet.fromArray(selection);

        return Constraint.add(sudoku, new KillerCageConstraint(squares, ''), propertySelection);
    }

    public override validate(sudoku: SudokuState, violations: ViolationState): ViolationState {
        violations = super.validate(sudoku, violations);

        // dont bother if we have no sum
        const expectedSum = this.sumAsNumber();
        if (!expectedSum) return violations;

        // don't bother if we have empty squares
        const squares = this.squares().toArray();
        const emptySquares = squares.filter(s => !sudoku.digit(s));
        if (emptySquares.length > 0) return violations;

        // validate sum
        const sum = squares.reduce((p, s) => p + parseInt(sudoku.digit(s) || '0'), 0);
        if (sum !== expectedSum) violations = violations.addViolation(this.squares());

        return violations;
    }

    public static getRegions(squares: Position[]): PositionSet[] {
        return [PositionSet.fromArray(squares)];
    }

    protected override getProperties(properties: Property[]): void {
        properties.push(new Property('Sum', this.name(), 'text', PropertyFlags.IsFocused, this, () => this.sum(),
            (ctx, prop, value) => Constraint.replace(ctx, prop, new KillerCageConstraint(this.squares(), value, this)),
            (ctx, prop, value) => KillerCageConstraint.validate(ctx, prop, value)));
    }

    private static validate(ctx: PropertyContext, prop: Property, value: PropertyValue): boolean {
        return value.length < 4;
    }
}