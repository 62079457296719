import classNames from "classnames";
import React from "react";
import { ConstraintProps } from "sudokuku-common/src/constraints/ConstraintReg";
import LockoutConstraint from "sudokuku-common/src/constraints/line/LockoutConstraint";
import './LockoutConstraintCell.css';

export default function LockoutConstraintCell(props: ConstraintProps): React.ReactElement | null {

    const constraint = props.constraint as LockoutConstraint;

    const [iDir, oDir] = constraint.cellDirections(props.pos);
    if (!iDir && !oDir) return null;

    const bulb = constraint.isStart(props.pos) || constraint.isEnd(props.pos);

    const iClassName = classNames(['LockoutConstraint', 'content', 'line', 'd' + iDir]);
    const oClassName = classNames(['LockoutConstraint', 'content', 'line', 'd' + oDir]);
    const bClassName = classNames(['LockoutConstraint', 'content', 'bulb']);

    return (
        <React.Fragment>
            {iDir ? <div className={iClassName} /> : null}
            {oDir ? <div className={oClassName} /> : null}
            {bulb ? <div className={bClassName} /> : null}
        </React.Fragment>);
}