import Position from '../../core/Position';
import PositionSet from '../../core/PositionSet';
import SudokuState from '../../core/SudokuState';
import ViolationState from '../../core/ViolationState';
import Property, { PropertyContext, PropertyFlags, PropertyValue } from '../../properties/Property';
import Constraint from '../Constraint';
import ConstraintDef from '../ConstraintDef';

export default class SandwichConstraint extends Constraint {

    private static readonly _sandwich = new ConstraintDef('sandwich', 'Sandwich', 'Killer');

    private readonly _squares: PositionSet;
    private readonly _pos: Position;
    private readonly _sum: string;

    protected constructor(squares: PositionSet, pos: Position, sum: string, prev?: SandwichConstraint) {
        super(prev);

        this._squares = squares;
        this._pos = pos;
        this._sum = sum;
    }

    public static def(): ConstraintDef {
        return this._sandwich;
    }

    public override def(): ConstraintDef {
        return SandwichConstraint.def();
    }

    public longName(): string {
        return `${this.name()} ${this.pos().toString()}`;
    }

    public rules(): string {
        return 'Numbers outside the grid indicate the sum of the digits between 1 and 9 in the row/column.';
    }

    public override isUnique(): boolean {
        return false;
    }

    public override squares(): PositionSet {
        return this._squares;
    }

    public override visible(): PositionSet {
        return PositionSet.fromPos(this._pos);
    }

    public pos(): Position {
        return this._pos;
    }

    public sum(): string {
        return this._sum;
    }

    public sumAsNumber(): number {
        return parseInt(this._sum);
    }

    public toString(): string {
        return `${this._squares.toString()}:${this._pos.toString()}:${this._sum}`;
    }

    public static fromString(str: string): SandwichConstraint {
        const [squares, pos, sum] = str.split(':');
        return SandwichConstraint.fromSquares(PositionSet.fromString(squares), Position.fromString(pos), sum);
    }

    public static fromSquares(squares: PositionSet, pos: Position, sum: string): SandwichConstraint {
        return new SandwichConstraint(squares, pos, sum);
    }

    public override validate(sudoku: SudokuState, violations: ViolationState): ViolationState {
        violations = super.validate(sudoku, violations);

        // dont bother if we have no sum
        const expectedSum = this.sumAsNumber();
        if (!Number.isFinite(expectedSum)) return violations;

        // sum the digits between 1 and 9
        const squares = this.squares().toSortedArray();
        let start = -1;
        let end = -1;
        let sum = 0;

        for (let i = 0; i < squares.length; i++) {
            const digit = parseInt(sudoku.digit(squares[i]) ?? '0');
            const isRange = digit === 1 || digit === 9;

            if (start < 0 && isRange) {
                start = i;
            }
            else if (end < 0 && isRange) {
                end = i;
                break;
            }
            else if (start >= 0 && end < 0) {
                if (!digit) break;
                sum += digit;
            }
        }

        // don't bother if we have empty squares
        if (start < 0 || end < 0) return violations;

        // validate sum
        if (sum !== expectedSum) violations = violations.addViolation(PositionSet.fromArray(squares.slice(start, end + 1)));

        // done
        return violations;
    }

    protected override getProperties(properties: Property[]): void {
        properties.push(new Property('Sum', this.name(), 'text', PropertyFlags.None, this, () => this.sum(),
            (ctx, prop, value) => Constraint.replace(ctx, prop, new SandwichConstraint(this._squares, this._pos, value, this)),
            (ctx, prop, value) => SandwichConstraint.validate(ctx, prop, value)));
    }

    private static validate(ctx: PropertyContext, prop: Property, value: PropertyValue): boolean {
        return value.length < 4;
    }
}