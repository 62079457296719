import Constraint from 'sudokuku-common/src/constraints/Constraint';
import BoxConstraint from 'sudokuku-common/src/constraints/unique/BoxConstraint';
import DigitSet from 'sudokuku-common/src/core/DigitSet';
import Position from 'sudokuku-common/src/core/Position';
import PositionSet from 'sudokuku-common/src/core/PositionSet';
import PositionSets from 'sudokuku-common/src/core/PositionSets';
import SudokuChange from 'sudokuku-common/src/core/SudokuChange';
import SudokuState from 'sudokuku-common/src/core/SudokuState';
import SolveDeduction from 'sudokuku-common/src/solver/SolveDeduction';
import SolveHint from 'sudokuku-common/src/solver/SolveHint';
import SolvePath from 'sudokuku-common/src/solver/SolvePath';
import SolveStep from 'sudokuku-common/src/solver/SolveStep';
import SelectionState from './SelectionState';

export default class HintState {

    private static readonly InDebugMode = false;

    private readonly _solvePath: SolvePath | undefined;
    private readonly _stepNum: number;
    private readonly _hintNum: number;

    private constructor(solvePath?: SolvePath, stepNum = -1, hintNum = -1) {
        this._solvePath = HintState.debugSolvePath(solvePath);
        this._stepNum = stepNum;
        this._hintNum = hintNum;
    }

    static fromDefault(): HintState {
        return new HintState();
    }

    static fromSolution(solvePath: SolvePath): HintState {
        return new HintState(solvePath);
    }

    public hasHint(): boolean {
        if (this._solvePath === undefined) return false;
        if (this._solvePath.steps().length === 0) return false;
        if (this._stepNum < 0) return true;

        return this.currentHint() !== undefined;
    }

    public hintText(): string {

        let text = this.currentHint()?.text();
        if (!text) return 'Click to show a hint...';

        // show the technique name?
        // const technique = this.currentStep()?.deduction().technique();
        // if (technique) text = `${technique}: ${text}`;

        text += this.isLastHint(this._hintNum + 1) ? ' Click to show the solution...' : ' Click to show another hint...';

        return text;
    }

    public hintSquares(): PositionSet {
        return this.currentHint()?.squares() ?? PositionSet.fromEmpty();
    }

    public hintColors(): PositionSets {
        return this.currentHint()?.colors() ?? PositionSets.fromEmpty();
    }

    public hintCauses(): PositionSets {
        return this.currentHint()?.causes() ?? PositionSets.fromEmpty();
    }

    public hintSudoku(sudoku: SudokuState): SudokuState {

        const txn = sudoku.startTransaction();

        const changes = this.currentChanges();
        if (!changes) return sudoku;

        sudoku = this.applyChanges(sudoku, changes);

        for (const change of changes) {
            if (change.op() === 'set' && change.target() === 'digit') {
                const peerSquares = this.findPeerSquares(sudoku, change.squares());
                sudoku = sudoku.applyChange(SudokuChange.removeCenterMark(peerSquares, change.digits()));
                sudoku = sudoku.applyChange(SudokuChange.removeCornerMark(peerSquares, change.digits()));
            }

            if (change.op() === 'set' && change.target() === 'center') {
                const boxSquares = this.findBoxCornerSquares(sudoku, change.squares(), change.digits());
                sudoku = sudoku.applyChange(SudokuChange.removeCornerMark(boxSquares, change.digits()));
            }
        }

        return sudoku.endTransaction(txn);
    }

    public hintSelection(selection: SelectionState): SelectionState {
        const squares = this.hintSquares();
        const colors = this.hintColors();
        const causes = this.hintCauses();

        return squares.isEmpty() ? selection : selection.setColorSelection(squares.middle(), squares.toArray(), colors, causes);
    }

    public nextHint(sudoku: SudokuState, selection: SelectionState): HintState {

        const steps = this._solvePath?.steps();
        if (!steps) return this;

        const stepNum = steps.findIndex((s, i) => this.showStep(s, i, sudoku));
        if (stepNum < 0) return HintState.fromDefault();

        let hintNum = 0;
        if (stepNum === this._stepNum) {
            const hints = steps[stepNum].deduction().hints();
            if (selection.squares().isEqual(hints[this._hintNum].squares())) {
                hintNum = (this._hintNum + 1) % hints.length;
            }
        }

        if (hintNum === 0) {
            console.log(`hint ${stepNum}.${hintNum}: ${steps[stepNum].deduction().technique()}`);
            for (const c of steps[stepNum].changes()) {
                console.log(`  ${c.op()} ${c.target()} ${c.digits()} ${c.squares()}`);
            }
        }

        return new HintState(this._solvePath, stepNum, hintNum);
    }

    private currentStep(): SolveStep | undefined {
        const steps = this._solvePath?.steps();
        return steps && steps[this._stepNum];
    }

    private currentHint(): SolveHint | undefined {
        const hints = this.currentStep()?.deduction().hints();
        return hints && hints[this._hintNum];
    }

    private currentChanges(): readonly SudokuChange[] | undefined {
        return this.isLastHint(this._hintNum) ? this.currentStep()?.changes() : undefined;
    }

    private isLastHint(hintNum: number) {
        const hints = this.currentStep()?.deduction().hints();
        if (hints === undefined) return false;

        return (hintNum === hints.length - 1);
    }

    private showStep(step: SolveStep, stepNum: number, sudoku: SudokuState): boolean {

        const deduction = step.deduction();
        if (deduction.technique() === '') return false;
        if (deduction.hints().length === 0) return false;

        const changedSquares: Position[] = [];
        this.applyChanges(sudoku, step.changes(), changedSquares);

        return changedSquares.length > 0;
    }

    private applyChanges(sudoku: SudokuState, changes: readonly SudokuChange[], changedSquares?: Position[]): SudokuState {

        // in debug mode, we show all operations
        if (HintState.InDebugMode && this._stepNum <= 0) {
            sudoku = sudoku.setCenterMark(sudoku.allSquares().filter(s => this.candidateMark(sudoku, s).isEmpty()), DigitSet.fromFull());
        }

        for (const change of changes) {
            if (this.applyChangeFilter(sudoku, change)) {
                sudoku = sudoku.applyChange(change, this.applyChangeDigitFilter, changedSquares);
            }
        }

        return sudoku;
    }

    private applyChangeFilter(sudoku: SudokuState, change: SudokuChange): boolean {

        if (change.target() === 'corner') {

            // dont change corner mark if digit is already set
            const digitSquare = change.squares().find(s => this.digit(sudoku, s).hasAny(change.digits()));
            if (digitSquare) return false;

            // dont change corner mark if mark is already set
            const cornerSquare = change.squares().find(s => this.cornerMark(sudoku, s).hasAny(change.digits()));
            if (cornerSquare) return false;

            // dont add corner marks if naked set
            const boxConstraints = sudoku.def().constraints().filter(c => (c instanceof BoxConstraint) && c.squares().hasAny(change.squares()));
            const boxSquares = Constraint.squares(boxConstraints).filter(s => this.candidateMark(sudoku, s).hasAny(change.digits()));
            const candidates = this.candidateMarks(sudoku, change.squares());
            const isNakedSet = boxSquares.isEqual(change.squares()) && candidates.size() <= change.squares().size();
            if (isNakedSet) return false;
        }

        return true;
    }

    private applyChangeDigitFilter(square: Position, oldDigits: DigitSet, newDigits: DigitSet, change: SudokuChange, sudoku: SudokuState): DigitSet {

        // dont update solved squares
        if (change.target() !== 'color' && sudoku.hasDigit(square)) return oldDigits;

        // dont filter add, rems and empty squares
        if (change.op() !== 'set') return newDigits;
        if (oldDigits.isEmpty()) return newDigits;

        // dont change digits set by the player
        switch (change.target()) {
            case 'digit': return oldDigits;
            case 'center': return oldDigits.intersectWith(newDigits);
            case 'corner': return oldDigits.unionWith(newDigits);
            default: return newDigits;
        }
    }

    private findPeerSquares(sudoku: SudokuState, squares: PositionSet): PositionSet {

        const peerConstraints = sudoku.def().constraints().filter(c => c.isUnique() && c.squares().hasAny(squares));
        const peerSquares = PositionSet.fromArrays(peerConstraints.map(c => c.squares()));

        return peerSquares;
    }

    private findBoxCornerSquares(sudoku: SudokuState, squares: PositionSet, digits: DigitSet): PositionSet {

        let boxSquares = PositionSet.fromEmpty();

        const boxConstraints = sudoku.def().constraints().filter(c => (c instanceof BoxConstraint) && c.squares().hasAny(squares));

        for (const boxConstraint of boxConstraints) {
            const markedSquares = boxConstraint.squares().filter(s => DigitSet.fromString(sudoku.cornerMark(s) || '').hasAny(digits));
            const remainingSquares = markedSquares.exceptWith(squares);
            if (remainingSquares.isEmpty()) boxSquares = boxSquares.unionWith(markedSquares);
        }

        return boxSquares;
    }

    private hasAllDigits(sudoku: SudokuState, squares: PositionSet): unknown {
        return !squares.toArray().some(s => sudoku.digit(s) === undefined);
    }

    private digit(sudoku: SudokuState, square: Position): DigitSet {
        return DigitSet.fromString(sudoku.digit(square) || '');
    }

    private digits(sudoku: SudokuState, squares: PositionSet): DigitSet {
        return DigitSet.fromString(squares.toArray().map(s => sudoku.digit(s) || '').join());
    }

    private cornerMark(sudoku: SudokuState, square: Position): DigitSet {
        return DigitSet.fromString(sudoku.cornerMark(square) || '');
    }

    private cornerMarks(sudoku: SudokuState, squares: PositionSet): DigitSet {
        return DigitSet.fromString(squares.toArray().map(s => sudoku.cornerMark(s) || '').join());
    }

    private centerMark(sudoku: SudokuState, square: Position): DigitSet {
        return DigitSet.fromString(sudoku.centerMark(square) || '');
    }

    private centerMarks(sudoku: SudokuState, squares: PositionSet): DigitSet {
        return DigitSet.fromString(squares.toArray().map(s => sudoku.centerMark(s) || '').join());
    }

    private candidateMark(sudoku: SudokuState, square: Position): DigitSet {
        return DigitSet.fromString(sudoku.digit(square) || sudoku.centerMark(square) || '');
    }

    private candidateMarks(sudoku: SudokuState, squares: PositionSet): DigitSet {
        return DigitSet.fromString(squares.toArray().map(s => sudoku.digit(s) || sudoku.centerMark(s) || '').join());
    }

    private static debugSolvePath(solvePath: SolvePath | undefined): SolvePath | undefined {

        if (!HintState.InDebugMode) return solvePath;
        if (!solvePath) return undefined;

        const newSteps: SolveStep[] = [];
        for (const step of solvePath.steps()) {
            const deduction = step.deduction();

            const hints = deduction.hints();
            if (hints.length > 0) {
                newSteps.push(step);
                continue;
            }

            const newHints = [SolveHint.fromText('Debug.')];
            const newDeduction = SolveDeduction.fromTechnique(deduction.technique(), newHints, deduction.eliminations());
            const newStep = SolveStep.fromDeduction(newDeduction, step.changes());
            newSteps.push(newStep);
        }

        return SolvePath.fromData(solvePath.revision(), solvePath.isComplete(), newSteps);
    }
}