import Position from '../../core/Position';
import PositionLine from '../../core/PositionLine';
import PositionSet from '../../core/PositionSet';
import SudokuState from '../../core/SudokuState';
import ViolationState from '../../core/ViolationState';
import PropertySelection from '../../properties/PropertySelection';
import Constraint from '../Constraint';
import ConstraintDef from '../ConstraintDef';
import LineConstraint from './LineConstraint';

export default class RenbanConstraint extends LineConstraint {

    private static readonly _renban = new ConstraintDef('renban', 'Renban Line', 'Line');

    public static def(): ConstraintDef {
        return this._renban;
    }

    public override def(): ConstraintDef {
        return RenbanConstraint.def();
    }

    public toString(): string {
        return this.line().toString();
    }

    public static fromString(str: string): RenbanConstraint {
        return RenbanConstraint.fromLine(PositionLine.fromString(str));
    }

    public static fromLine(line: PositionLine): RenbanConstraint {
        return new RenbanConstraint(line);
    }

    public static fromSelection(sudoku: SudokuState, selection: Position[], propertySelection: [PropertySelection]): SudokuState {
        
        for (const line of PositionLine.fromArrayMany(selection)) {
            sudoku = Constraint.add(sudoku, new RenbanConstraint(line), propertySelection);
        }

        return sudoku;
    }
    
    public rules(): string {
        return 'Digits along each purple renban line must be from a consecutive set of numbers.';
    }

    public override validate(sudoku: SudokuState, violations: ViolationState): ViolationState {
        violations = super.validate(sudoku, violations);

        // get squares
        const squares = this.line().toArray();

        // get sorted digits along the line
        const digits = squares.map(s => parseInt(sudoku.digit(s) ?? '0'));
        digits.sort();

        // any missing digits?
        if (!digits[0]) return violations;

        // accumulate violations
        const violatingSqares: Position[] = [];

        for (let i = 1; i < digits.length; i++) {
            const prev = digits[i - 1];
            const next = digits[i];

            // digits must be consecutive
            if (next - prev !== 1) {
                violatingSqares.push(squares[i - 1]);
                violatingSqares.push(squares[i]);
            }
        }

        // create violation
        if (violatingSqares.length > 0) {
            violations = violations.addViolation(PositionSet.fromArray(violatingSqares));
        }

        // done
        return violations;
    }
}