import classNames from "classnames";
import React from "react";
import { ConstraintProps } from "sudokuku-common/src/constraints/ConstraintReg";
import LittleKillerConstraint from "sudokuku-common/src/constraints/killer/LittleKillerConstraint";
import './LittleKillerConstraintCell.css';

export default function LittleKillerConstraintCell(props: ConstraintProps): React.ReactElement | null {

    const constraint = props.constraint as LittleKillerConstraint;

    const hasLittleKiller = props.pos.isEqual(constraint.pos());
    if (!hasLittleKiller) return null;

    const sum = constraint.sum();
    const dir = constraint.dir();
    const arrow = '\u2191';

    const sumClassName = classNames(['LittleKillerConstraint', 'content', 'sum', 'd' + dir]);
    const dirClassName = classNames(['LittleKillerConstraint', 'content', 'dir', 'd' + dir]);

    return (
        <React.Fragment>
            <div className={sumClassName}>{sum}</div>
            <div className={dirClassName}>{arrow}</div>
        </React.Fragment>
    );
}