import DigitSet from '../../core/DigitSet';
import PositionSet from '../../core/PositionSet';
import SudokuState from '../../core/SudokuState';
import ViolationState from '../../core/ViolationState';
import Constraint from '../Constraint';

export default abstract class CellConstraint extends Constraint {

    private _squares: PositionSet;

    protected constructor(squares: PositionSet) {
        super();

        this._squares = squares;
    }

    public squares(): PositionSet {
        return this._squares;
    }

    public isUnique(): boolean {
        return false;
    }

    public longName(): string {
        return `${this.name()} ${this.squares().toString()}`;
    }

    public override validate(sudoku: SudokuState, violations: ViolationState): ViolationState {

        const validDigits = this.validDigits();

        for (const square of this._squares.toArray()) {
            const digit = sudoku.digit(square);
            if (!digit) continue;

            const digitNumber = parseInt(digit);
            const isValid = validDigits.has(digitNumber);

            if (!isValid) violations = violations.addViolation(PositionSet.fromPos(square));
        }

        return violations;
    }

    public abstract validDigits(): DigitSet;
}