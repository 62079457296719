import Position from '../../core/Position';
import PositionSet from '../../core/PositionSet';
import ConstraintDef from '../ConstraintDef';
import UniqueConstraint from './UniqueConstraint';

export default class DiagConstraint extends UniqueConstraint {

    private static readonly _diag = new ConstraintDef('diag', 'Diagonal', 'Unique');

    private readonly _dir: '+' | '-';

    protected constructor(squares: PositionSet, dir: '+' | '-') {
        super(squares);

        this._dir = dir;
    }

    public static def(): ConstraintDef {
        return this._diag;
    }

    override def(): ConstraintDef {
        return DiagConstraint.def();
    }

    public direction(): string {
        return this._dir;
    }

    public longName(): string {
        return `${this.name()} ${this._dir} ${this.squares().first()?.toString()}`;
    }

    public rules(): string {
        return 'Digits cannot repeat along the marked diagonals.'
    }

    public toString(): string {
        return `${this.squares().first()?.toString()}:${this._dir}`;
    }

    public static fromString(str: string): DiagConstraint {
        const [squares, dir] = str.split(':');
        return DiagConstraint.fromStart(Position.fromString(squares), dir as '+' | '-');
    }

    public static fromStart(start: Position, dir: '+' | '-'): DiagConstraint {
        const squares: Position[] = [];

        for (let i = 0; i < 9; i++) {
            const square = dir === "+" ? start.offset(+i, -i) : start.offset(+i, +i);
            squares.push(square);
        }

        return new DiagConstraint(PositionSet.fromArray(squares), dir);
    }
}