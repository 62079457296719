import React from 'react';
import './Viewport.css';

export type ViewportProps = {
}

export type ViewportState = {
    originX: number;
    originY: number;
    translateX: number;
    translateY: number;
    scale: number;
    transition: number;
}

interface GestureEvent extends UIEvent {
    clientX: number;
    clientY: number;
    rotation: number;
    scale: number;
}

export default class Viewport extends React.PureComponent<ViewportProps,ViewportState> {

    private readonly _ref: React.RefObject<HTMLDivElement>;

    private _disableGestureEvent: boolean;
    private _gestureStartScale: number;
    private _prevCenterX: number;
    private _prevCenterY: number;
    private _prevDist : number;

    private readonly WHEEL_TIMEOUT_MS = 150;
    private _wheelTimeout: NodeJS.Timeout | undefined;

    constructor(props: ViewportProps) {
        super(props);

        this._ref = React.createRef<HTMLDivElement>();
        this._gestureStartScale =  1;
        this._prevCenterX = -1;
        this._prevCenterY = -1;
        this._prevDist = -1;
        this._disableGestureEvent = false;

        this.state = Viewport.defaultState(0);

        this.handleWheel = this.handleWheel.bind(this);
        this.handleWheelTimeout = this.handleWheelTimeout.bind(this);
        this.handleGestureStart = this.handleGestureStart.bind(this);
        this.handleGestureChange = this.handleGestureChange.bind(this);
        this.handleGestureEnd = this.handleGestureEnd.bind(this);
        this.handleTouchStart = this.handleTouchStart.bind(this);
        this.handleTouchMove = this.handleTouchMove.bind(this);
        this.handleTouchEnd = this.handleTouchEnd.bind(this);
    }
    
    override componentDidMount(): void {
        this._ref.current?.addEventListener('wheel', this.handleWheel);
        this._ref.current?.addEventListener('gesturestart', this.handleGestureStart);
        this._ref.current?.addEventListener('gesturechange', this.handleGestureChange);
        this._ref.current?.addEventListener('gestureend', this.handleGestureEnd);
        this._ref.current?.addEventListener("touchstart", this.handleTouchStart);
        this._ref.current?.addEventListener("touchmove", this.handleTouchMove);
        this._ref.current?.addEventListener("touchend", this.handleTouchEnd);
        this._ref.current?.addEventListener("touchcancel", this.handleTouchEnd);
    }

    override componentWillUnmount(): void {
        this._ref.current?.removeEventListener('wheel', this.handleWheel);
        this._ref.current?.removeEventListener('gesturestart', this.handleGestureStart);
        this._ref.current?.removeEventListener('gesturechange', this.handleGestureChange);
        this._ref.current?.removeEventListener('gestureend', this.handleGestureEnd);
        this._ref.current?.removeEventListener("touchstart", this.handleTouchStart);
        this._ref.current?.removeEventListener("touchend", this.handleTouchEnd);
        this._ref.current?.removeEventListener("touchmove", this.handleTouchMove);
        this._ref.current?.removeEventListener("touchcancel", this.handleTouchEnd);
    }

    override render(): React.ReactNode {

        const style = {
            transform: this.cssTransform(),
            transformOrigin: this.cssTransformOrigin(),
            transition: this.cssTransition()
        };

        return (
            <div ref={this._ref} className="ViewportContainer">
                <div className="Viewport" style={style}>
                    {this.props.children}
                </div>
            </div>
        );
    }

    private cssTransform(): string {
        return (
            `translate(${this.state.translateX}px,${this.state.translateY}px) ` +
            `translate(${this.state.originX}px,${this.state.originY}px) ` +
            `scale(${this.state.scale}) `+
            `translate(${-this.state.originX}px,${-this.state.originY}px) `
        );
    }

    private cssTransformOrigin(): string {
        return '0px 0px';
    }

    private cssTransition(): string {
        return `transform ${this.state.transition}s`;
    }

    private handleWheel(event: WheelEvent | React.WheelEvent<HTMLDivElement>) {

        if (this._wheelTimeout) clearTimeout(this._wheelTimeout);
        this._wheelTimeout = setTimeout(this.handleWheelTimeout, this.WHEEL_TIMEOUT_MS);

        if (event.ctrlKey) {
            const element = event.currentTarget as HTMLDivElement;

            const width = element.offsetWidth;
            const height = element.offsetHeight;

            const centerX = event.clientX - element.offsetLeft + window.scrollX;
            const centerY = event.clientY - element.offsetTop + window.scrollY;

            const offsetX = (this._prevCenterX >= 0) ? centerX - this._prevCenterX : 0;
            const offsetY = (this._prevCenterY >= 0) ? centerY - this._prevCenterY : 0;

            const deltaScale = -event.deltaY / 100.0 * this.state.scale;
            const deltaX = offsetX - (offsetX / this.state.scale);
            const deltaY = offsetY - (offsetY / this.state.scale);

            this._prevCenterX = centerX;
            this._prevCenterY = centerY;
            
            this.setState((s,p) => Viewport.zoomState(s, deltaScale, deltaX, deltaY, centerX, centerY, width, height));
            event.preventDefault();
          }
          else {
            const element = event.currentTarget as HTMLDivElement;
            const deltaX = event.deltaX / this.state.scale;
            const deltaY = event.deltaY / this.state.scale;
            const width = element.offsetWidth;
            const height = element.offsetHeight;
            
            this.setState((s,p) => Viewport.panState(s, deltaX, deltaY, width, height));
            event.preventDefault();
          }      
    }

    private handleWheelTimeout() {
        if (!this._ref.current) return;
    
        if (this.state.scale < 1) {
            this._prevCenterX = -1;
            this._prevCenterY = -1;

            this.setState((p,s) => Viewport.defaultState(0.15));
        }
    }

    private handleGestureStart(event: Event) {

        if (this._disableGestureEvent) return;

        this._gestureStartScale = this.state.scale;
        event.preventDefault();
    }

    private handleGestureChange(event: Event) {

        if (this._disableGestureEvent) return;

        const gestureEvent = event as GestureEvent;

        const scale = this._gestureStartScale * gestureEvent.scale;
        const deltaY = (this.state.scale - scale) * 100 / this.state.scale;

        const wheelEvent = new WheelEvent('wheel', {
            clientX: gestureEvent.clientX,
            clientY: gestureEvent.clientY,
            deltaMode: WheelEvent.DOM_DELTA_PIXEL,
            deltaY: deltaY,
            ctrlKey: true
        });

        gestureEvent.currentTarget?.dispatchEvent(wheelEvent);
        event.preventDefault();
    }

    private handleGestureEnd(event: Event) {

        if (this._disableGestureEvent) return;

        event.preventDefault();
    }
  
    private handleTouchStart(event: TouchEvent) {

        // no need to respond to gesture events if we are getting touch events
        this._disableGestureEvent = true;

        // only consider double finger taps
        if (event.touches.length !== 2) return;

        const element = event.currentTarget as HTMLDivElement;
        const touch0 = event.touches[0];
        const touch1 = event.touches[1];

        const dist = Viewport.distance(touch0.clientX, touch0.clientY, touch1.clientX, touch1.clientY);

        const clientX = (touch0.clientX + touch1.clientX) / 2;
        const clientY = (touch0.clientY + touch1.clientY) / 2;

        const centerX = clientX - element.offsetLeft + window.scrollX;
        const centerY = clientY - element.offsetTop + window.scrollY;

        const scaledCenterX = centerX / this.state.scale;
        const scaledCenterY = centerY / this.state.scale;

        const scaledOriginX = this.state.originX - (this.state.originX / this.state.scale);
        const scaledOriginY = this.state.originY - (this.state.originY / this.state.scale);

        const scaledTranslateX = this.state.translateX - (this.state.translateX / this.state.scale);
        const scaledTranslateY = this.state.translateY - (this.state.translateY / this.state.scale);

        this._prevCenterX = scaledCenterX + scaledOriginX + scaledTranslateX;
        this._prevCenterY = scaledCenterY + scaledOriginY + scaledTranslateY;
        this._prevDist = dist;

        event.preventDefault();
    }

    private handleTouchMove(event: TouchEvent) {

        // only consider double finger taps
        if (event.touches.length !== 2) return;

        // start zoom timeout
        if (this._wheelTimeout) clearTimeout(this._wheelTimeout);
        this._wheelTimeout = setTimeout(this.handleWheelTimeout, this.WHEEL_TIMEOUT_MS);

        const element = event.currentTarget as HTMLDivElement;
        const width = element.offsetWidth;
        const height = element.offsetHeight;
        const touch0 = event.touches[0];
        const touch1 = event.touches[1];

        const dist = Viewport.distance(touch0.clientX, touch0.clientY, touch1.clientX, touch1.clientY);

        const clientX = (touch0.clientX + touch1.clientX) / 2;
        const clientY = (touch0.clientY + touch1.clientY) / 2;

        const centerX = clientX - element.offsetLeft + window.scrollX;
        const centerY = clientY - element.offsetTop + window.scrollY;

        const deltaX = centerX - this._prevCenterX;
        const deltaY = centerY - this._prevCenterY;
        const deltaDist = dist - this._prevDist;
        const deltaScale = deltaDist / 100 * this.state.scale;

        this._prevCenterX = centerX;
        this._prevCenterY = centerY;
        this._prevDist = dist;

        this.setState((s,p) => Viewport.zoomState(s, deltaScale, deltaX, deltaY, centerX, centerY, width, height));
        event.preventDefault();
    }

    private handleTouchEnd(event: TouchEvent) {
        // do nothing
    }
    
    private static defaultState(transition: number) {
        return {
            originX: 0,
            originY: 0,
            translateX: 0,
            translateY: 0,
            scale: 1,
            transition: transition
        }   
    }
    
    private static zoomState(s: ViewportState, deltaScale: number, deltaX: number, deltaY: number, centerX: number, centerY: number, width: number, height: number) {

        let scale = s.scale + deltaScale;
        if (scale < 0.8) scale = 0.8;
        if (scale > 5.0) scale = 5.0;

        const maxX = centerX - (centerX / scale); // + (width * 0.1);
        const minX = maxX - (width - (width / scale)); // - (width * 0.2);
        const maxY = centerY - (centerY / scale); // + (height * 0.1);
        const minY = maxY - (height - (height / scale)); // - (height * 0.2);

        let translateX = s.translateX + deltaX;
        if (translateX < minX) translateX = minX;
        if (translateX > maxX) translateX = maxX;

        let translateY = s.translateY + deltaY;
        if (translateY < minY) translateY = minY;
        if (translateY > maxY) translateY = maxY;

        const originX = centerX - translateX;
        const originY = centerY - translateY;

        return {
            originX: Math.round(originX * 100) / 100,
            originY: Math.round(originY * 100) / 100,
            translateX: Math.round(translateX * 100) / 100,
            translateY: Math.round(translateY * 100) / 100,
            scale: Math.round(scale * 100) / 100,
            transition: 0
        };
    }

    private static panState(s: ViewportState, deltaX: number, deltaY: number, width: number, height: number) {

        const centerX = s.originX + s.translateX;
        const centerY = s.originY + s.translateY;

        return this.zoomState(s, 0, deltaX, deltaY, centerX, centerY, width, height);
    }

    private static distance(x1: number, y1: number, x2: number, y2: number): number {
        return Math.sqrt(Math.pow(x1 - x2, 2) + Math.pow(y1 - y2, 2));
    }
}