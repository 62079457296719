
export enum StatusFlags {
    None = 0,
    IsError = 1 << 1,
    IsLoading = 1 << 2,
    IsSaving = 1 << 3,
    IsComplete = 1 << 4,
    HasViolations = 1 << 5,
    HasNewerRevision = 1 << 6,
}

export default class StatusState {

    private readonly _flags: StatusFlags;
    private readonly _message: string;

    private constructor(flags: StatusFlags, message: string) {
        this._flags = flags;
        this._message = message;
    }

    static fromDefault(): StatusState {
        return new StatusState(StatusFlags.None, '');
    }

    static fromError(message: string): StatusState {
        return new StatusState(StatusFlags.IsError, message);
    }

    hasFlags(flags: StatusFlags): boolean {
        return (this._flags & flags) === flags;
    }

    setFlags(flags: StatusFlags, message?: string): StatusState {
        return new StatusState(this._flags | flags, message ?? this._message);
    }

    clearFlags(flags: StatusFlags, message?: string): StatusState {
        return new StatusState(this._flags & ~flags, message ?? this._message);
    }

    message(): string {
        return this._message;
    }
}