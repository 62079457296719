/* eslint-disable @typescript-eslint/ban-types */
import DigitSet from '../../core/DigitSet';
import Position from '../../core/Position';
import PositionSet from '../../core/PositionSet';
import SudokuState from '../../core/SudokuState';
import ViolationState from '../../core/ViolationState';
import DominoConstraint from '../domino/DominoConstraint';
import GlobalConstraint from './GlobalConstraint';

export default abstract class NegativeConstraint extends GlobalConstraint {

    private static readonly _offsets: [number, number][] = [
        [+0, +0],
        [+0, -1], [+0, +1],
        [-1, +0], [+1, +0]
    ];

    private _type: Function;
    private _positive: DominoConstraint[];

    protected constructor(squares: PositionSet, type: Function, ...positive: DominoConstraint[]) {
        super(squares);

        this._type = type;
        this._positive = positive;
    }

    public override toString(): string {
        return this.squares().toString();
    }

    override isUniqueCandidates(): boolean {
        return false;
    }

    protected override candidateOffsets(): [number, number][] {
        return NegativeConstraint._offsets;
    }

    public override validateSquares(sudoku: SudokuState): PositionSet {
        let squares = super.validateSquares(sudoku);
        const constraints = sudoku.def().constraints();

        // dont check squares with positive constraint
        for (const constraint of constraints.filter(c => c instanceof this._type)) {
            squares = squares.exceptWith(constraint.squares());
        }

        return squares;
    }

    protected override validateCandidates(square: Position, candidates: PositionSet, sudoku: SudokuState, violations: ViolationState): ViolationState {

        // get digit
        const digit = DigitSet.fromString(sudoku.digit(square) ?? '');
        if (digit.isEmpty()) return violations;

        // get violations
        const invalidCandidates = candidates.filter(c => {
            const candidateDigit = DigitSet.fromString(sudoku.digit(c) ?? '');
            if (candidateDigit.isEmpty()) return false;

            const domino = digit.unionWith(candidateDigit);
            const invalid = this._positive.find(p => p.isValidDomino(domino)) != undefined;

            return invalid;
        });

        // no violations?
        if (invalidCandidates.isEmpty()) return violations;

        // report violation
        return violations.addViolation(invalidCandidates.unionWith(PositionSet.fromPos(square)));
    }
}