import classNames from "classnames";
import { ConstraintProps } from "sudokuku-common/src/constraints/ConstraintReg";
import DiagConstraint from "sudokuku-common/src/constraints/unique/DiagConstraint";
import './DiagConstraintCell.css';

export default function DiagConstraintCell(props: ConstraintProps): React.ReactElement | null {

    const constraint = props.constraint as DiagConstraint;
    const isPos = constraint.direction() === "+";
    const isNeg = constraint.direction() === "-";

    const className = classNames({
        'DiagConstraint': true,
        'content': true,
        'pos': isPos,
        'neg': isNeg
    });

    return <div className={className} />;
}