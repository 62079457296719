/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */

export default class DbCreateResult {

    private _row: {
        id: string;
    }

    private constructor(row: any) {
        this._row = row;
    }

    public id(): string {
        return this._row.id;
    }

    public static fromRow(row: any): DbCreateResult {
        return new DbCreateResult(row);
    }
}