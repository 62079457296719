import DigitSet from '../../core/DigitSet';
import Position from '../../core/Position';
import PositionSet from '../../core/PositionSet';
import SudokuState from '../../core/SudokuState';
import ViolationState from '../../core/ViolationState';
import Property, { PropertyContext, PropertyFlags, PropertyValue } from '../../properties/Property';
import PropertySelection from '../../properties/PropertySelection';
import Constraint from '../Constraint';
import ConstraintDef from '../ConstraintDef';

export default class QuadConstraint extends Constraint {

    private readonly _start: Position;
    private readonly _squares: PositionSet;
    private readonly _digits: string;

    private static readonly _quad = new ConstraintDef('quad', 'Quadruple', 'Local');

    protected constructor(start: Position, digits: string, prev?: QuadConstraint) {
        super(prev);

        this._start = start;
        this._squares = PositionSet.fromArray([start, start.right(), start.down(), start.down().right()]);
        this._digits = digits;
    }

    public static def(): ConstraintDef {
        return this._quad;
    }

    public override def(): ConstraintDef {
        return QuadConstraint.def();
    }

    public longName(): string {
        return `${this.name()} ${this.start().toString()}`;
    }

    public rules(): string {
        return 'Digits in circles must appear in the surrounding cells.';
    }

    public digits(): string {
        return this._digits;
    }

    public start(): Position {
        return this._start;
    }

    public override squares(): PositionSet {
        return this._squares;
    }

    public override isUnique(): boolean {
        return false;
    }

    public toString(): string {
        return `${this.start().toString()}:${this.digits()}`;
    }

    public static fromString(str: string): QuadConstraint {
        const [square, digits] = str.split(':');
        return QuadConstraint.fromStart(Position.fromString(square), digits);
    }

    static fromStart(start: Position, digits: string): QuadConstraint {
        return new QuadConstraint(start, digits);
    }

    public static fromSelection(sudoku: SudokuState, selection: Position[], propertySelection: [PropertySelection]): SudokuState {

        const squares = PositionSet.fromArray(selection);
        if (!QuadConstraint.isValidSelection(squares)) return sudoku;

        const firstSquare = squares.first();
        if (!firstSquare) return sudoku;

        return Constraint.add(sudoku, new QuadConstraint(firstSquare, ''), propertySelection);
    }

    public override validate(sudoku: SudokuState, violations: ViolationState): ViolationState {

        const digits = sudoku.digits(this.squares()).filter(d => d);
        if (digits.length < 4) return violations;

        const enteredDigits = DigitSet.fromString(digits.join());
        const requiredDigits = DigitSet.fromString(this.digits());

        const missingDigits = requiredDigits.exceptWith(enteredDigits);
        if (missingDigits.isEmpty()) return violations;

        return violations.addViolation(this.squares());
    }

    private static isValidSelection(squares: PositionSet): boolean {
        return (squares.size() === 4) && (squares.numRows() === 2) && (squares.numCols() === 2);
    }

    protected override getProperties(properties: Property[]): void {
        properties.push(new Property('Digits', this.name(), 'text', PropertyFlags.IsFocused, this, () => this.digits(),
            (ctx, prop, value) => Constraint.replace(ctx, prop, new QuadConstraint(this._start, value?.toString() ?? '', this)),
            (ctx, prop, value) => QuadConstraint.validate(ctx, prop, value)));
    }

    private static validate(ctx: PropertyContext, prop: Property, value: PropertyValue): boolean {
        return !DigitSet.fromString(value).isEmpty();
    }
}