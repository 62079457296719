import DigitSet from '../../core/DigitSet';
import Position from '../../core/Position';
import PositionSet from '../../core/PositionSet';
import SudokuState from '../../core/SudokuState';
import Property, { PropertyContext, PropertyFlags, PropertyValue } from '../../properties/Property';
import PropertySelection from '../../properties/PropertySelection';
import Constraint from '../Constraint';
import ConstraintDef from '../ConstraintDef';
import SumNegativeConstraint from '../global/SumNegativeConstraint';
import DominoConstraint from './DominoConstraint';

export default class SumConstraint extends DominoConstraint {

    private static readonly _sum = new ConstraintDef('sum', 'Kropki Sum', 'Domino');

    private _sum: string;

    protected constructor(squares: PositionSet, sum: string, prev?: SumConstraint) {
        super(squares, d => SumConstraint.getValidDigits(d, SumConstraint.sumAsNumber(sum)), prev);

        this._sum = sum;
    }

    public static def(): ConstraintDef {
        return this._sum;
    }

    public override def(): ConstraintDef {
        return SumConstraint.def();
    }

    public sum(): string {
        return this._sum;
    }

    public sumAsNumber(): number {
        return SumConstraint.sumAsNumber(this._sum);
    }

    public rules(constraints: Constraint[]): string {
        const negative = constraints.find(c => c instanceof SumNegativeConstraint);
        const negativeRule = negative ? '' : ' Not all sums are given.';

        return `Cells separated by ${this._sum} sum to ${this.sumAsNumber()}.` + negativeRule;
    }

    public toString(): string {
        return `${this.squares().toString()}:${this._sum}`;
    }

    public static fromString(str: string): SumConstraint {
        const [squares, value] = str.split(':');
        return SumConstraint.fromSquares(PositionSet.fromString(squares), value);
    }

    static fromSquares(squares: PositionSet, value: string): SumConstraint {
        return new SumConstraint(squares, value);
    }

    public static fromSelection(sudoku: SudokuState, selection: Position[], propertySelection: [PropertySelection]): SudokuState {
        
        const squares = PositionSet.fromArray(selection);

        return Constraint.add(sudoku, new SumConstraint(squares, 'X'), propertySelection);
    }
    
    private static getValidDigits(digits: DigitSet, sum: number): DigitSet {
        let result = DigitSet.fromEmpty();

        for (let digit = 1; digit <= 9; digit++) {
            if (!digits.has(digit)) continue;

            const rem = sum - digit;
            if (rem < 1 || rem > 9 || rem === digit) continue;

            result = result.unionWith(DigitSet.fromDigit(rem));
        }

        return result;
    }

    private static sumAsNumber(value: string): number {

        switch (value.toUpperCase()) {
            case "X": return 10;
            case "V": return 5;
        }

        return parseInt(value);
    }

    protected override getProperties(properties: Property[]): void {
        properties.push(new Property('Sum', this.name(), 'text', PropertyFlags.None, this, () => this.sum(),
            (ctx, prop, value) => Constraint.replace(ctx, prop, new SumConstraint(this.squares(), value?.toString() ?? ',', this)),
            (ctx, prop, value) => SumConstraint.validate(ctx, prop, value)));
    }

    private static validate(ctx: PropertyContext, prop: Property, value: PropertyValue): boolean {
        return Number.isSafeInteger(this.sumAsNumber(value));
    }
}