import { withAuth0, WithAuth0Props, withAuthenticationRequired } from '@auth0/auth0-react';
import React from 'react';
import { RouteComponentProps } from "react-router-dom";
import SudokuApi from '../api/v1/SudokuApi';
import Auth from '../auth/Auth';
import UIMenu, { UIMenuProps } from '../ui-menu/UIMenu';
import UIMenuDivider from '../ui-menu/UIMenuDivider';
import UIMenuHamburger from '../ui-menu/UIMenuHamburger';
import UIMenuLink from '../ui-menu/UIMenuLink';
import UIRoot from '../ui-shell/UIRoot';
import './UIImport.css';

export type UIImportProps = WithAuth0Props & UIMenuProps & RouteComponentProps & {
}

export type UIImportState = {
    state: 'idle' | 'importing' | 'success';
    value: string;
    status: string;
    sudokuId: string;
}

export class UIImport extends React.PureComponent<UIImportProps, UIImportState> {

    private _auth: Auth;

    public constructor(props: UIImportProps) {
        super(props);

        this._auth = new Auth(props.auth0);

        this.state = {
            state: 'idle',
            value: '',
            status: '',
            sudokuId: '',
        }
    }

    override componentDidMount(): void {
        document.title = UIRoot.Title + ' - Import';
    }

    override componentDidUpdate(prevProps: UIImportProps, prevState: UIImportState): void {

        // update auth
        if (this.props.auth0 !== prevProps.auth0) {
            this._auth = new Auth(this.props.auth0);
        }

    }

    override render(): React.ReactNode {
        return (
            <div className="UIImport">

                <div className="form">
                    <textarea autoFocus={true} rows={10} placeholder="Digits, URL, etc." value={this.state.value} onChange={(e) => this.handleValueChange(e.currentTarget.value)}></textarea>
                    <div className="buttons">
                        <button id="Import" onClick={() => this.handleImportClick()} disabled={this.state.state !== 'idle'} >Import</button>
                        <span id="Status">{this.state.status}</span>
                        <button id="Edit" disabled={this.state.state !== 'success'} onClick={() => this.handleEditClick()}>Edit</button>
                        <button id="Play" disabled={this.state.state !== 'success'} onClick={() => this.handlePlayClick()}>Play</button>
                    </div>
                </div>

                <p>Please read the <a href="/terms" target="_blank">Terms</a> before importing a puzzle. The puzzle Author retains all rights.</p>

                <p>Sudokuku supports importing the following content:</p>
                <ul>
                    <li>F-Puzzles URL:
                        <p>Only 9x9 puzzles are currently supported. All standard constraints are supported. Most non-standard ones
                            (like whispers) are also supported. Cosmetics (text, circles, rectangles and lines) are not yet supported.</p>
                        <p>Example: https://f-puzzles.com/?id=y7vvsqn6</p>
                    </li>

                    <li>CTC URL:
                        <p>Only 9x9 puzzles are currently supported. Constraints are inferred based on their appearance. Please double
                            check that all constraints have imported correctly using the editor.</p>
                        <p>Example: https://app.crackingthecryptic.com/sudoku/NFhHj2bQ2g</p>
                    </li>

                    <li>81 Digits:
                        <p>This is a common format used by many classic Sudoku apps and websites.
                            Digits represent givens. Dots/zeroes are empty cells. All other characters are ignored.</p>
                        <p>Example: ..48529..39...6.8...8...574873.4..56...168.4.14.7.....629...7...3.687..2..7..3..5</p>
                    </li>
                </ul>

                <UIMenu {...this.props}>
                    <UIMenuHamburger {...this.props} />
                    <UIMenuLink to='/' {...this.props}>Home</UIMenuLink>
                    <UIMenuDivider />
                    <UIMenuLink to='/terms' target="_blank" {...this.props}>Terms</UIMenuLink>
                    <UIMenuLink to='/privacy' target="_blank" {...this.props}>Privacy</UIMenuLink>
                </UIMenu>
            </div>
        );
    }

    private handleValueChange(value: string): void {
        this.setState({ value: value })
    }

    private handleImportClick(): void {
        if (!this.state.value) {
            this.setState({ status: 'Please input a URL or digits into the form.' });
            return;
        }

        this.setState({ status: 'Importing...', state: 'importing' });

        SudokuApi.importSudoku(this._auth, this.state.value)
            .then(r =>
                this.setState({ status: 'Success. Click Play or Edit to continue.', state: 'success', sudokuId: r.id() }))
            .catch(e =>
                this.setState({ status: `Import failed (${e.message})`, state: 'idle' }));
    }

    private handleEditClick(): void {
        this.props.history.push(`/edit/${this.state.sudokuId}`);
    }

    private handlePlayClick(): void {
        this.props.history.push(`/play/${this.state.sudokuId}`);
    }
}

export default withAuthenticationRequired(withAuth0(UIImport), {
    onRedirecting: () => <div>Loading...</div>,
});