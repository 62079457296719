import classNames from "classnames";
import React from "react";
import { ConstraintProps } from "sudokuku-common/src/constraints/ConstraintReg";
import RenbanConstraint from "sudokuku-common/src/constraints/line/RenbanConstraint";
import './RenbanConstraintCell.css';

export default function RenbanConstraintCell(props: ConstraintProps): React.ReactElement | null {

    const constraint = props.constraint as RenbanConstraint;

    const [iDir, oDir] = constraint.cellDirections(props.pos);
    if (!iDir && !oDir) return null;

    const iClassName = classNames(['RenbanConstraint', 'content', 'line', 'd' + iDir]);
    const oClassName = classNames(['RenbanConstraint', 'content', 'line', 'd' + oDir]);

    const ibgClassName = classNames(['RenbanConstraint', 'content', 'line', 'bg', 'd' + iDir]);
    const obgClassName = classNames(['RenbanConstraint', 'content', 'line', 'bg', 'd' + oDir]);

    return (
        <React.Fragment>
            {iDir ? <div className={ibgClassName} /> : null}
            {oDir ? <div className={obgClassName} /> : null}
            {iDir ? <div className={iClassName} /> : null}
            {oDir ? <div className={oClassName} /> : null}
        </React.Fragment>);
}