import Position from '../../core/Position';
import PositionSet from '../../core/PositionSet';
import ConstraintDef, { ConstraintDefFlags } from '../ConstraintDef';
import UniqueConstraint from './UniqueConstraint';

export default class ColConstraint extends UniqueConstraint {

    private static readonly _col = new ConstraintDef('col', 'Column', 'Unique', [], -3, ConstraintDefFlags.IsStandard);

    private constructor(squares: PositionSet) {
        super(squares);
    }

    public static def(): ConstraintDef {
        return this._col;
    }

    public override def(): ConstraintDef {
        return ColConstraint.def();
    }

    public longName(): string {
        return `${this.name()} ${this.squares().first()?.toString()}`;
    }

    public rules(): string {
        return 'Digits cannot repeat in each column.'
    }

    public toString(): string {
        return this.squares().first()?.toString() || '';
    }

    public static fromString(str: string): ColConstraint {
        return ColConstraint.fromStart(Position.fromString(str));
    }

    static fromStart(start: Position): ColConstraint {
        const squares: Position[] = [];
        
        for (let i = 0; i < 9; i++) {
            const square = start.offset(i, 0);
            squares.push(square);
        }

        return new ColConstraint(PositionSet.fromArray(squares));
    }
}