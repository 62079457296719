import Position from '../../core/Position';
import PositionLine from '../../core/PositionLine';
import PositionSet from '../../core/PositionSet';
import SudokuError from '../../core/SudokuError';
import SudokuState from '../../core/SudokuState';
import ViolationState from '../../core/ViolationState';
import PropertySelection from '../../properties/PropertySelection';
import Constraint from '../Constraint';
import ConstraintDef from '../ConstraintDef';
import LineConstraint from './LineConstraint';

export default class ThermoConstraint extends LineConstraint {

    private static readonly _thermo = new ConstraintDef('thermo', 'Thermometer', 'Line', ['Thermometer', 'Thermo']);

    public static def(): ConstraintDef {
        return this._thermo;
    }

    public override def(): ConstraintDef {
        return ThermoConstraint.def();
    }

    public override isUnique(): boolean {
        return true;
    }

    public toString(): string {
        return this.line().toString();
    }

    public static fromString(str: string): ThermoConstraint {
        return ThermoConstraint.fromLine(PositionLine.fromString(str));
    }

    public static fromLine(line: PositionLine): ThermoConstraint {

        if (!this.isValidLine(line)) {
            throw new SudokuError('Line is not a valid thermometer constraint.');
        }

        return new ThermoConstraint(line);
    }

    public static fromSelection(sudoku: SudokuState, selection: Position[], propertySelection: [PropertySelection]): SudokuState {
        
        for (const line of PositionLine.fromArrayMany(selection)) {
            sudoku = Constraint.add(sudoku, new ThermoConstraint(line), propertySelection);
        }

        return sudoku;
    }

    public rules(): string {
        return 'Digits must increase from the bulb of each thermometer.'
    }

    public override validate(sudoku: SudokuState, violations: ViolationState): ViolationState {
        violations = super.validate(sudoku, violations);

        let prevDigit = 0;
        let prevSquare = Position.fromString('a1');

        for (const square of this.line().toArray()) {

            // skip unfilled digits
            const digit = parseInt(sudoku.digit(square) || '0');
            if (!digit) continue;

            // digits must increase
            if (digit <= prevDigit) {
                violations = violations.addViolation(PositionSet.fromArray([prevSquare, square]));
                continue;
            }

            // continue to next digit
            prevDigit = digit;
            prevSquare = square;
        }

        return violations;
    }

    public static isValidLine(line: PositionLine): boolean {
        if (line.size() < 2) return false;
        if (line.size() > 9) return false;
        if (line.isOverlapping()) return false;

        return true;
    }
}