import Position from './Position';
import PositionSet from './PositionSet';
import SudokuConstraints from './SudokuConstraints';
import SudokuError from './SudokuError';
import SudokuInfo from './SudokuInfo';

export default class SudokuDef {

    private readonly _revision: number;
    private readonly _info: SudokuInfo;
    private readonly _numRows: number;
    private readonly _numCols: number;
    private readonly _squares: PositionSet;
    private readonly _digits: Map<string, string>;
    private readonly _constraints: SudokuConstraints;

    private constructor(revision: number, info: SudokuInfo, numRows: number, numCols: number, squares: PositionSet, digits: Map<string, string>, constraints: SudokuConstraints) {
        this._revision = revision;
        this._info = info;
        this._numRows = numRows;
        this._numCols = numCols;
        this._squares = squares;
        this._digits = digits;
        this._constraints = constraints;
    }

    isEmpty(): boolean {
        return !this._numRows || !this._numCols;
    }

    info(): SudokuInfo {
        return this._info;
    }

    revision(): number {
        return this._revision;
    }

    numRows(): number {
        return this._numRows;
    }

    numCols(): number {
        return this._numCols;
    }

    isFixed(pos: Position): boolean {
        return this._digits.has(pos.toString());
    }

    isSquare(pos: Position): boolean {
        return this._squares.has(pos);
    }

    squares(): PositionSet {
        return this._squares;
    }

    constraints(): SudokuConstraints {
        return this._constraints;
    }

    digit(pos: Position): string | undefined {
        return this._digits.get(pos.toString());
    }

    allDigits(): Map<string, string> {
        return this._digits;
    }

    grid(): string {

        let grid = '';

        for (let row = 1; row <= this._numRows; row++) {

            if (grid.length > 0) grid += '\n';

            for (let col = 1; col <= this._numCols; col++) {

                const pos = Position.fromRowCol(row, col);
                const digit = this.digit(pos);
                const square = this.isSquare(pos) ? '.' : '#';

                grid += digit || square;
            }
        }

        return grid;
    }

    static fromEmpty(): SudokuDef {
        return new SudokuDef(0, SudokuInfo.fromEmpty(), 0, 0, PositionSet.fromEmpty(), new Map<string, string>(), SudokuConstraints.fromEmpty());
    }

    static fromData(revision: number, info: SudokuInfo, numRows: number, numCols: number, squares: PositionSet, digits: Map<string, string>, constraints: SudokuConstraints): SudokuDef {
        return new SudokuDef(revision, info, numRows, numCols, squares, digits, constraints);
    }

    static fromGrid(revision: number, info: SudokuInfo, grid: string, constraints: SudokuConstraints): SudokuDef {

        const squares: Position[] = [];
        const digits = new Map<string, string>();
        let row = 1;
        let col = 1;
        let numCols = 0;
        let numRows = 0;

        // standardise newline
        grid = grid.trim() + '\n';

        for (let i = 0; i < grid.length; i++) {

            const pos = Position.fromRowCol(row, col);
            const posId = pos.toString();

            if (grid[i] === ' ' || grid[i] === '\t') {
                // skip whitespace
            }
            else if (grid[i] === '\n') {
                // new row
                numCols = Math.max(numCols, col);
                row += 1;
                col = 1;
            }
            else if (grid[i] === '#') {
                // void cell
                col += 1;
            }
            else if (grid[i] === '.' || grid[i] === '0') {
                // empty square
                squares.push(pos);
                col += 1;
            }
            else if (grid[i] >= '1' && grid[i] <= '9') {
                // fixed square
                digits.set(posId, grid[i]);
                squares.push(pos);
                col += 1;
            }
            else {
                // invalid character
                throw new SudokuError(`Def: Invalid grid character (${grid[i]})`);
            }
        }

        numCols = Math.max(numCols, col) - 1;
        numRows = Math.max(numRows, row) - 1;

        return new SudokuDef(revision, info, numRows, numCols, PositionSet.fromArray(squares), digits, constraints);
    }

    toString(): string {
        return this.grid();
    }

    setDigit(squares: PositionSet, digit: string): SudokuDef {
        const newDigits = new Map<string, string>(this._digits);
        let isChanged = false;

        for (const pos of squares.toArray()) {
            if (this.setDigitImpl(newDigits, pos, digit)) isChanged = true;
        }

        return isChanged ? new SudokuDef(this._revision + 1, this._info, this._numRows, this._numCols, this._squares, newDigits, this._constraints) : this;
    }

    clearDigit(squares: PositionSet): SudokuDef {
        const newDigits = new Map<string, string>(this._digits);
        let isChanged = false;

        for (const pos of squares.toArray()) {
            if (this.clearDigitImpl(newDigits, pos)) isChanged = true;
        }

        return isChanged ? new SudokuDef(this._revision + 1, this._info, this._numRows, this._numCols, this._squares, newDigits, this._constraints) : this;
    }

    setInfo(newInfo: SudokuInfo, incrementRevision = true): SudokuDef {
        const revision = incrementRevision ? this._revision + 1 : this._revision;

        return new SudokuDef(revision, newInfo, this._numRows, this._numCols, this._squares, this._digits, this._constraints);
    }

    setConstraints(newConstraints: SudokuConstraints, incrementRevision = true): SudokuDef {
        const revision = incrementRevision ? this._revision + 1 : this._revision;

        return new SudokuDef(revision, this._info, this._numRows, this._numCols, this._squares, this._digits, newConstraints);
    }

    private setDigitImpl(newDigits: Map<string, string>, pos: Position, digit: string): boolean {

        // dont update non-squares squares
        if (!this.isSquare(pos)) return false;

        // set digit
        const posId = pos.toString();
        if (newDigits.get(posId) === digit) {
            newDigits.delete(posId);
        }
        else {
            newDigits.set(posId, digit);
        }

        return true;
    }

    private clearDigitImpl(newDigits: Map<string, string>, pos: Position): boolean {

        // dont update non-squares squares
        if (!this.isSquare(pos)) return false;
        if (!this.digit(pos)) return false;

        // clear digit
        const posId = pos.toString();
        newDigits.delete(posId);

        return true;
    }
}