import memoize from 'memoize-one';
import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import Position from 'sudokuku-common/src/core/Position';
import PositionSet from 'sudokuku-common/src/core/PositionSet';
import SudokuChange, { SudokuChangeTarget } from 'sudokuku-common/src/core/SudokuChange';
import SudokuDef from 'sudokuku-common/src/core/SudokuDef';
import SudokuState from 'sudokuku-common/src/core/SudokuState';
import ViolationState from 'sudokuku-common/src/core/ViolationState';
import SudokuApi from '../api/v1/SudokuApi';
import Viewport from '../components/Viewport';
import SudokuStorage from '../storage/SudokuStorage';
import UIMenu, { UIMenuProps } from '../ui-menu/UIMenu';
import UIMenuButton from '../ui-menu/UIMenuButton';
import UIMenuDivider from '../ui-menu/UIMenuDivider';
import UIMenuHamburger from '../ui-menu/UIMenuHamburger';
import UIMenuLink from '../ui-menu/UIMenuLink';
import UIRoot from '../ui-shell/UIRoot';
import Grid from './components/grid/Grid';
import GridClipboard from './components/grid/GridClipboard';
import GridEvents, { GridEventsFlags } from './components/grid/GridEvents';
import UIError from './components/ui/UIError';
import UIInputDigits from './components/ui/UIInputDigits';
import UIInputInfo from './components/ui/UIInputInfo';
import UIInputMode from './components/ui/UIInputMode';
import UIInputStatus from './components/ui/UIInputStatus';
import ConfigState from './model/ConfigState';
import HintState from './model/HintState';
import InputState, { InputMode } from './model/InputState';
import SelectionState from './model/SelectionState';
import StatusState, { StatusFlags } from './model/StatusState';
import ViewState from './model/ViewState';
import ConfigStorage from './storage/ConfigStorage';
import ViewStorage from './storage/ViewStorage';
import './UIPlay.css';

export type UIPlayParams = {
    id: string
}

export type UIPlayProps = UIMenuProps & RouteComponentProps<UIPlayParams> & {
}

export type UIPlayState = {
    sudoku: SudokuState,
    selection: SelectionState,
    input: InputState,
    hint: HintState,
    status: StatusState,
    config: ConfigState,
    view: ViewState,
}

export default class UIPlay extends React.PureComponent<UIPlayProps, UIPlayState> {

    private readonly _gridRef: React.RefObject<HTMLDivElement>;

    constructor(props: UIPlayProps) {
        super(props);

        const sudokuDef = SudokuDef.fromEmpty();

        this.state = {
            sudoku: SudokuState.fromDef(sudokuDef),
            selection: SelectionState.fromDef(sudokuDef),
            input: InputState.fromDefault(),
            hint: HintState.fromDefault(),
            status: StatusState.fromDefault(),
            config: ConfigState.fromDefault(),
            view: ViewState.fromDefault(),
        };

        this._gridRef = React.createRef<HTMLDivElement>();

        this.handleKeyDown = this.handleKeyDown.bind(this);
        this.handlePointerDown = this.handlePointerDown.bind(this);
        this.handlePointerDrag = this.handlePointerDrag.bind(this);
        this.handlePointerLongClick = this.handlePointerLongClick.bind(this);
        this.handlePointerUp = this.handlePointerUp.bind(this);
        this.handleInputClick = this.handleInputClick.bind(this);
        this.handleMenuClick = this.handleMenuClick.bind(this);
    }

    override componentDidMount(): void {

        document.title = this.title(UIRoot.Title);

        document.addEventListener("keydown", this.handleKeyDown);

        this.loadLocalConfig();
        this.loadLocalView();

        if (this.loadLocalSudoku()) {
            this.checkServerSudokuRevision().then(() =>
                this.loadServerSolution()
            )
        }
        else {
            this.loadServerSudoku().then(() =>
                this.loadServerSolution()
            );
        }
    }

    override componentWillUnmount(): void {
        document.removeEventListener("keydown", this.handleKeyDown);
    }

    override componentDidUpdate(prevProps: UIPlayProps, prevState: UIPlayState): void {

        // set title
        if (this.state.sudoku.def().info() !== prevState.sudoku.def().info()) {
            document.title = this.title(UIRoot.Title);
        }

        // save state
        if (this.state.sudoku !== prevState.sudoku && !this.state.sudoku.isEmpty()) {
            SudokuStorage.saveSudoku(this.props.location.pathname, this.state.sudoku);
        }

        // save view
        if (this.state.view !== prevState.view) {
            ViewStorage.saveView(this.props.location.pathname, this.state.view);
        }
    }

    private validateConstraints = memoize((sudoku: SudokuState) => UIPlay.validateConstraints(sudoku));

    private isComplete = memoize((sudoku: SudokuState) => sudoku.isComplete());

    override render(): React.ReactNode {

        const sudokuId = this.props.match.params.id;
        const sudoku = this.state.sudoku;
        const selection = this.state.selection;
        const mode = this.state.input.mode();
        const hint = this.state.hint;
        const config = this.state.config;
        const view = this.state.view;
        let status = this.state.status;

        // validate sudoku
        let doValidation = true;
        if (config.validate() === 'never') doValidation = false;
        if (config.validate() === 'complete') doValidation = this.isComplete(sudoku);

        // TODO: We shouldn't be setting status flags here!
        const violations = doValidation ? this.validateConstraints(sudoku) : ViolationState.fromEmpty();
        if (violations.hasViolations()) status = status.setFlags(StatusFlags.HasViolations);
        if (this.isComplete(sudoku)) status = status.setFlags(StatusFlags.IsComplete);

        // determine validation config text
        let validateConfigText = 'Disable auto validation';
        if (config.validate() === 'never') validateConfigText = 'Enable auto validation';
        if (config.validate() === 'complete') validateConfigText = 'Enable auto validation';

        if (status.hasFlags(StatusFlags.IsError)) {
            return (
                <div className="UIPlay">
                    <UIError status={status} />
                    <UIMenu {...this.props}>
                        <UIMenuHamburger {...this.props} />
                        <UIMenuLink {...this.props} to="/">Home</UIMenuLink>
                    </UIMenu>
                </div>
            );
        }

        return (
            <div className="UIPlay">
                <UIInputInfo orientation="portrait" sudoku={sudoku} view={view} onClick={this.handleInputClick} />
                <div className="UIPlayGrid" ref={this._gridRef}>
                    <Viewport>
                        <GridEvents onPointerDown={this.handlePointerDown} onPointerDrag={this.handlePointerDrag} onPointerLongClick={this.handlePointerLongClick} onPointerUp={this.handlePointerUp} >
                            <Grid sudoku={sudoku} selection={selection} violations={violations} />
                        </GridEvents>
                    </Viewport>
                </div>
                <div className="UIPlayInput">
                    <UIInputInfo orientation="landscape" sudoku={sudoku} view={view} onClick={this.handleInputClick} />
                    <UIInputMode mode={mode} onClick={this.handleInputClick} />
                    <UIInputDigits mode={mode} config={config} onClick={this.handleInputClick} />
                    <UIInputStatus status={status} hint={hint} onClick={this.handleInputClick} />
                </div>
                <UIMenu {...this.props}>
                    <UIMenuHamburger {...this.props} />
                    <UIMenuLink {...this.props} to="/">Home</UIMenuLink>
                    <UIMenuDivider {...this.props} />
                    <UIMenuButton {...this.props} id="Menu-Edit" onClick={this.handleMenuClick}>Edit Puzzle</UIMenuButton>
                    <UIMenuButton {...this.props} id="Menu-Reset" onClick={this.handleMenuClick}>Reset Puzzle</UIMenuButton>
                    <UIMenuDivider {...this.props} />
                    <UIMenuButton {...this.props} id="Menu-Keypad" onClick={this.handleMenuClick}>Flip Keypad</UIMenuButton>
                    <UIMenuButton {...this.props} id="Menu-Validate" onClick={this.handleMenuClick}>{validateConfigText}</UIMenuButton>
                    <UIMenuDivider {...this.props} />
                    <UIMenuButton {...this.props} id="Menu-CopyText" onClick={this.handleMenuClick}>Copy selected digits</UIMenuButton>
                    <UIMenuDivider {...this.props} />
                    <UIMenuLink {...this.props} to={`/download/${sudokuId}.pdf`} target="_blank">Download PDF</UIMenuLink>
                    <UIMenuLink {...this.props} to={`/download/${sudokuId}.png`} target="_blank">Download PNG</UIMenuLink>
                    <UIMenuDivider />
                    <UIMenuLink to='/terms' target="_blank" {...this.props}>Terms</UIMenuLink>
                    <UIMenuLink to='/privacy' target="_blank" {...this.props}>Privacy</UIMenuLink>
                </UIMenu>
            </div>
        );
    }

    private title(title: string): string {

        const info = this.state.sudoku.def().info();

        const sudokuTitle = info.title();
        if (sudokuTitle) {
            title += ' - ';
            title += sudokuTitle;
        }

        const sudokuAuthor = info.author();
        if (sudokuTitle && sudokuAuthor) {
            title += ' by ';
            title += sudokuAuthor;
        }

        return title;
    }

    private static validateConstraints(sudoku: SudokuState): ViolationState {
        let violations = ViolationState.fromEmpty();

        for (const constraint of sudoku.def().constraints().all()) {
            violations = constraint.validate(sudoku, violations);
        }

        return violations;
    }

    private handleKeyDown(event: KeyboardEvent) {

        function setSelection(s: UIPlayState, isMulti: boolean, move: () => Position) {

            const square = s.selection.nextSelectableSquare(s.selection.primary(), move);
            if (!square) return null;

            // undo the selection when going backwards
            if (isMulti) {
                const prev = s.selection.prev();
                if (prev && square.isEqual(prev.primary())) {
                    return { selection: prev };
                }
            }

            if (isMulti) {
                return { selection: s.selection.addSelection(square, s.selection) };
            }
            else {
                return { selection: s.selection.setSelection(square) };
            }
        }

        // ktc app:
        //  ctrl+click:  add/remove from selection
        //  ctrl+arrow:  add to selection
        //  space:       next mode
        //  ctrl+space:  prev mode
        //  z:           digit mode
        //  x:           corner mode
        //  c:           center mode
        //  v:           color mode
        //  ctrl+digit:  center
        //  shift+digit: corner
        //  c+s+digit:   color
        //  ctrl+z       undo
        //  ctrl+y       redo

        // Play Mode
        //  https://www.sudokuku.com/play?id=dsjdhw87
        //  - Save solve in progress (on timer, or on change?)
        //  - Save def & state on client (version stamped)
        //  - Restart reloads def from server and clears state (no undo!)
        //  - URL contains puzzle id
        //  - Copy URL to share, no login required
        //  - Login to see all solved puzzles
        //  - Login to comment on puzzle
        //  - Config stored on client (eg: show timer, etc)
        //
        // Create Mode
        //  - Requires login
        //  - Create new puzzle
        //      - https://www.sudokuku.com/edit?id=dsjdhw87
        //      - Size, Title, Description, Rules, etc
        //  - Automatically saves def (version stamped)
        //


        let inputMode = this.state.input.mode();
        if (event.ctrlKey) inputMode = InputMode.CenterMarks;
        if (event.shiftKey) inputMode = InputMode.CornerMarks;
        if (event.ctrlKey && event.shiftKey) inputMode = InputMode.ColorMarks;

        switch (event.code) {

            case 'ArrowDown':
                event.preventDefault();
                this.setState((s, p) => setSelection(s, event.ctrlKey || event.shiftKey, Position.prototype.down));
                break;

            case 'ArrowUp':
                event.preventDefault();
                this.setState((s, p) => setSelection(s, event.ctrlKey || event.shiftKey, Position.prototype.up));
                break;

            case 'ArrowLeft':
                event.preventDefault();
                this.setState((s, p) => setSelection(s, event.ctrlKey || event.shiftKey, Position.prototype.left));
                break;

            case 'ArrowRight':
                event.preventDefault();
                this.setState((s, p) => setSelection(s, event.ctrlKey || event.shiftKey, Position.prototype.right));
                break;

            case 'Digit1':
            case 'Digit2':
            case 'Digit3':
            case 'Digit4':
            case 'Digit5':
            case 'Digit6':
            case 'Digit7':
            case 'Digit8':
            case 'Digit9':
            case 'Numpad1':
            case 'Numpad2':
            case 'Numpad3':
            case 'Numpad4':
            case 'Numpad5':
            case 'Numpad6':
            case 'Numpad7':
            case 'Numpad8':
            case 'Numpad9':
                event.preventDefault();
                this.setState((s, p) => UIPlay.setDigit(s, inputMode, event.key));
                break;

            case 'Backspace':
            case 'Delete':
                event.preventDefault();
                this.setState((s, p) => UIPlay.clearDigit(s, inputMode));
                break;

            case 'Space':
                if (event.ctrlKey || event.shiftKey) {
                    event.preventDefault();
                    this.setState((s, p) => UIPlay.setPrevInputMode(s));
                }
                else {
                    event.preventDefault();
                    this.setState((s, p) => UIPlay.setNextInputMode(s));
                }
                break;

            case 'KeyA':
                if (event.ctrlKey || event.shiftKey || event.metaKey) {
                    event.preventDefault();
                    this.setState((s, p) => UIPlay.selectAll(s));
                }
                break;

            case 'KeyZ':
                if (event.ctrlKey) {
                    event.preventDefault();
                    this.setState((s, p) => UIPlay.undo(s));
                }
                else if (event.metaKey && !event.shiftKey) {
                    event.preventDefault();
                    this.setState((s, p) => UIPlay.undo(s));
                }
                else if (event.metaKey && event.shiftKey) {
                    event.preventDefault();
                    this.setState((s, p) => UIPlay.redo(s));
                }
                else {
                    event.preventDefault();
                    this.setState((s, p) => UIPlay.setInputMode(s, InputMode.Digits));
                }
                break;

            case 'KeyX':
                if (!event.ctrlKey) {
                    event.preventDefault();
                    this.setState((s, p) => UIPlay.setInputMode(s, InputMode.CornerMarks));
                }
                break;

            case 'KeyC':
                if (event.ctrlKey || event.metaKey) {
                    GridClipboard.copyAsText(this.state.sudoku, this.state.selection.squares());
                }
                else {
                    event.preventDefault();
                    this.setState((s, p) => UIPlay.setInputMode(s, InputMode.CenterMarks));
                }
                break;

            case 'KeyV':
                if (!event.ctrlKey) {
                    event.preventDefault();
                    this.setState((s, p) => UIPlay.setInputMode(s, InputMode.ColorMarks));
                }
                break;

            case 'KeyY':
                if (event.ctrlKey) {
                    event.preventDefault();
                    this.setState((s, p) => UIPlay.redo(s));
                }
                break;

            case 'KeyH':
                if (!event.ctrlKey) {
                    event.preventDefault();
                    this.setState((s, p) => UIPlay.hint(s));
                }
                break;
        }
    }

    private handlePointerDown(element: Element | null, flags: GridEventsFlags) {

        function setSelection(s: UIPlayState, isMulti: boolean, square: Position) {
            if (isMulti) {
                return { selection: s.selection.toggleSelection(square) }
            }
            else {
                return { selection: s.selection.setSelection(square) }
            }
        }

        const squareElement = element as HTMLDivElement;
        if (!squareElement) return;
        if (!squareElement.id) return;

        const isMulti = (flags & GridEventsFlags.IsMulti) !== 0;

        this.setState((s, p) => setSelection(s, isMulti, Position.fromString(squareElement.id)));
    }

    private handlePointerUp(element: Element | null, flags: GridEventsFlags) {
        // do nothing
    }

    private handlePointerDrag(element: Element | null, flags: GridEventsFlags) {

        function addSelection(s: UIPlayState, square: Position) {
            return { selection: s.selection.addSelection(square) }
        }

        const squareElement = element as HTMLDivElement;
        if (!squareElement) return;
        if (!squareElement.id) return;

        const square = Position.fromString(squareElement.id);

        this.setState((s, p) => addSelection(s, square));
    }

    private handlePointerLongClick(element: Element, flags: GridEventsFlags) {

        // get selected square
        const squareElement = element as HTMLDivElement;
        if (!squareElement) return;
        if (!squareElement.id) return;

        // get flags
        const isMulti = (flags & GridEventsFlags.IsMulti) !== 0;

        // check selection has not changed
        const square = Position.fromString(squareElement.id);
        if (!square.isEqual(this.state.selection.primary())) return;

        // check selection contains a digit
        const sudoku = this.state.sudoku;
        const selectedDigit = sudoku.digit(square);
        if (!selectedDigit) return;

        // find all positions with the same digit
        const squares = sudoku.digitSquares(selectedDigit);

        // set selection
        this.setState((s, p) => UIPlay.selectMulti(s, isMulti, square, squares));
    }

    private handleInputClick(event: React.MouseEvent<HTMLButtonElement>) {
        const inputMode = this.state.input.mode();
        let id = event.currentTarget.id;
        let key = '';

        if (id.startsWith('InputRule-')) {
            key = id.substring(10);
            id = 'InputRule'
        }

        switch (id) {
            case 'Input1':
            case 'Input2':
            case 'Input3':
            case 'Input4':
            case 'Input5':
            case 'Input6':
            case 'Input7':
            case 'Input8':
            case 'Input9':
                event.preventDefault();
                this.setState((s, p) => UIPlay.setDigit(s, inputMode, id.substring(id.length - 1)));
                break;

            case 'InputClear':
                event.preventDefault();
                this.setState((s, p) => UIPlay.clearDigit(s, inputMode));
                break;

            case 'InputUndo':
                event.preventDefault();
                this.setState((s, p) => UIPlay.undo(s));
                break;

            case 'InputRedo':
                event.preventDefault();
                this.setState((s, p) => UIPlay.redo(s));
                break;

            case 'InputHint':
                event.preventDefault();
                this.setState((s, p) => UIPlay.hint(s));
                break;

            case 'InputDigits':
                event.preventDefault();
                this.setState((s, p) => UIPlay.setInputMode(s, InputMode.Digits));
                break;

            case 'InputCenterMarks':
                event.preventDefault();
                this.setState((s, p) => UIPlay.setInputMode(s, InputMode.CenterMarks));
                break;

            case 'InputCornerMarks':
                event.preventDefault();
                this.setState((s, p) => UIPlay.setInputMode(s, InputMode.CornerMarks));
                break;

            case 'InputColorMarks':
                event.preventDefault();
                this.setState((s, p) => UIPlay.setInputMode(s, InputMode.ColorMarks));
                break;

            case 'InputNewerRevisionAvailable':
                event.preventDefault();
                this.mergeServerSudokuRevision();
                break;

            case 'InputTitle':
                event.preventDefault();
                this.setState((s, p) => UIPlay.toggleInfoBox(s));
                break;

            case 'InputRule':
                event.preventDefault();
                this.setState((s, p) => UIPlay.showConstraintRule(s, key, event.shiftKey || event.ctrlKey));
                break;
        }
    }

    private handleMenuClick(event: React.MouseEvent<HTMLButtonElement>) {
        const id = event.currentTarget.id;

        switch (id) {

            case 'Menu-Reset': {
                this.loadServerSudoku(true).then(() =>
                    this.loadServerSolution()
                );
                break;
            }

            case 'Menu-Edit': {
                this.props.history.push(`/edit/${this.props.match.params.id}`);
                break;
            }

            case 'Menu-Keypad': {
                let config = this.state.config;

                switch (config.keypad()) {
                    case 'phone': config = config.setKeypad('numpad'); break;
                    case 'numpad': config = config.setKeypad('phone'); break;
                    default: config = config.setKeypad('numpad'); break;
                }

                this.saveLocalConfig(config);
                break;
            }

            case 'Menu-Validate': {
                let config = this.state.config;

                switch (config.validate()) {
                    case 'always': config = config.setValidate('complete'); break;
                    case 'never': config = config.setValidate('always'); break;
                    case 'complete': config = config.setValidate('always'); break;
                    default: config = config.setValidate('complete'); break;
                }

                this.saveLocalConfig(config);
                break;
            }

            case 'Menu-CopyText':
                GridClipboard.copyAsText(this.state.sudoku, this.state.selection.squares());
                event.preventDefault();
                break;
        }
    }

    private static setDigit(s: UIPlayState, mode: InputMode, digit: string) {

        switch (mode) {
            case InputMode.Digits:
                return { sudoku: this.setDigitImpl(s.sudoku, s.selection.squares(), digit) };

            case InputMode.CenterMarks:
                return { sudoku: s.sudoku.toggleCenterMark(s.selection.squares(), digit) };

            case InputMode.CornerMarks:
                return { sudoku: s.sudoku.toggleCornerMark(s.selection.squares(), digit) };

            case InputMode.ColorMarks:
                return { sudoku: s.sudoku.toggleColorMark(s.selection.squares(), digit) };

            default:
                return null;
        }
    }

    private static clearDigit(s: UIPlayState, mode: InputMode) {

        switch (mode) {
            case InputMode.Digits:
                return { sudoku: this.clearDigitImpl(s.sudoku, s.selection.squares(), 'digit') };

            case InputMode.CenterMarks:
                return { sudoku: this.clearDigitImpl(s.sudoku, s.selection.squares(), 'center') };

            case InputMode.CornerMarks:
                return { sudoku: this.clearDigitImpl(s.sudoku, s.selection.squares(), 'corner') };

            case InputMode.ColorMarks:
                return { sudoku: this.clearDigitImpl(s.sudoku, s.selection.squares(), 'color') };

            default:
                return null;
        }
    }

    private static setDigitImpl(sudoku: SudokuState, squares: PositionSet, digit: string): SudokuState {
        const mutableSquares = squares.filter(s => sudoku.isSquare(s) && !sudoku.isFixed(s));

        const all = mutableSquares.filter(s => sudoku.digit(s) === digit);
        if (all.size() === mutableSquares.size()) {
            return sudoku.clearDigit(squares);
        }
        else {
            return sudoku.setDigit(squares, digit);
        }
    }

    private static clearDigitImpl(sudoku: SudokuState, squares: PositionSet, target: SudokuChangeTarget): SudokuState {
        let newSudoku = sudoku;

        // clear target digits
        newSudoku = sudoku.applyChange(SudokuChange.set(target, squares, ''));
        if (newSudoku !== sudoku) return newSudoku;

        // clear color marks
        newSudoku = sudoku.applyChange(SudokuChange.set('color', squares, ''));
        if (newSudoku !== sudoku) return newSudoku;

        // clear corner marks
        newSudoku = sudoku.applyChange(SudokuChange.set('corner', squares, ''));
        if (newSudoku !== sudoku) return newSudoku;

        // clear center marks
        newSudoku = sudoku.applyChange(SudokuChange.set('center', squares, ''));
        if (newSudoku !== sudoku) return newSudoku;

        // clear digits
        newSudoku = sudoku.applyChange(SudokuChange.set('digit', squares, ''));
        if (newSudoku !== sudoku) return newSudoku;

        // done
        return sudoku;
    }

    private static selectMulti(s: UIPlayState, isMulti: boolean, primary: Position | undefined, squares: PositionSet) {
        if (isMulti) {
            return { selection: s.selection.addMultiSelection(primary, squares.toArray()) }
        } else {
            return { selection: s.selection.setMultiSelection(primary, squares.toArray()) }
        }
    }

    private static selectAll(s: UIPlayState) {
        const primary = s.selection.primary() || s.selection.firstSelectableSquare();
        const squares = s.sudoku.allSquares().toArray();

        return { selection: s.selection.setMultiSelection(primary, squares) };
    }

    private static undo(s: UIPlayState) {
        return { sudoku: s.sudoku.undo() };
    }

    private static redo(s: UIPlayState) {
        return { sudoku: s.sudoku.redo() };
    }

    private static hint(s: UIPlayState) {
        const hint = s.hint.nextHint(s.sudoku, s.selection);
        const sudoku = hint.hintSudoku(s.sudoku);
        const selection = hint.hintSelection(s.selection);

        return ({
            hint: hint,
            sudoku: sudoku,
            selection: selection,
        });
    }

    private static setInputMode(s: UIPlayState, mode: InputMode) {
        return { input: s.input.setMode(mode) };
    }

    private static setNextInputMode(s: UIPlayState) {
        return { input: s.input.nextMode() };
    }

    private static setPrevInputMode(s: UIPlayState) {
        return { input: s.input.prevMode() };
    }

    private static toggleInfoBox(s: UIPlayState) {
        return { view: s.view.toggleInfo() };
    }

    private static showConstraintRule(s: UIPlayState, key: string, isMulti: boolean) {
        const constraint = s.sudoku.def().constraints().find(c => c.key() === key);
        if (!constraint) return null;

        const square = s.selection.primary() || s.sudoku.def().squares().middle();
        if (!square) return null;

        const squares = constraint.rulesExample(s.sudoku, square);
        const primary = s.selection.primary() || squares.middle();

        return UIPlay.selectMulti(s, isMulti, primary, squares);
    }

    private loadLocalConfig(): boolean {
        const config = ConfigStorage.loadConfig();
        if (!config) return false;

        this.setState({
            config: config,
        });

        return true;
    }

    private saveLocalConfig(config: ConfigState): void {

        this.setState({
            config: config,
        });

        ConfigStorage.saveConfig(config);
    }

    private loadLocalView(): boolean {
        const view = ViewStorage.loadView(this.props.location.pathname);
        if (!view) return false;

        this.setState({
            view: view,
        });

        return true;
    }

    private loadLocalSudoku(): boolean {
        const sudoku = SudokuStorage.loadSudoku(this.props.location.pathname);
        if (!sudoku) return false;

        this.setState({
            sudoku: sudoku,
            selection: SelectionState.fromSudoku(sudoku),
            input: InputState.fromDefault(),
        });

        return true;
    }

    private loadServerSudoku(canUndo = false): Promise<void> {

        this.setState((s, p) => ({
            status: s.status.setFlags(StatusFlags.IsLoading)
        }));

        const sudokuId = this.props.match.params.id;

        return SudokuApi.getSudoku(sudokuId)
            .then(sudoku =>
                this.setState((s, p) => ({
                    sudoku: canUndo ? sudoku.setPrev(s.sudoku) : sudoku,
                    selection: SelectionState.fromSudoku(sudoku),
                    status: StatusState.fromDefault(),
                })))
            .catch(e => {
                const def = SudokuDef.fromEmpty();
                this.setState((s, p) => ({
                    sudoku: SudokuState.fromDef(def),
                    selection: SelectionState.fromDef(def),
                    status: StatusState.fromError(`Could not load Sudoku (${sudokuId}).`),
                }));
            });
    }

    private loadServerSolution(): Promise<void> {

        return SudokuApi.getSolution(this.props.match.params.id)
            .then(solution =>
                this.setState((s, p) => ({
                    hint: HintState.fromSolution(solution)
                })))
            .catch(e => {
                // no solution found
            });
    }

    private checkServerSudokuRevision(): Promise<void> {
        return SudokuApi.getSudoku(this.props.match.params.id)
            .then(sudoku => {

                const localRevision = this.state.sudoku.def().revision();
                const serverRevision = sudoku.def().revision();
                if (localRevision >= serverRevision) return;

                this.setState((s, p) => ({
                    status: s.status.setFlags(StatusFlags.HasNewerRevision)
                }));
            })
            .catch(e => {
                // no revision found
            })
    }

    private mergeServerSudokuRevision() {

        this.setState((s, p) => ({
            status: s.status
                .clearFlags(StatusFlags.HasNewerRevision)
                .setFlags(StatusFlags.IsLoading)
        }));

        SudokuApi.getSudoku(this.props.match.params.id)
            .then(sudoku => {
                this.setState((s, p) => ({
                    sudoku: s.sudoku.setDef(sudoku.def()),
                    status: s.status.clearFlags(StatusFlags.IsLoading)
                }));
            })
            .catch(e => {
                // no revision found (should never happen)
                this.setState((s, p) => ({
                    status: s.status.clearFlags(StatusFlags.IsLoading)
                }));
            })
    }
}