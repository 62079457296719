import PositionSet from '../../core/PositionSet';
import BoxConstraint from './BoxConstraint';
import ConstraintDef from '../ConstraintDef';

export default class RegionConstraint extends BoxConstraint {

    private static readonly _region = new ConstraintDef('region', 'Irregular Region', 'Unique', ['Irregular Region', 'Jigsaw'], -1);

    public static override def(): ConstraintDef {
        return this._region;
    }

    public override def(): ConstraintDef {
        return RegionConstraint.def();
    }

    public override longName(): string {
        return `${this.name()} ${this.squares().first()?.toString()}`;
    }

    public override rules(): string {
        return 'Digits cannot repeat in each region.'
    }

    public override toString(): string {
        return this.squares().toString();
    }

    public static override fromString(str: string): RegionConstraint {
        return RegionConstraint.fromSquares(PositionSet.fromString(str));
    }

    public static override fromSquares(squares: PositionSet): RegionConstraint {
        return new RegionConstraint(squares);
    }
}