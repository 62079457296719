import classNames from "classnames";
import Position from "sudokuku-common/src/core/Position";
import SelectionState from "../../model/SelectionState";
import './GridSelection.css';

export type GridSelectionProps = {
    pos: Position,
    selection: SelectionState
}

export default function GridSelection(props: GridSelectionProps): React.ReactElement | null {

    const pos = props.pos;
    const squares = props.selection.squares();
    const color = props.selection.colorIndex(pos);
    const pointer = props.selection.pointerIndex(pos);

    // dont render unselected cells
    if (!squares.has(pos) && (color <= 0) && (pointer <= 0)) return null;

    const className = classNames({
        'GridSelection': true,
        'content': true,
        't': squares.isTop(pos),
        'r': squares.isRight(pos),
        'b': squares.isBottom(pos),
        'l': squares.isLeft(pos),
        'c c1': color === 1,
        'c c2': color === 2,
        'c c3': color === 3,
        'c c4': color === 4,
        'c c5': color === 5,
        'c c6': color === 6,
        'c c7': color === 7,
        'c c8': color === 8,
        'c c9': color === 9,
        'p c1': color <= 0 && pointer === 1,
        'p c2': color <= 0 && pointer === 2,
        'p c3': color <= 0 && pointer === 3,
        'p c4': color <= 0 && pointer === 4,
        'p c5': color <= 0 && pointer === 5,
        'p c6': color <= 0 && pointer === 6,
        'p c7': color <= 0 && pointer === 7,
        'p c8': color <= 0 && pointer === 8,
        'p c9': color <= 0 && pointer === 9,
    });

    return <div className={className} />;
}