import React from 'react';
import { Link } from 'react-router-dom';
import './UIHeader.css';

export type UIHeaderProps = {
}

export type UIHeaderState = {
}

export default class UIHeader extends React.PureComponent<UIHeaderProps, UIHeaderState> {
    override render(): React.ReactNode {

        return (
            <div className="UIHeader">
                <Link to='/'>Sudokuku</Link>
            </div>
        );
    }
}