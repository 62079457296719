import React from 'react';
import { GridCellFlags } from './GridCell';
import './GridFixed.css';

export type GridFixedProps = {
    id: string;
    flags: GridCellFlags;
}

export type GridFixedState = {    
}

export default class GridFixed extends React.PureComponent<GridFixedProps,GridFixedState> {

    override render(): React.ReactNode {

        if ((this.props.flags & GridCellFlags.IsFixed) === 0) return null;
        
        return <div className="GridFixed content" />;
    }
}