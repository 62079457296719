import PositionSet from '../../core/PositionSet';
import ConstraintDef from '../ConstraintDef';
import UniqueConstraint from './UniqueConstraint';

export default class DisjointConstraint extends UniqueConstraint {

    private static readonly _disjoint = new ConstraintDef('disjoint', 'Disjoint Group', 'Unique');

    public static def(): ConstraintDef {
        return this._disjoint;
    }

    override def(): ConstraintDef {
        return DisjointConstraint.def();
    }

    public longName(): string {
        return this.name();
    }

    public rules(): string {
        return 'Cells that appear in the same relative position in their box must not contain the same digit.';
    }

    public toString(): string {
        return this.squares().toString();
    }

    public static fromString(str: string): DisjointConstraint {
        return DisjointConstraint.fromSquares(PositionSet.fromString(str));
    }

    public static fromSquares(squares: PositionSet): DisjointConstraint {
        return new DisjointConstraint(squares);
    }
}
