import classNames from "classnames";
import { ConstraintProps } from "sudokuku-common/src/constraints/ConstraintReg";
import ParityEvenConstraint from "sudokuku-common/src/constraints/cell/ParityEvenConstraint";
import ParityOddConstraint from "sudokuku-common/src/constraints/cell/ParityOddConstraint";
import './ParityConstraintCell.css';

export default function ParityConstraintCell(props: ConstraintProps): React.ReactElement | null {

    const isOdd = props.constraint instanceof ParityOddConstraint;
    const isEven = props.constraint instanceof ParityEvenConstraint;

    const className = classNames({
        'ParityConstraint': true,
        'content': true,
        'even': isEven,
        'odd': isOdd,
    });

    return <div className={className} />;
}