import Position from '../../core/Position';
import PositionSet from '../../core/PositionSet';
import ConstraintDef, { ConstraintDefFlags } from '../ConstraintDef';
import UniqueConstraint from './UniqueConstraint';

export default class RowConstraint extends UniqueConstraint {

    private static readonly _row = new ConstraintDef('row', 'Row', 'Unique', [], -4, ConstraintDefFlags.IsStandard);

    private constructor(squares: PositionSet) {
        super(squares);
    }

    public static def(): ConstraintDef {
        return this._row;
    }

    public override def(): ConstraintDef {
        return RowConstraint.def();
    }

    public longName(): string {
        return `${this.name()} ${this.squares().first()?.toString()}`;
    }

    public rules(): string {
        return 'Digits cannot repeat in each row.'
    }

    public toString(): string {
        return this.squares().first()?.toString() || '';
    }

    public static fromString(str: string): RowConstraint {
        return RowConstraint.fromStart(Position.fromString(str));
    }

    public static fromStart(start: Position): RowConstraint {
        const squares: Position[] = [];

        for (let i = 0; i < 9; i++) {
            const square = start.offset(0, i);
            squares.push(square);
        }

        return new RowConstraint(PositionSet.fromArray(squares));
    }
}