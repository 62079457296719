import { ConstraintProps } from "sudokuku-common/src/constraints/ConstraintReg";
import RatioConstraint from "sudokuku-common/src/constraints/domino/RatioConstraint";
import './RatioConstraintCell.css';

export default function RatioConstraintCell(props: ConstraintProps): React.ReactElement | null {

    const constraint = props.constraint as RatioConstraint;

    const hasTopSquare = constraint.topSquare(props.pos) !== null;
    const hasLeftSquare = constraint.leftSquare(props.pos) !== null;
    if (!hasTopSquare && !hasLeftSquare) return null;

    return (
        <div className="RatioConstraint content">
            {hasTopSquare ? <div className="dot t"></div> : null}
            {hasLeftSquare ? <div className="dot l"></div> : null}
        </div>);
}