import SudokuState from '../core/SudokuState';
import SolveStep from './SolveStep';

export default class SolvePath {

    private readonly _revision: number;
    private readonly _isComplete: boolean;
    private readonly _steps: SolveStep[];

    private constructor(revision: number, isComplete: boolean, steps: SolveStep[]) {
        this._revision = revision;
        this._isComplete = isComplete;
        this._steps = steps;
    }

    public revision(): number {
        return this._revision;
    }

    public isComplete(): boolean {
        return this._isComplete;
    }

    public steps(): readonly SolveStep[] {
        return this._steps;
    }

    public static fromSudoku(sudoku: SudokuState, steps: SolveStep[]): SolvePath {
        return new SolvePath(sudoku.def().revision(), sudoku.isComplete(), steps);
    }

    public static fromData(revision: number, isComplete: boolean, steps: SolveStep[]): SolvePath {
        return new SolvePath(revision, isComplete, steps);
    }
}