import It from '../core/It';
import Position from '../core/Position';
import SudokuState from '../core/SudokuState';
import PropertySelection from '../properties/PropertySelection';
import Constraint from './Constraint';
import ConstraintDef from './ConstraintDef';

export type ConstraintProps = {
    key: string,
    pos: Position,
    sudoku: SudokuState,
    selection: unknown,
    constraint: Constraint,
}

export type ConstraintElement = unknown;

export type ConstraintFromString = (str: string) => Constraint;

export type ConstraintFromSelection = (sudoku: SudokuState, squares: Position[], selection: [PropertySelection]) => SudokuState;

export type ConstraintRender = (props: ConstraintProps) => ConstraintElement | null;

type ConstraintRegDef = {
    readonly def: ConstraintDef;
    readonly fromString?: ConstraintFromString;
    readonly fromSelection?: ConstraintFromSelection;
    readonly render?: ConstraintRender;
}

export default class ConstraintReg {

    private static readonly _regs = new Map<string, ConstraintRegDef>();
    private static _instanceKey = 0;

    public static defs(): ConstraintDef[] {
        return It(this._regs.values()).map(r => r.def).toArray();
    }

    public static registerConstructors(def: ConstraintDef, fromString: ConstraintFromString, fromSelection?: ConstraintFromSelection): ConstraintDef {
        return this.register({ def: def, fromString: fromString, fromSelection: fromSelection });
    }

    public static registerRender(def: ConstraintDef, render: ConstraintRender): ConstraintDef {
        return this.register({ def: def, render: render });
    }

    private static register(reg: ConstraintRegDef): ConstraintDef {
        const def = reg.def;

        const oldReg = this._regs.get(def.kind());
        const newReg = { ...oldReg, ...reg };
        this._regs.set(def.kind(), newReg);

        return def;
    }

    public static newInstanceKey(): string {
        return (++this._instanceKey).toString();
    }

    public static fromString(kind: string, str: string): Constraint | null {
        const reg = this._regs.get(kind);
        return (reg && reg.fromString && reg.fromString(str)) || null;
    }

    public static fromSelection(kind: string, sudoku: SudokuState, squares: Position[], selection: [PropertySelection]): SudokuState {
        const reg = this._regs.get(kind);
        return (reg && reg.fromSelection && reg.fromSelection(sudoku, squares, selection)) || sudoku;
    }

    public static hasFromSelection(kind: string): boolean {
        const reg = this._regs.get(kind);
        return reg && reg.fromSelection ? true : false;
    }

    public static render(pos: Position, sudoku: SudokuState, selection: unknown, constraint: Constraint): ConstraintElement | null {

        const reg = this._regs.get(constraint.kind());

        const props = {
            key: `${constraint.key()}-${pos.toString()}`,
            pos: pos,
            sudoku: sudoku,
            selection: selection,
            constraint: constraint
        }

        return (reg && reg.render && reg.render(props)) || null;
    }
}