import { withAuth0, WithAuth0Props } from '@auth0/auth0-react';
import React from 'react';
import AuthAudiences from 'sudokuku-common/src/auth/AuthAudiences';
import AuthRoles from 'sudokuku-common/src/auth/AuthRoles';
import AuthScopes from 'sudokuku-common/src/auth/AuthScopes';
import Auth from '../auth/Auth';
import AuthUser from '../auth/AuthUser';
import UIMenu from '../ui-menu/UIMenu';
import UIMenuButton from '../ui-menu/UIMenuButton';
import UIMenuDivider from '../ui-menu/UIMenuDivider';
import UIUserButton from './UIUserButton';
import './UIUserMenu.css';

export type UIUserMenuProps = WithAuth0Props & {
}

export type UIUserMenuState = {
    isMenuExpanded: boolean;
    authUser: AuthUser | null;
}

class UIUserMenu extends React.PureComponent<UIUserMenuProps, UIUserMenuState> {

    private _auth: Auth;

    constructor(props: UIUserMenuProps) {
        super(props);

        this._auth = new Auth(props.auth0);

        this.state = {
            isMenuExpanded: false,
            authUser: null,
        }
    }

    override componentDidMount(): void {
        this._auth = this._auth.update(this.props.auth0, undefined, u => this.setState({ authUser: u }));
    }

    override componentDidUpdate(prevProps: UIUserMenuProps, prevState: UIUserMenuState): void {
        this._auth = this._auth.update(this.props.auth0, prevProps.auth0, u => this.setState({ authUser: u }));
    }

    override render(): React.ReactNode {

        const isAdmin = this.state.authUser && this.state.authUser.hasRole(AuthRoles.Admin);

        return (
            <div className="UIUserMenu">
                <UIMenu isMenuExpanded={this.state.isMenuExpanded} onMenuExpand={e => this.handleMenuExpand(e)} onMenuCollapse={e => this.handleMenuCollapse(e)}>
                    <UIUserButton onClick={e => this.handleMenuToggle(e)} />
                    <UIMenuDivider />
                    {isAdmin ? <UIMenuButton onMenuCollapse={e => this.handleMenuCollapse(e)} onClick={() => this.handleAdminDbUpdate()}>Admin: Update DB</UIMenuButton> : null}
                    {isAdmin ? <UIMenuButton onMenuCollapse={e => this.handleMenuCollapse(e)} onClick={() => this.handleAdminDbDeleteSolutions()}>Admin: Delete Solutions</UIMenuButton> : null}
                    {isAdmin ? <UIMenuDivider /> : null}
                    <UIMenuButton onMenuCollapse={e => this.handleMenuCollapse(e)} onClick={() => this.handleLogout()}>Log Out</UIMenuButton>
                </UIMenu>
            </div >
        );
    }

    private async handleAdminDbUpdate() {

        const headers = await this._auth.fetchHeaders(AuthAudiences.Admin, AuthScopes.AdminPuzzlesWrite);
        const res = await fetch('/db-update', { headers: headers });
        const json = res.ok ? await res.json() : res.statusText;

        alert(json);
    }

    private async handleAdminDbDeleteSolutions() {

        const headers = await this._auth.fetchHeaders(AuthAudiences.Admin, AuthScopes.AdminPuzzlesWrite);
        const res = await fetch('/db-delete-solutions', { headers: headers });
        const json = res.ok ? await res.json() : res.statusText;

        alert(json);
    }

    private handleLogout() {
        this._auth.logout(window.location.origin);
    }

    private handleMenuToggle(event: React.SyntheticEvent<HTMLElement>) {
        this.setState(s => ({ isMenuExpanded: !s.isMenuExpanded }));
    }

    private handleMenuExpand(event: React.SyntheticEvent<HTMLElement>) {
        this.setState({ isMenuExpanded: true });
    }

    private handleMenuCollapse(event: React.SyntheticEvent<HTMLElement>) {
        this.setState({ isMenuExpanded: false });
    }
}

export default withAuth0(UIUserMenu);