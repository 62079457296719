import React from 'react';
import classNames from 'classnames';
import SudokuState from 'sudokuku-common/src/core/SudokuState';
import Position from 'sudokuku-common/src/core/Position';
import './GridCornerMark.css';

export type GridCornerMarkProps = {
    id: string;
    sudoku: SudokuState;
}

export type GridCornerMarkState = {
}

export default class GridCornerMark extends React.PureComponent<GridCornerMarkProps, GridCornerMarkState> {

    override render(): React.ReactNode {

        const sudoku = this.props.sudoku;
        const square = Position.fromString(this.props.id);

        const hasDigit = sudoku.hasDigit(square);
        if (hasDigit) return null;

        const cornerMark = sudoku.cornerMark(square);
        if (!cornerMark) return null;

        const className = classNames({
            'GridCornerMark': true,
            'content': true,
        });

        const element =
            <div className={className}>
                {Array.from(cornerMark).map((c, i) => <div key={c} className={`content m${i + 1}`}>{c}</div>)}
            </div>

        return element;
    }
}