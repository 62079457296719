/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */

import MultiMap from "./MultiMap";

export interface LazyIterable<V> extends Iterable<V> {
    map<R>(mapFunc: (value: V, index: number) => R): LazyIterable<R>;
    flatMap<R>(mapFunc: (value: V, index: number) => Iterable<R>): LazyIterable<R>;
    reduce<R>(initialValue: R, reduceFunc: (previousValue: R, currentValue: V, index: number) => R): R;
    filter(filterFunc: (value: V, index: number) => boolean): LazyIterable<V>;
    distinct(): LazyIterable<V>;
    distinctBy<K>(keyFunc: (value: V, index: number) => K): LazyIterable<V>;
    sort(compareFunc?: (a: V, b: V) => number): SortedIterable<V>;
    sortBy<K>(keyFunc: (value: V, index: number) => K, compareFunc?: (a: K, b: K) => number): SortedIterable<V>;
    groupBy<K>(keyFunc: (value: V, index: number) => K): LazyIterable<IterableGroup<K, V>>;
    groupByMap<K, R>(keyFunc: (value: V, index: number) => K, mapFunc: (value: V, index: number) => R): LazyIterable<IterableGroup<K, R>>;
    first(): V | undefined;
    find(findFunc: (value: V, index: number) => boolean): V | undefined;
    take(count: number): LazyIterable<V>;
    takeWhile(takeFunc: (value: V, index: number) => boolean): LazyIterable<V>;
    skip(count: number): LazyIterable<V>;
    skipWhile(skipFunc: (value: V, index: number) => boolean): LazyIterable<V>;
    append(value: Iterable<V> | V | null): LazyIterable<V>;
    prepend(value: Iterable<V> | V | null): LazyIterable<V>;
    repeat(count: number): LazyIterable<V>;
    reverse(): LazyIterable<V>;
    all(allFunc: (value: V, index: number) => boolean): boolean;
    some(someFunc: (value: V, index: number) => boolean): boolean;
    count(): number;
    isEmpty(): boolean;
    join(separator?: string): string;
    toArray(): V[];
    toString(): string;
}

export interface SortedIterable<V> extends LazyIterable<V> {
    thenBy<K>(keyFunc: (value: V, index: number) => K, compareFunc?: (a: K, b: K) => number): SortedIterable<V>;
}

export interface IterableGroup<K, V> {
    key: K;
    values: LazyIterable<V>;
}

interface WithLength {
    readonly length: number;
}

interface WithSize {
    readonly size: number;
}

export function It<V>(iterable?: Iterable<V> | V | null): LazyIterable<V> {

    function isIterable<V>(obj: any): obj is Iterable<V> {
        return typeof obj[Symbol.iterator] === 'function';
    }

    if (iterable == null) return LazyIterableImpl.fromEmpty<V>();
    if (iterable instanceof LazyIterableImpl) return iterable;
    if (typeof iterable === 'string') return LazyIterableImpl.fromValue<V>(iterable);
    if (isIterable(iterable)) return new LazyIterableImpl(() => iterable);
    return LazyIterableImpl.fromValue<V>(iterable);
}

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace It {

    export function single<V>(value: V): LazyIterable<V> {
        return LazyIterableImpl.fromValue<V>(value);
    }

    export function repeat<V>(value: V, count: number): LazyIterable<V> {
        return LazyIterableImpl.fromValue<V>(value).repeat(count);
    }
    
    export function range(value: number, count: number, step = 1): LazyIterable<number> {
        return new LazyIterableImpl(() => (function* (value: number, count: number, step: number) {
            for (let i = 0; i < count; i += 1, value += step) {
                yield value;
            }
        })(value, count, step));
    }
}

export default It;

class LazyIterableImpl<V> implements LazyIterable<V> {

    private static readonly _empty = [];

    private readonly _iterable: () => Iterable<V>;

    public constructor(iterable: () => Iterable<V>) {
        this._iterable = iterable;
    }

    public static fromEmpty<V>(): LazyIterable<V> {
        return new LazyIterableImpl(() => this._empty);
    }

    public static fromValue<V>(value: V): LazyIterable<V> {
        return new LazyIterableImpl(() => LazyIterableImpl.valueGenerator(value));
    }

    public map<R>(mapFunc: (value: V, index: number) => R): LazyIterable<R> {
        return new LazyIterableImpl<R>(() => LazyIterableImpl.mapGenerator<V, R>(this, mapFunc));
    }

    public flatMap<R>(mapFunc: (value: V, index: number) => R[]): LazyIterable<R> {
        return new LazyIterableImpl<R>(() => LazyIterableImpl.flatMapGenerator<V, R>(this, mapFunc));
    }

    public reduce<R>(initialValue: R, reduceFunc: (previousValue: R, currentValue: V, index: number) => R): R {
        let reduced = initialValue;
        let index = 0;

        for (const value of this) {
            reduced = reduceFunc(reduced, value, index++);
        }

        return reduced;
    }

    public filter(filterFunc: (value: V, index: number) => boolean): LazyIterable<V> {
        return new LazyIterableImpl<V>(() => LazyIterableImpl.filterGenerator<V>(this, filterFunc));
    }

    public distinct(): LazyIterable<V> {
        return new LazyIterableImpl<V>(() => new Set<V>(this).values());
    }

    public distinctBy<K>(keyFunc: (value: V, index: number) => K): LazyIterable<V> {
        return new LazyIterableImpl<V>(() => new Map<K, V>(this.map((v, i) => [keyFunc(v, i), v])).values());
    }

    public sort(compareFunc?: (a: V, b: V) => number): SortedIterable<V> {
        return new SortedIterableImpl<V, V>(() => this, v => v, compareFunc);
    }

    public sortBy<K>(keyFunc: (value: V, index: number) => K, compareFunc?: (a: K, b: K) => number): SortedIterable<V> {
        return new SortedIterableImpl<V, K>(() => this, keyFunc, compareFunc);
    }

    public groupBy<K>(keyFunc: (value: V, index: number) => K): LazyIterable<IterableGroup<K, V>> {
        return new LazyIterableImpl<IterableGroup<K, V>>(() => LazyIterableImpl.groupGenerator(MultiMap.groupBy(this, keyFunc)));
    }

    public groupByMap<K, R>(keyFunc: (value: V, index: number) => K, mapFunc: (value: V, index: number) => R): LazyIterable<IterableGroup<K, R>> {
        return new LazyIterableImpl<IterableGroup<K, R>>(() => LazyIterableImpl.groupGenerator(MultiMap.groupByMap(this, keyFunc, mapFunc)));
    }

    private static *groupGenerator<V, K>(map: MultiMap<K, V>): Generator<IterableGroup<K, V>> {
        for (const [key, values] of map.entries()) {
            yield { key: key, values: It(values) };
        }
    }

    public first(): V | undefined {
        return this.find(() => true);
    }

    public find(findFunc: (value: V, index: number) => boolean): V | undefined {
        let index = 0;

        for (const value of this) {
            if (findFunc(value, index++)) return value;
        }

        return undefined;
    }

    public take(count: number): LazyIterable<V> {
        return this.takeWhile((v, i) => i < count);
    }

    public takeWhile(takeFunc: (value: V, index: number) => boolean): LazyIterable<V> {
        return new LazyIterableImpl<V>(() => LazyIterableImpl.takeGenerator(this, takeFunc));
    }

    public skip(count: number): LazyIterable<V> {
        return this.skipWhile((v, i) => i < count);
    }

    public skipWhile(skipFunc: (value: V, index: number) => boolean): LazyIterable<V> {
        return new LazyIterableImpl<V>(() => LazyIterableImpl.skipGenerator(this, skipFunc));
    }

    public append(value: Iterable<V> | V | null): LazyIterable<V> {
        return new LazyIterableImpl<V>(() => LazyIterableImpl.concatGenerator(this, It(value)));
    }

    public prepend(value: Iterable<V> | V | null): LazyIterable<V> {
        return new LazyIterableImpl<V>(() => LazyIterableImpl.concatGenerator(It(value), this));
    }

    public repeat(count: number): LazyIterable<V> {
        return new LazyIterableImpl<V>(() => LazyIterableImpl.repeatGenerator(this, count));
    }

    public reverse(): LazyIterable<V> {
        return new LazyIterableImpl<V>(() => this.toArray().reverse());
    }

    public all(allFunc: (value: V, index: number) => boolean): boolean {
        return !this.some((v, i) => !allFunc(v, i));
    }

    public some(someFunc: (value: V, index: number) => boolean): boolean {
        let index = 0;

        for (const value of this) {
            if (someFunc(value, index++)) return true;
        }

        return false;
    }

    public count(): number {

        // convert to real iterator
        const iterable = this.iterable()();

        // try fast options
        if (Array.isArray(iterable)) return iterable.length;
        if (LazyIterableImpl.hasSize(iterable)) return iterable.size;
        if (LazyIterableImpl.hasLength(iterable)) return iterable.length;

        // try the slow option
        let index = 0;
        for (const value of iterable) index++;

        // done
        return index;
    }

    public isEmpty(): boolean {
        return this.first() === undefined;
    }

    public join(separator?: string): string {
        return Array.from(this).join(separator);
    }

    public toArray(): V[] {
        return Array.from(this);
    }

    public toString(): string {
        return this.join();
    }

    protected iterable(): () => Iterable<V> {
        return this._iterable;
    }

    [Symbol.iterator](): Iterator<V> {
        return this._iterable()[Symbol.iterator]();
    }

    private static *filterGenerator<V>(iterable: Iterable<V>, filterFunc: (value: V, index: number) => boolean): Generator<V> {
        let index = 0;

        for (const value of iterable) {
            if (filterFunc(value, index++)) yield value;
        }
    }

    private static *mapGenerator<V, R>(iterable: Iterable<V>, mapFunc: (value: V, index: number) => R): Generator<R> {
        let index = 0;

        for (const value of iterable) {
            yield mapFunc(value, index++);
        }
    }

    private static *flatMapGenerator<V, R>(iterable: Iterable<V>, mapFunc: (value: V, index: number) => R[]): Generator<R> {
        let index = 0;

        for (const value of iterable) {
            for (const mapped of mapFunc(value, index++)) {
                yield mapped;
            }
        }
    }

    private static *valueGenerator<V>(value: V): Generator<V> {
        yield value;
    }

    private static * takeGenerator<V>(iterable: Iterable<V>, takeFunc: (value: V, index: number) => boolean): Iterable<V> {
        let index = 0;

        for (const value of iterable) {
            if (!takeFunc(value, index++)) break;
            yield value;
        }
    }

    private static * skipGenerator<V>(iterable: Iterable<V>, skipFunc: (value: V, index: number) => boolean): Iterable<V> {
        let index = 0;
        let skip = true;

        for (const value of iterable) {
            skip = skip && skipFunc(value, index++);
            if (!skip) yield value;
        }
    }

    private static * concatGenerator<V>(first: Iterable<V>, second: Iterable<V>): Iterable<V> {
        yield* first;
        yield* second;
    }

    private static * repeatGenerator<V>(iterable: Iterable<V>, count: number): Iterable<V> {
        for (let i = 0; i < count; i++) {
            yield* iterable;
        }
    }

    private static hasLength(obj: any): obj is WithLength {
        return typeof obj.length === 'number';
    }

    private static hasSize(obj: any): obj is WithSize {
        return typeof obj.size === 'number';
    }
}

class SortedIterableImpl<V, K> extends LazyIterableImpl<V> implements SortedIterable<V> {

    private readonly _keyFunc: (value: V, index: number) => K;
    private readonly _compareFunc: (a: K, b: K) => number;
    private _sorted: [K, V][] | undefined;

    public constructor(iterable: () => Iterable<V>, keyFunc: (value: V, index: number) => K, compareFunc?: (a: K, b: K) => number) {
        super(iterable);

        this._keyFunc = keyFunc;
        this._compareFunc = compareFunc ?? SortedIterableImpl.defaultCompare;
    }

    public thenBy<KK>(keyFunc: (value: V, index: number) => KK, compareFunc?: (a: KK, b: KK) => number): SortedIterable<V> {

        const compareThenFunc = compareFunc ?? SortedIterableImpl.defaultCompare;

        return new SortedIterableImpl<V, [K, KK]>(
            super.iterable(),
            (v, i) => [this._keyFunc(v, i), keyFunc(v, i)],
            (a, b) => this._compareFunc(a[0], b[0]) || compareThenFunc(a[1], b[1]));
    }

    private static defaultCompare(a: any, b: any): number {
        if (a > b) return +1;
        if (a < b) return -1;
        return 0;
    }

    private sorted(): [K, V][] {

        // already sorted?
        if (this._sorted) return this._sorted;

        // convert to real iterator
        const iterable = this.iterable()();

        // iterate into an array
        const array = Array.from<V, [K, V]>(iterable, (v, i) => [this._keyFunc(v, i), v]);

        // sort array
        this._sorted = array.sort((a, b) => this._compareFunc(a[0], b[0]));

        // return sorted
        return this._sorted;
    }

    override[Symbol.iterator](): Iterator<V> {

        // map value out of sorted array
        const sorted = this.sorted();
        const iterable = It(sorted).map(v => v[1]);

        // get iterator
        return iterable[Symbol.iterator]();
    }
}