export default class Position {

    private readonly _pos: string;

    private constructor(pos: string) {
        this._pos = pos;
    }

    static fromString(pos: string): Position {
        return new Position(pos);
    }

    static fromRowCol(row: number, col: number): Position {
        return new Position(this.posId(row, col));
    }

    static rowId(row: number): string {
        return String.fromCharCode('a'.charCodeAt(0) + row - 1);
    }

    static colId(col: number): string {
        return col.toString();
    }

    static posId(row: number, col: number): string {
        return this.rowId(row) + this.colId(col);
    }

    toString(): string {
        return this._pos;
    }

    isEqual(other: Position | undefined): boolean {
        return (other !== undefined) && (other._pos === this._pos);
    }

    row(): number {
        return this._pos.charCodeAt(0) - 'a'.charCodeAt(0) + 1;
    }

    col(): number {
        return Number.parseInt(this._pos.substring(1));
    }

    offset(dr: number, dc: number): Position {
        const row = this.row() + dr;
        const col = this.col() + dc;

        return Position.fromRowCol(row, col);
    }

    up(): Position {
        return this.offset(-1, 0);
    }

    down(): Position {
        return this.offset(+1, 0);
    }

    left(): Position {
        return this.offset(0, -1);
    }

    right(): Position {
        return this.offset(0, +1);
    }

    distanceTo(other: Position): number {
        return (
            Math.abs(this.col() - other.col()) +
            Math.abs(this.row() - other.row())
        );
    }

}