import React from 'react';
import './UIMenuItem.css';

export type UIMenuItemProps = {
    onClick?: React.MouseEventHandler<HTMLElement>;
    onMenuCollapse: React.MouseEventHandler<HTMLElement>;
}

export type UIMenuItemState = {
}

export default class UIMenuItem extends React.PureComponent<UIMenuItemProps,UIMenuItemState> {

    constructor(props: UIMenuItemProps) {
        super(props);

        this.handleClick = this.handleClick.bind(this);
    }

    override render(): React.ReactNode {
        return (
            <div className="UIMenuItem" onClick={this.handleClick}>
                {this.props.children}
            </div>
        );
    }

    private handleClick(event: React.MouseEvent<HTMLElement>) {
        this.props.onMenuCollapse(event);
        this.props.onClick && this.props.onClick(event);
    }
}