import { User } from "@auth0/auth0-react";

export default class AuthUser {

    private readonly _auth0: User;
    private readonly _roles: Set<string>;

    public constructor(auth0: User, roles: string[]) {
        this._auth0 = auth0;
        this._roles = new Set(roles.map(r => r.toLowerCase()));
    }

    public id(): string {
        return this._auth0.sub ?? '';
    }

    public nickname(): string {
        return this._auth0.nickname ?? '';
    }

    public hasRole(role: string): boolean {
        return this._roles.has(role.toLowerCase());
    }

    public static IsValid(user: AuthUser | null, auth0: User): boolean {
        return (user != null) && (user._auth0 === auth0);
    }
}