import PositionSet from '../../core/PositionSet';
import ConstraintDef from '../ConstraintDef';
import SumConstraint from '../domino/SumConstraint';
import NegativeConstraint from './NegativeConstraint';

export default class SumNegativeConstraint extends NegativeConstraint {

    private static readonly _sumNeg = new ConstraintDef('sum-', 'Sum (Negative)', 'Global', ['Sum', 'Negative', 'XV']);

    protected constructor(squares: PositionSet) {
        super(squares, SumConstraint, SumConstraint.fromSquares(squares, 'X'), SumConstraint.fromSquares(squares, 'V'));
    }

    public static def(): ConstraintDef {
        return this._sumNeg;
    }

    public override def(): ConstraintDef {
        return SumNegativeConstraint.def();
    }

    public rules(): string {
        return 'Adjacent cells cannot sum to 5 or 10 (unless explicitly allowed by an X or V).';
    }

    public static fromString(str: string): SumNegativeConstraint {
        return SumNegativeConstraint.fromSquares(PositionSet.fromString(str));
    }

    public static fromSquares(squares: PositionSet): SumNegativeConstraint {
        return new SumNegativeConstraint(squares);
    }

    public static fromDefault(): SumNegativeConstraint {
        return new SumNegativeConstraint(PositionSet.fromEmpty());
    }
}