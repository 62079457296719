
export type ViewInfo = 'show' | 'hide';

export default class ViewState {

    private readonly _info: ViewInfo;

    private constructor(info: ViewInfo) {
        this._info = info;
    }

    static fromDefault(): ViewState {
        return new ViewState('show');
    }

    static fromData(info: ViewInfo): ViewState {
        return new ViewState(info);
    }

    public isEmpty(): boolean {
        return this._info === undefined;
    }

    info(): ViewInfo {
        return this._info;
    }

    toggleInfo(): ViewState {
        return new ViewState(this._info === 'show' ? 'hide' : 'show');
    }
}