import React from "react";
import { ConstraintProps } from "sudokuku-common/src/constraints/ConstraintReg";
import SandwichConstraint from "sudokuku-common/src/constraints/killer/SandwichConstraint";
import './SandwichConstraintCell.css';

export default function SandwichConstraintCell(props: ConstraintProps): React.ReactElement | null {

    const constraint = props.constraint as SandwichConstraint;

    const hasSandwich = props.pos.isEqual(constraint.pos());
    if (!hasSandwich) return null;

    const sum = constraint.sum();

    return <div className="SandwichConstraint content">{sum}</div>;
}