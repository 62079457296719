import classNames from "classnames";
import React from "react";
import { ConstraintProps } from "sudokuku-common/src/constraints/ConstraintReg";
import InequalityConstraint from "sudokuku-common/src/constraints/domino/InequalityConstraint";
import SelectionState from "../../model/SelectionState";
import './InequalityConstraintCell.css';

const colorLookup = new Map<string, number>([
    ['12', 0], ['14', 0], ['16', 0], ['18', 0],
    ['22', 0], ['24', 0], ['26', 1], ['28', 1],
    ['32', 1], ['34', 1], ['36', 2], ['38', 0],
    ['42', 2], ['44', 1], ['46', 3], ['48', 0],
    ['52', 2], ['54', 1], ['56', 4], ['58', 0],
    ['62', 3], ['64', 1], ['66', 4], ['68', 0],
    ['72', 3], ['74', 2], ['76', 5], ['78', 0],
    ['82', 4], ['84', 2], ['86', 6], ['88', 0],
    ['92', 4], ['94', 2], ['96', 7], ['98', 0],
]);

function getCellColors(props: ConstraintProps, cellStyles: string[]): string | undefined {

    const selection = props.selection as SelectionState;

    const colorIndex = selection.colorIndex(props.pos);
    if (colorIndex > 0) {
        cellStyles.push('sc'); // selection color
        return colorIndex.toString();
    }

    const pointerIndex = selection.pointerIndex(props.pos);
    if (pointerIndex > 0) {
        cellStyles.push('sp'); // selection pointer
        return pointerIndex.toString();
    }

    return props.sudoku.colorMark(props.pos);
}

export default function InequalityConstraintCell(props: ConstraintProps): React.ReactElement | null {

    const constraint = props.constraint as InequalityConstraint;

    const [iDir] = constraint.cellDirections(props.pos);
    if (!iDir.length) return null;

    let color = '0';

    const cellStyles: string[] = [];
    const cellColors = getCellColors(props, cellStyles);
    if (cellColors) {
        const key = cellColors.length.toString() + iDir;
        const index = colorLookup.get(key) ?? 0;
        color = cellColors[index];
    }

    const iClassName = classNames(['InequalityConstraint', 'content', 'line', 'd' + iDir]);
    const cClassName = classNames(['c', 'c' + color, ...cellStyles]);

    return (
        <div className={iClassName}>
            <div className={cClassName} />
        </div>
    );
}