/* eslint-disable @typescript-eslint/no-explicit-any */

import SudokuError from "sudokuku-common/src/core/SudokuError";

export default class SudokuApiError extends SudokuError {

    public readonly status: number;

    public constructor(res: Response, body?: string) {
        super(res.status + ': ' + body || res.statusText)

        this.status = res.status;
    }
}