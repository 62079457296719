import ParityEvenConstraint from '../constraints/cell/ParityEvenConstraint';
import ParityOddConstraint from '../constraints/cell/ParityOddConstraint';
import Constraint from '../constraints/Constraint';
import ConstraintDef from '../constraints/ConstraintDef';
import ConstraintReg from '../constraints/ConstraintReg';
import DiffConstraint from '../constraints/domino/DiffConstraint';
import InequalityConstraint from '../constraints/domino/InequalityConstraint';
import RatioConstraint from '../constraints/domino/RatioConstraint';
import SumConstraint from '../constraints/domino/SumConstraint';
import DiffNegativeConstraint from '../constraints/global/DiffNegativeConstraint';
import KingConstraint from '../constraints/global/KingConstraint';
import KnightConstraint from '../constraints/global/KnightConstraint';
import RatioNegativeConstraint from '../constraints/global/RatioNegativeConstraint';
import SumNegativeConstraint from '../constraints/global/SumNegativeConstraint';
import HiddenKillerConstraint from '../constraints/killer/HiddenKillerConstraint';
import KillerCageConstraint from '../constraints/killer/KillerCageConstraint';
import LittleKillerConstraint from '../constraints/killer/LittleKillerConstraint';
import SandwichConstraint from '../constraints/killer/SandwichConstraint';
import ArrowConstraint from '../constraints/line/ArrowConstraint';
import BetweenConstraint from '../constraints/line/BetweenConstraint';
import LockoutConstraint from '../constraints/line/LockoutConstraint';
import PalindromeConstraint from '../constraints/line/PalindromeConstraint';
import RenbanConstraint from '../constraints/line/RenbanConstraint';
import ThermoConstraint from '../constraints/line/ThermoConstraint';
import WhisperConstraint from '../constraints/line/WhisperConstraint';
import CloneConstraint from '../constraints/local/CloneConstraint';
import QuadConstraint from '../constraints/local/QuadConstraint';
import BoxConstraint from '../constraints/unique/BoxConstraint';
import ColConstraint from '../constraints/unique/ColConstraint';
import DiagConstraint from '../constraints/unique/DiagConstraint';
import DisjointConstraint from '../constraints/unique/DisjointConstraint';
import RegionConstraint from '../constraints/unique/RegionConstraint';
import RowConstraint from '../constraints/unique/RowConstraint';
import MultiMap from './MultiMap';
import Position from './Position';
import PositionSet from './PositionSet';

export default class SudokuConstraints {

    private static readonly _defs = [
        ConstraintReg.registerConstructors(RowConstraint.def(), RowConstraint.fromString),
        ConstraintReg.registerConstructors(ColConstraint.def(), ColConstraint.fromString),
        ConstraintReg.registerConstructors(BoxConstraint.def(), BoxConstraint.fromString),
        ConstraintReg.registerConstructors(RegionConstraint.def(), RegionConstraint.fromString),
        ConstraintReg.registerConstructors(KillerCageConstraint.def(), KillerCageConstraint.fromString, KillerCageConstraint.fromSelection),
        ConstraintReg.registerConstructors(LittleKillerConstraint.def(), LittleKillerConstraint.fromString),
        ConstraintReg.registerConstructors(HiddenKillerConstraint.def(), HiddenKillerConstraint.fromString),
        ConstraintReg.registerConstructors(SandwichConstraint.def(), SandwichConstraint.fromString),
        ConstraintReg.registerConstructors(ThermoConstraint.def(), ThermoConstraint.fromString, ThermoConstraint.fromSelection),
        ConstraintReg.registerConstructors(PalindromeConstraint.def(), PalindromeConstraint.fromString, PalindromeConstraint.fromSelection),
        ConstraintReg.registerConstructors(ArrowConstraint.def(), ArrowConstraint.fromString, ArrowConstraint.fromSelection),
        ConstraintReg.registerConstructors(BetweenConstraint.def(), BetweenConstraint.fromString, BetweenConstraint.fromSelection),
        ConstraintReg.registerConstructors(LockoutConstraint.def(), LockoutConstraint.fromString, LockoutConstraint.fromSelection),
        ConstraintReg.registerConstructors(RenbanConstraint.def(), RenbanConstraint.fromString, RenbanConstraint.fromSelection),
        ConstraintReg.registerConstructors(WhisperConstraint.def(), WhisperConstraint.fromString, WhisperConstraint.fromSelection),
        ConstraintReg.registerConstructors(RatioConstraint.def(), RatioConstraint.fromString, RatioConstraint.fromSelection),
        ConstraintReg.registerConstructors(RatioNegativeConstraint.def(), RatioNegativeConstraint.fromString),
        ConstraintReg.registerConstructors(DiffConstraint.def(), DiffConstraint.fromString, DiffConstraint.fromSelection),
        ConstraintReg.registerConstructors(DiffNegativeConstraint.def(), DiffNegativeConstraint.fromString),
        ConstraintReg.registerConstructors(SumConstraint.def(), SumConstraint.fromString, SumConstraint.fromSelection),
        ConstraintReg.registerConstructors(SumNegativeConstraint.def(), SumNegativeConstraint.fromString),
        ConstraintReg.registerConstructors(KnightConstraint.def(), KnightConstraint.fromString),
        ConstraintReg.registerConstructors(KingConstraint.def(), KingConstraint.fromString),
        ConstraintReg.registerConstructors(DiagConstraint.def(), DiagConstraint.fromString),
        ConstraintReg.registerConstructors(QuadConstraint.def(), QuadConstraint.fromString, QuadConstraint.fromSelection),
        ConstraintReg.registerConstructors(CloneConstraint.def(), CloneConstraint.fromString),
        ConstraintReg.registerConstructors(ParityOddConstraint.def(), ParityOddConstraint.fromString, ParityOddConstraint.fromSelection),
        ConstraintReg.registerConstructors(ParityEvenConstraint.def(), ParityEvenConstraint.fromString, ParityEvenConstraint.fromSelection),
        ConstraintReg.registerConstructors(DisjointConstraint.def(), DisjointConstraint.fromString),
        ConstraintReg.registerConstructors(InequalityConstraint.def(), InequalityConstraint.fromString, InequalityConstraint.fromSelection),
    ];

    private readonly _all: Constraint[];
    private readonly _inCell: MultiMap<string, Constraint>;
    private readonly _visibleInCell: MultiMap<string, Constraint>;

    private constructor(all: Constraint[]) {
        this._all = all.sort((a, b) => a.order() - b.order());
        this._inCell = MultiMap.groupByArrayMap(all, c => c.squares().map(s => s.toString()), c => c);
        this._visibleInCell = MultiMap.groupByArrayMap(all, c => c.visible().map(s => s.toString()), c => c);
    }

    public static defs(): ConstraintDef[] {
        return this._defs;
    }

    public static fromEmpty(): SudokuConstraints {
        return new SudokuConstraints([]);
    }

    public static fromArray(array: Constraint[]): SudokuConstraints {
        return new SudokuConstraints(array);
    }

    public isEmpty(): boolean {
        return this._all.length === 0;
    }

    public all(): Constraint[] {
        return this._all;
    }

    public in(pos: Position): Constraint[] {
        return this._inCell.get(pos.toString()) || [];
    }

    public visibleIn(pos: Position): Constraint[] {
        return this._visibleInCell.get(pos.toString()) || [];
    }

    public inAll(set: PositionSet): Constraint[] {
        return this.filter(c => c.squares().hasAll(set));
    }

    public inAny(set: PositionSet): Constraint[] {
        return this.filter(c => c.squares().hasAny(set));
    }

    public find(findFunc: (c: Constraint, i: number) => boolean): Constraint | undefined {
        return this._all.find(findFunc);
    }

    public filter(filterFunc: (c: Constraint, i: number) => boolean): Constraint[] {
        return this._all.filter(filterFunc);
    }

    public add(constraint: Constraint): SudokuConstraints {
        return new SudokuConstraints([...this._all, constraint]);
    }

    public remove(constraint: Constraint): SudokuConstraints {
        return new SudokuConstraints(this._all.filter(c => c !== constraint));
    }

    public replace(oldConstraint: Constraint, newConstraint: Constraint): SudokuConstraints {
        return new SudokuConstraints(this._all.map(c => c === oldConstraint ? newConstraint : c));
    }

    public addArray(constraints: Constraint[]): SudokuConstraints {
        return new SudokuConstraints([...this._all, ...constraints]);
    }
}