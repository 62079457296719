import PositionSet from '../../core/PositionSet';
import ConstraintDef from '../ConstraintDef';
import GlobalConstraint from './GlobalConstraint';

export default class KingConstraint extends GlobalConstraint {

    private static readonly _king = new ConstraintDef('king', 'Chess King', 'Global', ['Chess King', 'Anti-King']);

    private static readonly _offsets: [number, number][] = [
        [-1, -1], [-1, +0], [-1, +1],
        [+0, -1], [+0, +0], [+0, +1],
        [+1, -1], [+1, +0], [+1, +1]
    ];

    private constructor(squares: PositionSet) {
        super(squares);
    }

    public static def(): ConstraintDef {
        return this._king;
    }

    public override def(): ConstraintDef {
        return KingConstraint.def();
    }

    override isUniqueCandidates(): boolean {
        return true;
    }

    public rules(): string {
        return 'Cells separated by a chess king\'s move cannot contain the same digit.'
    }

    public toString(): string {
        return this.squares().toString();
    }

    public static fromString(str: string): KingConstraint {
        return KingConstraint.fromSquares(PositionSet.fromString(str));
    }

    public static fromSquares(squares: PositionSet): KingConstraint {
        return new KingConstraint(squares);
    }

    public static fromDefault(): KingConstraint {
        return new KingConstraint(PositionSet.fromEmpty());
    }

    protected override candidateOffsets(): [number, number][] {
        return KingConstraint._offsets;
    }
}